core_module.directive('scrollEnable', function ($timeout, $rootScope) {
    return {
        restrict: 'A',
        scope: {
            target: '@scrollTarget'
        },
        link: function (scope, element, attrs) {
            var el = element[0];
            var content = element.parents('md-dialog').find('md-dialog-content')[0];

            var checkScroll = function () {
                return (content.scrollTop + content.offsetHeight >= content.scrollHeight);
            };

            var observed = function () {
                return content.offsetHeight;
            };

            var callback = function (newValue, oldValue) {
                el.disabled = !checkScroll();
            };

            var watcher = scope.$watch(observed, callback);

            var handler = function (event, target) {
                if (target === scope.target) {
                    el.disabled = false;
                }
            };

            var scrollListener = $rootScope.$on('scrollBottom', handler);

            scope.$on('$destroy', function () {
                scrollListener();
                watcher();
            })

        }
    }
});