core_module.controller('UserRoleDialogsController', function ($scope, $mdDialog, EntityService, DialogService, AlertService, $translate, userId, AuthService, $rootScope) {

    $scope.userId = userId;
    $scope.role = {};
    $scope.facility = {};

    $scope.container = {role: {}, facility: {}};

    (function () {
        $scope.isAdmin = AuthService.isAdmin();

        EntityService.getEntries("currentUser/assignableroles", { limit: -1}).then(function (response) {
            $scope.roles = response.data.map(function (item) {
                return {name: $translate.instant(item.name), value: item};
            });
        });
        if (AuthService.isAdmin() === true) {
            EntityService.getEntries("facility", { limit: -1}).then(function (response) {
                if (response.data.content) {
                    $scope.facilities = response.data.content.map(function (item) {
                        return {name: item.name, value: item};
                    });
                } else {
                    $scope.facilities = response.data.map(function (item) {
                        return {name: item.name, value: item};
                    });
                }
            });
        } else {
            $scope.facilities = [];
            $scope.facilities.push(AuthService.getCurrentUserFacility());
        }
    }());

    $scope.addConfirm = function () {
        var newUserRole = {};
        if ($scope.userRoleForm.$valid) {
            if ($scope.container.role.facilityNeeded === true) {
                if (AuthService.isAdmin() === true) {
                    newUserRole = {
                        userId: $scope.userId,
                        facilityId: $scope.container.facility.id,
                        roleId: $scope.container.role.id
                    }
                } else {
                    newUserRole = {
                        userId: $scope.userId,
                        facilityId: AuthService.getCurrentUserFacility().id,
                        roleId: $scope.container.role.id
                    }
                }

            } else {
                newUserRole = {
                    userId: $scope.userId,
                    roleId: $scope.container.role.id
                }
            }
            EntityService.addEntry('userRole', newUserRole).then(function (response) {
                AlertService.showToast($translate.instant('userRoleCreatedSuccessfully'));
                $rootScope.$broadcast('refreshTable', {entity: 'userRole'});
            }, function (response) {
                // err msg
                $rootScope.$broadcast('refreshTable', {entity: 'userRole'});
            });
            DialogService.closeDialog();
        }
        else {
            AlertService.renderErrorMessage($translate.instant('form_has_errors'), $translate.instant('form_has_errors'), '');
        }
    };


});
