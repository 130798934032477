core_module.factory('AuthService', function ($q, $rootScope, $location, $interval, $http, $translate, HttpService, AlertService, CONFIG, LanguageService, RoleService) {

    var secondsToLogout = undefined;
    var countdownInterval;
    var languages = [];

    var authService = {
        isSignedIn: isSignedIn,
        isAdmin: isAdmin,
        hasPermission: hasPermission,
        hasPermissionOrIsAdmin: hasPermissionOrIsAdmin,
        checkExpiration: checkExpiration,
        clearStorage: clearStorage,
        signIn: signIn,
        signOut: signOut,
        getSecondsToLogout: getSecondsToLogout,
        getCurrentUser: getCurrentUser,
        getCurrentUserName: getCurrentUserName,
        getCurrentUserFacility: getCurrentUserFacility,
        setCurrentUserFacility: setCurrentUserFacility,
        hasCurrentUserFacility: hasCurrentUserFacility,
        setFacilityParameter: setFacilityParameter,
        getFacilityParameter: getFacilityParameter,
        setCurrentUserPermission: setCurrentUserPermission,
        hasRoleByName: hasRoleByName

    };

    LanguageService.getLanguages().then(function (response) {
        languages = response.data;
    }, function (response) {

    });

    function checkExpiration(time) {
        if ($http.pendingRequests.length === 0) {
            if (time) {
                var currentDate = new Date;
                secondsToLogout = Math.floor((time - currentDate.getTime()) / 1000);
            }
            if (isSignedIn() && !secondsToLogout) clearStorage();
        }
    }


    function clearStorage() {
        sessionStorage.clear();
    }

    function isSignedIn() {
        return !!sessionStorage.getItem('token');
    }

    function getCurrentUser() {
        var strcurrentUser = sessionStorage.getItem('currentUser');
        try {
            var currentUser = JSON.parse(strcurrentUser);
            if (currentUser != null) {
                return currentUser;
            } else {
                return undefined;
            }
        } catch (e) {
            return undefined;
        }
    }

    function getCurrentUserName() {
        return sessionStorage.getItem('currentUserName');
    }

    function getCurrentUserFacility() {
        var strCurrentUserFacility = sessionStorage.getItem('currentUserFacility');
        try {
            var currentUserFacility = JSON.parse(strCurrentUserFacility);
            if (currentUserFacility != null) {
                return currentUserFacility;
            } else {
                return undefined;
            }
        } catch (e) {
            return undefined;
        }
    }

    function hasCurrentUserFacility() {
        return !!getCurrentUserFacility();
    }

    function setCurrentUserFacility(facility) {
        return sessionStorage.setItem('currentUserFacility', JSON.stringify(facility));
    }

    function isAdmin() {
        return hasPermission(CONFIG.ROLE_ADMIN);
    }

    /*
     return TRUE if user has Permission 'name' or is ADMIN
     */
    function hasPermissionOrIsAdmin(name) {
        return isAdmin() || hasPermission(name);
    }

    function hasPermission(name) {

        if (!isSignedIn()) {
            return false;
        }
        var permissionStr = sessionStorage.getItem('currentUserPermissions');
        permissionStr = permissionStr || '';
        var permissions = permissionStr.split(',');

        var key = permissions.find(function (item) {
            return item === name || hasAdminPermission(name, item);
        });

        return key !== undefined;
    }

    function hasAdminPermission(name, item) {
        if (getWithoutLastElement(name) !== getWithoutLastElement(item)) return false;
        var itemElems = item.split('_');
        var operation = itemElems[itemElems.length - 1];
        return operation === 'ADMIN'
    }

    function getWithoutLastElement(permission) {
        var splitted = permission.split('_');
        splitted.pop();
        return splitted.join('');
    }


    function getSecondsToLogout() {
        return secondsToLogout;
    }

    function signIn(params) {
        var deferred = $q.defer();
        HttpService.post(
            '/auth/login/', params
        ).then(function (response) {
            countdownInterval = $interval(countdown, 1000);
            sessionStorage.setItem('token', response.data.token);
            sessionStorage.setItem('currentUser', JSON.stringify(response.data.user));
            sessionStorage.setItem('currentUserId', response.data.user.id);
            sessionStorage.setItem('currentUserName', response.data.user.email);
            sessionStorage.setItem('currentUserFacility', JSON.stringify(response.data.user.currentFacility));
            sessionStorage.setItem('expirationTime', (response.data.expirationTime / 1000));

            if (response.data.user.language != null && languages.length > 0) {
                var i = 0;
                for (; i < languages.length; i++) {
                    if (response.data.user.language == languages[i]) {
                        moment.locale(response.data.user.language);
                        $translate.use(response.data.user.language);
                    }
                }
            } else {
                moment.locale("en");
                $translate.use("en");
            }
            return response;
        }).then(function (response) {
            var promises = [
                setCurrentUserPermission(response.data.user),
                RoleService.setCurrentUserRoles(),
            ];

            $q.all(promises).then(function () {
                deferred.resolve();
            });
        }, function (response) {
            clearStorage();
            deferred.reject(response);
        });

        return deferred.promise;
    }


    function setCurrentUserPermission(user) {
        var deferred = $q.defer();
        HttpService.get('/currentUser/permissions').then(function (response) {
            var permissionNames = response.data;
            permissionNames = permissionNames.join(',');
            sessionStorage.setItem('currentUserPermissions', permissionNames);
            deferred.resolve();
        }, function (response) {
            clearStorage();
            deferred.reject();
        })
        return deferred.promise;
    }


    function hasRoleByName(roleName) {
        return getCurrentUser().userRoles.some(function (role) {
            return role.roleName === roleName
        });
    }

    function setFacilityParameter() {

        var currentFacility = getCurrentUserFacility();

        if (typeof currentFacility !== "undefined") {
            HttpService.shortGet("/facilityParameters/?sort=id&size=" + CONFIG.MAX_PAGE_SIZE + "&page=1&facilityId=" + currentFacility.id, function (response) {
                sessionStorage.setItem("facilityParameter", JSON.stringify(response.data.content));
            }, function (response) {
                console.log("leider nein");
                sessionStorage.setItem("facilityParameter", JSON.stringify(response.data));
            });
        }
    }


    function getFacilityParameter() {
        var strFacilityParameter = sessionStorage.getItem('facilityParameter');
        try {
            var facilityParameter = JSON.parse(strFacilityParameter);
            if (facilityParameter != null) {
                return facilityParameter;
            } else {
                return undefined;
            }
        } catch (e) {
            return undefined;
        }
    }

    function signOut() {
        return HttpService.get('/auth/logout/').then(function () {
            handleSignOut();
        }, function () {
            handleSignOut();
        })
    }

    function handleSignOut() {
        clearStorage();
        $rootScope.$broadcast('changeUserState', false);
        $location.path("/");
    }


    function countdown() {
        if (isSignedIn() && secondsToLogout && secondsToLogout > 0) {
            secondsToLogout--;
        } else if (secondsToLogout <= 0) {
            $interval.cancel(countdownInterval);
            signOut().then(function () {
                AlertService.renderDialogMessage("app.time_is_up");
            });
        }
    }

    return authService;
});
