core_module.component('exportSettingsTable', {
    bindings: {
        parentId: '<?'
    },
    controller: function ($mdDialog, $rootScope, $q, $translate, HttpService, EntityService, AlertService) {
        var vm = this;
        var promisses = [];
        var documentTemplateTypeSelectOptions = [];
        var exportSelectOptions = [];

        vm.$onInit = init;

        processBindings();

        this.$onChanges = function (changes) {
            if (changes.hasOwnProperty('parentId')) {
                processBindings()
            }
        };

        function processBindings() {
            vm.exportCsvFieldsGroupId = (vm.parentId) ? vm.parentId : null;
            if (vm.exportCsvFieldsGroupId) {
                vm.addFunction = function () {
                    $mdDialog.show({
                        templateUrl: 'shared/components/exportCsv/exportSettingsTable/dialogs/add.exportSettingToExportCsvFieldsGroup.html',
                        controller: 'ExportSettingToExportCsvFieldsGroupController',
                        fullscreen: false,
                        locals: {
                            parentId: vm.exportCsvFieldsGroupId
                        }
                    });
                };

                vm.removeExportSettingFromExportCsvFieldsGroup = function (export_settings) {
                    var confirm = $mdDialog.confirm()
                        .title($translate.instant('confirm_changes'))
                        .textContent($translate.instant('confirm_changes'))
                        .ok($translate.instant('delete'))
                        .cancel($translate.instant('cancel'));
                    $mdDialog.show(confirm).then(function () {
                            var showDeleteSuccess = function () {
                                AlertService.showToast($translate.instant('exportSetting' + ' deleted successfully'));
                            };
                            var url = "/export_settings/delExportSetting?exportCsvFieldsGroupId="
                                + vm.exportCsvFieldsGroupId + "&exportSettingId="
                                + export_settings.id;

                            return HttpService.delete(url).then(function () {
                                $rootScope.$broadcast('refreshTable', {entity: 'export_settings'});
                                showDeleteSuccess();
                            });
                        }
                    );
                };

                vm.editFunction = function () {
                    AlertService.showToast('Editierung von ExportSetting ist in ExportSetting Tabelle.');
                }
            } else {
                vm.addFunction = undefined;
                vm.removeSectionTemplateFromExportCsvFieldsGroup = undefined;
                vm.editFunction = undefined;
            }
        }

        vm.getExportSettingsUrl = function () {
            if (vm.exportCsvFieldsGroupId) {
                return 'export_settings/exportCsvFieldsGroup/' + vm.exportCsvFieldsGroupId;
            }
        };

        function init() {
            var documentTemplateTypeUrl = 'documentTemplateType';
            var exportsUrl = 'exports';
            promisses[0] = EntityService.getEntries(documentTemplateTypeUrl);
            promisses[1] = EntityService.getEntries(exportsUrl);

            $q.all(promisses).then(function (dataArray) {
                var j, tmpArray = [];
                var selectOption = {};

                if (dataArray[0].data) { // DocumentTemplateType objects
                    tmpArray = dataArray[0].data.content;
                    tmpArray.forEach(function (templateType) {
                        selectOption = {};
                        selectOption.name = templateType.templateType;
                        selectOption.value = templateType.id;
                        documentTemplateTypeSelectOptions.push(selectOption);
                    });
                }
                if (dataArray[1].data) { // Exports objects
                    tmpArray = dataArray[1].data.content;
                    for (j=0; j < tmpArray.length; j++) {
                        selectOption = {};
                        selectOption.name = tmpArray[j].exportName;
                        selectOption.value = tmpArray[j].id;
                        exportSelectOptions.push(selectOption);
                    }
                }
            }, function (response) {
                AlertService.renderErrorMessage(response.data.details.cause, response.data.details, response.status);
            });
        }

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            showDeleted: true,
            add: true
        };

        vm.initObject = {
            id: null
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {
                name: 'entryTemplateCustomId',
                field: 'entryTemplateCustomId',
                display: true,
                edit: true,
                type: 'text',
                link: false,
                filter: true
            },
            {
                name: 'propertyNameInJsonDocument',
                field: 'propertyNameInJsonDocument',
                display: true,
                edit: true,
                type: 'text',
                link: false,
                filter: true
            },
            {
                name: 'documentTemplateType',
                field: 'documentTemplateType',
                display: false,
                displayName: 'Bogentyp',
                editrules: {required: true},
                edit: true,
                type: 'select',
                selectOptions: documentTemplateTypeSelectOptions,
                link: false,
                filter: true
            },
            {
                name: 'documentTemplateTypeName',
                field: 'documentTemplateType_Name',
                display: true,
                displayName: 'Bogentyp',
                edit: false,
                type: 'text',
                link: false,
                filter: false
            },
            {
                name: 'csvFieldName',
                field: 'csvFieldName',
                display: true,
                edit: true,
                type: 'text',
                filter: true
            },
            {name: 'position', field: 'position', display: true, edit: true, type: 'text', filter: true},
            {name: 'keyFeld', field: 'keyFeld', display: true, edit: true, type: 'boolean', filter: true}
            // is now in HTR not used
            // {name: 'excludeField', field: 'excludeField', display: true, edit: true, type: 'boolean', filter: true}
        ];

    },
    templateUrl: 'shared/components/exportCsv/exportSettingsTable/exportSettingsTable.html',
    controllerAs: 'exportSettings'
});
