/**
 * Created by Thorsten.Wirth on 10.01.2017.
 */
core_module.controller('AddParameterController', function ($rootScope, DialogService, EntityService, AlertService, initObject) {
    var vm = this;

    vm.$onInit = init;
    vm.addParameter = addParameter;

    function init() {
        vm.param = angular.copy(initObject);
    }

    function addParameter() {
        EntityService.addEntry("parameters", vm.param).then(function () {
            $rootScope.$broadcast("refreshTable", {entity: "parameters"});
            AlertService.showToast('Insert new Parameter was successful');
            DialogService.hideDialog();
        });
    }
});