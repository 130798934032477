core_module.factory('AlertService', function ($rootScope, $mdDialog, $mdToast, $translate) {
    $rootScope.alerts = [];

    var translateAlert = function (message, details) {
        if (!details)
            return $translate.instant(message);
        else {
            return $translate.instant(message, details);
        }
    };

    return {
        renderGenericMessage: function (type, message, details, code) {
            switch (type) {
                case 'trace':
                    this.renderTraceMessage(message, details, code);
                    break;
                case 'info':
                    this.renderInfoMessage(message, details, code);
                    break;
                case 'warn':
                    this.renderWarningMessage(message, details, code);
                    break;
                case 'success':
                    this.renderSuccessMessage(message, details, code);
                    break;
                case 'error':
                    this.renderErrorMessage(message, details, code);
                    break;
                default:
                    this.renderErrorMessage(message, details, code);
                    break;
            }
        },
        renderSuccessMessage: function (message, details, code) {
            var localizedMessage = translateAlert(message, details);
            $rootScope.alerts.push({
                'time': Date.now(),
                'type': 'success',
                'message': localizedMessage,
                'details': details,
                'code': code
            });
            console.info(code, message, details);
            $mdToast.showSimple(localizedMessage);
        },
        renderTraceMessage: function (message, details, code) {
            var localizedMessage = translateAlert(message, details);
            $rootScope.alerts.push({
                'time': Date.now(),
                'type': 'trace',
                'message': localizedMessage,
                'details': details,
                'code': code
            });
            console.trace(code, message, details);
        },
        renderInfoMessage: function (message, details, code) {
            var localizedMessage = translateAlert(message, details);
            $rootScope.alerts.push({
                'time': Date.now(),
                'type': 'info',
                'message': localizedMessage,
                'details': details,
                'code': code
            });
            console.info(code, message, details);
            $mdToast.showSimple(localizedMessage);
        },
        renderWarningMessage: function (message, details, code) {
            var localizedMessage = translateAlert(message, details);
            var alert = {
                'time': Date.now(),
                'type': 'warning',
                'message': localizedMessage,
                'details': details,
                'code': code
            };
            console.warn(code, message, details);
            $rootScope.alerts.push(alert);
            $rootScope.showAlerts = true;
        },
        renderErrorMessage: function (message, details, code) {
            var localizedMessage = translateAlert(message, details);
            var alert = {
                'time': Date.now(),
                'type': 'error',
                'message': localizedMessage,
                'details': details,
                'code': code
            };
            console.error(code, message, details);
            $rootScope.alerts.push(alert);
            $rootScope.showAlerts = true;
        },
        renderDialogMessage: function (message, title, details, code) {
            if (!title)
                title = $translate.instant('dialog.remark');
            var localizedMessage = translateAlert(message, details);
            console.trace(code, message, details);
            var alert = $mdDialog.alert({multiple: true, clickOutsideToClose: true})
                .title($translate.instant(title))
                .textContent(localizedMessage)
                .ok('OK');

            $mdDialog.show(alert);

        },
        renderMessageByHTTPStatus: function (status, message, details) {
            var type = String(status).charAt(0);
            switch (type) {
                case '1':
                    this.renderInfoMessage(message, details, status);
                    break;
                case '2':
                    this.renderSuccessMessage(message, details, status);
                    break;
                case '3':
                    this.renderInfoMessage(message, details, status);
                    break;
                case '4':
                    this.renderDialogMessage(message, "", details, status);
                    break;
                case '5':
                    this.renderErrorMessage(message, details, status);
                    break;
                default:
                    this.renderErrorMessage(message, details, status);
                    break;
            }
        },

        showToast: function (textContent, position, delay, details) {
            var toastText = '';
            if (textContent) {
                if (!details) {
                    toastText = $translate.instant(textContent);
                } else {
                    toastText = $translate.instant(textContent, details);
                }
            }
            $mdToast.show(
                $mdToast.simple()
                    .textContent(toastText)
                    // .parent(document.querySelectorAll('#toaster'))
                    .position(position || 'top right')
                    .hideDelay(delay || 3000)
                    .action('×')
            );
        }

    }
});