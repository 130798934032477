core_module.controller('ErrorsDialogController', function ($rootScope, DialogService, HttpService, PreloaderService) {
    var vm = this;

    vm.alerts = $rootScope.alerts;

    vm.$onInit = init;
    vm.sendErrors = sendErrors;

    function init() {
        PreloaderService.waitForDOM();
    }

    function sendErrors() {
        HttpService.shortPost("/alerts", {"data": $rootScope.alerts});
        $rootScope.alerts = [];
        $rootScope.showAlerts = true;
        DialogService.hideDialog();
    }
});