core_module.component('auditMCase', {
    bindings: {
        className:'<?',
        parentId: '<?',
        entity: '<?'
    },
    controller: function ($mdDialog) {
        var vm = this;

        vm.getDetailedLogsUrl = getDetailedLogsUrl;

        vm.query = {
            order: 'rev',
            limit: 10,
            page: 1
        };

        vm.items = [];

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: false,
            add: false
        };

        vm.initObject = {
            deleted: false,
            name: ''
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, noSort: true},
            {name: 'deleted', field: 'deleted', display: true, edit: false, type: 'boolean', link: false, noSort: true},
            {   name: 'logging.revision',
                field: 'revision',
                display: true,
                edit: false,
                type: 'number',
                link: false,
                noSort: true
            },
            {
                name: 'logging.revisionType',
                field: 'revisionType',
                display: true,
                edit: false,
                type: 'text',
                link: false,
                noSort: true
            },
            {name: 'caseCustomId', field: 'caseCustomId', display: true, edit: false, type: 'text', link: false},
            {name: 'globalCaseId', field: 'globalCaseId', display: true, edit: false, type: 'text', link: false},
            {name: 'name', field: 'name', display: true, edit: false, type: 'text', link: false},
            {name: 'status', field: 'status', display: true, edit: false, type: 'text', link: false},
            {name: 'caseFile', field: 'caseFile', display: true, edit: false, type: 'number', link: false},
            {name: 'currentFacility', field: 'currentFacility', display: true, edit: false, type: 'number', link: false},
            {name: 'currentUser', field: 'currentUser', display: true, edit: false, type: 'number', link: false},
            {name: 'dateOfAdmission', field: 'dateOfAdmission', display: true, edit: false, type: 'datetime', link: false},
            {name: 'dateOfAccident', field: 'dateOfAccident', display: true, edit: false, type: 'datetime', link: false}
        ];

        function getDetailedLogsUrl() {
            return '/auditing/' + vm.className + "/" + vm.parentId;
        }

        vm.getDetailedLogsUrl = getDetailedLogsUrl;
    },
    templateUrl: 'shared/components/auditMCase/auditMCase.html',
    controllerAs: 'auditMCase'
});