core_module.component('coreInput', {
        transclude: true,
        bindings: {
            label: "@?",
            helperText: "@?",
            name: "@?",
            model: "=",
            type: "@?",
            required: "@?",
            minlength: "<?",
            maxlength: "<?",
            rows: "@?",
            cols: "@?",
            readonly: "<?",
            options: "=?",
            parameter: "=?",
            logo: "@?",
            tooltip: "@?",
            header: "@?",
            showLogo: "@?",
            pwModel: "=?",
            pattern: "@?",
            patternmsg: "@?",
            validateField: "<?",
            onChange: "&",
            minDate: "=?",
            maxDate: "=?",
            relatedDate: "=?",
            relatedMinDate: "=?",
            dateFormat: "=?",
            sectionForm: "@?",
            onBlur: "&",
            filePattern: "@?"
        },
        controller: function ($scope, $translate, $mdDialog, AuthService, LanguageService, DialogService, uuid, moment, CONFIG) {
            var vm = this;

            vm.$onInit = init;
            vm.checkInvalidDropBox = checkInvalidDropBox;
            vm.calculateAge = calculateAge;
            vm.showHelp = showHelp;
            vm.getEscapedValidateField = getEscapedValidateField;
            vm.close = close;

            vm.searchTerm = "";

            function init() {
                setInputName();
                prepareInputClasses();
                prepareInputs();
                prepareBindings();
                prepareTranslations();
                prepareParameterInput();
            }

            function close(){
                vm.searchTerm = "";
            }

            function setInputName() {
                if (!vm.name) {
                    var name = uuid.v4();
                    name = name.split('-').join('');
                    vm.name = '_' + name;
                }
            }

            function prepareInputClasses() {
                vm.labelClass = '';
                vm.inputContainerClass = 'md-block';
                vm.mdPickerClass = '';
            }

            function prepareInputs() {
                if (vm.type === 'language') {
                    LanguageService.getMappedLanguages().then(function (languages) {
                        vm.options = languages;
                    });
                } else if (vm.type === 'date' || vm.type === 'time') {
                    prepareDateTimeType();
                } else if (vm.type === 'slider') {
                    prepareSliderType();
                } else if (vm.type === 'code') {
                    prepareCodeType();
                } else if (vm.type === 'boolean') {
                    prepareBooleanType();
                }
            }

            function prepareBindings() {
                if (!vm.options)
                    vm.options = [];

                if (!vm.type)
                    vm.type = 'text';

                if (!vm.sectionForm) {
                    vm.sectionForm = false;
                }

                if (!vm.showLogo) {
                    vm.showLogo = true;
                }

                if (vm.showLogo === false || vm.showLogo === 'false') {
                    vm.inputContainerClass += ' no-icon-floating';
                }


                if (!vm.required || vm.required === 'false') {
                    vm.required = false;
                } else if (vm.required === true || vm.required === 'true' || vm.required === 'required') {
                    vm.required = true;
                    vm.labelClass += ' md-required';
                }

                if (!vm.logo) {
                    if (!vm.required)
                        vm.logo = 'label_outline';
                    else
                        vm.logo = 'label';
                }

                if (!vm.header) {
                    vm.header = false;
                }
                else {
                    vm.labelClass += ' md-container-ignore label-header md-no-float';
                    vm.inputContainerClass += ' md-no-float no-icon-floating';
                    vm.mdPickerClass = 'with-header';
                }

                if (!vm.readonly) {
                    vm.readonly = false;
                }
                else {
                    vm.labelClass += ' readonly';
                    $scope.readonly = vm.readonly;
                }

                if (!vm.minlength) {
                    vm.minlength = 0;
                }

                if (!vm.maxlength) {
                    vm.maxlength = CONFIG.MAX_PAGE_SIZE;
                }

                if (!vm.minDate) {
                    vm.minDate = false;
                }

                if (!vm.maxDate) {
                    vm.maxDate = false;
                }

                if (!vm.rows) {
                    vm.rows = 4;
                }

                if (!vm.cols) {
                    vm.cols = 20;
                }

                if (!vm.filePattern) {
                    vm.filePattern = 'text/plain';
                }

                if (!vm.patternmsg) {
                    vm.patternmsg = 'validation.pattern';
                }
            }

            function prepareBooleanType() {
                if (typeof vm.model === 'undefined') vm.model = false;
            }

            function prepareParameterInput() {
                if (angular.isObject(vm.parameter) && (!angular.isObject(vm.model) || angular.isArray(vm.model))) {
                    vm.model = {value: ''};
                    vm.model.unit = vm.parameter.siUnit.name;
                    if (AuthService.getFacilityParameter() != 'null') {
                        $scope.facilityParameters = AuthService.getFacilityParameter();
                        var params = vm.parameter;
                        var tempModel = {};
                        tempModel.unit = vm.parameter.siUnit.name;
                        angular.forEach($scope.facilityParameters, function (facParam) {
                            if (params.id == facParam.parameter.id && facParam.active && facParam.standardUnit != null) {
                                tempModel.unit = facParam.standardUnit.name;
                                tempModel.value = '';
                            }
                        });
                        vm.model = tempModel;
                    }
                } else if (angular.isObject(vm.parameter) && angular.isObject(vm.model)) {
                    vm.model.unit = vm.parameter.siUnit.name;
                    if (!vm.model.hasOwnProperty('value')) {
                        vm.model.value = '';
                    }
                }
            }

            function prepareSliderType() {
                if (!vm.model || isNaN(parseInt(vm.model))) vm.model = 0;
                if (angular.isUndefined(vm.maxlength)) vm.maxlength = 100;
            }

            function prepareDateTimeType() {
                if (vm.type === 'date' || vm.type === 'time') {
                    var d = moment(vm.model);
                    if (d.isValid() && typeof vm.model !== 'undefined')
                        vm.model = new Date(d);
                    else vm.model = null;
                }

                if (!angular.isDate(vm.model)) {
                    vm.model = null;
                }

                if (!vm.dateFormat) {
                    vm.dateFormat = 'DD.MM.YYYY';
                }
            }

            function prepareCodeType() {
                $scope.configureAce = configureAce;
            }

            function configureAce(editor) {
                if (vm.readonly) editor.setReadOnly(true);
                if (vm.required == 'true' && !vm.model) $scope[vm.name].code.$setValidity('required', false);
                else $scope[vm.name].code.$setValidity('required', true);
                editor.on('blur', function () {
                    if (vm.required == 'true') {
                        if (!vm.model) {
                            $scope[vm.name].code.$setValidity('required', false);
                        }
                        else {
                            $scope[vm.name].code.$setValidity('required', true);
                        }
                    }
                    $scope.$digest();
                });
            }

            function prepareTranslations() {
                vm.labelTranslated = $translate.instant(vm.label);
                vm.tooltipTranslated = $translate.instant(vm.tooltip);
                vm.validationRequiredTranslated = $translate.instant('validation.required');
                vm.helperTextTranslated = $translate.instant(vm.helperText);
                vm.validationPatternMsgTranslated = $translate.instant(vm.patternmsg);
            }

            function checkInvalidDropBox() {
                return $scope[vm.name].$error && $scope[vm.name].$dirty && !vm.model
            }

            function calculateAge(date) {
                var relativeDate = new moment(vm.relatedDate);
                var givenDate = new moment(date);

                vm.model = relativeDate.diff(givenDate, 'years');
            }

            function showHelp(ev) {
                if (vm.tooltipTranslated.includes('dialog::')) {
                    var splitted = vm.tooltipTranslated.split('::');
                    var imageUrl = splitted[1];
                    var textContent = splitted[2];
                    DialogService.displayHelpDialog(imageUrl, textContent);
                } else {
                    $mdDialog.show(
                        $mdDialog.alert()
                            .clickOutsideToClose(true)
                            .title($translate.instant('help'))
                            .textContent(vm.tooltipTranslated)
                            .targetEvent(ev)
                            .ok('OK')
                            .multiple(true)
                    );
                }
            }

            function getEscapedValidateField() {
                return vm.validateField ? vm.validateField.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") : null;
            }
        },
        templateUrl: 'shared/components/coreInput/coreInput.html',
        controllerAs: 'coreInput'
    }
);