/**
 * Created by Thorsten.Wirth on 10.01.2017.
 */
core_module.controller('UnitController', function ($rootScope, $translate, $mdDialog, EntityService, DialogService, parameter, unit) {
    var vm = this;
    var isNewEntry = false;

    vm.title = 'add_unit';
    vm.parameter = angular.copy(parameter);

    vm.$onInit = init;
    vm.confirm = confirm;

    function init() {
        if (unit) {
            vm.unit = angular.copy(unit);
            vm.title = $translate.instant('edit_unit') + ' - ' + unit.name;
        }
        else {
            vm.unit = {};
            isNewEntry = true;
        }
    }

    function confirm() {
        if (isNewEntry) {
            createUnit();
        }
        else updateUnit();
    }

    function createUnit() {
        vm.parameter.unit.push(vm.unit);
        EntityService.updateEntry("parameters", vm.parameter).then(function () {
            $rootScope.$broadcast('loadFacilitiesParam');
            DialogService.hideDialog();
        });
    }

    function updateUnit() {
        if (vm.unit.id === vm.parameter.siUnit.id) {
            vm.parameter.siUnit = vm.unit;
        }
        else {
            var index = vm.parameter.unit.findIndex(function (el) {
                return el.id === vm.unit.id
            });
            vm.parameter.unit[index] = vm.unit;
        }
        EntityService.updateEntry("parameters", vm.parameter).then(function () {
            $rootScope.$broadcast('loadFacilitiesParam');
            DialogService.hideDialog();
        });
    }
});