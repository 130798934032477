core_module.component('appNavbar', {
    controller: function ($scope, $rootScope, $location, RouteService, AuthService) {
        var vm = this;
        var selectedIndex = -1;

        vm.routes = [];

        vm.$onInit = init;
        vm.setPath = setPath;
        vm.isActive = isActive;
        vm.hasPerm = hasPerm;
        vm.getMenuClass = getMenuClass;
        vm.hasChildPerm = hasChildPerm;
        vm.getItemClass = getItemClass;
        vm.isSignedIn = AuthService.isSignedIn;

        function init() {
            prepareValues();
        }

        $scope.$on('changeUserState', function (event, isSignedIn) {
            vm.isSignedIn = isSignedIn;
            var allRoutes = RouteService.getRoutes();
            vm.routes = selectActiveRoutes(allRoutes);
            resetTabIndex();
        });

        function prepareValues() {
            var allRoutes = RouteService.getRoutes();
            vm.routes = selectActiveRoutes(allRoutes);
            selectedIndex = Number(localStorage.getItem("selectedIndex"));
        }

        function selectActiveRoutes(routes) {
            var originalRoutes = Object.assign([], routes);

            var activeRoutes = originalRoutes.filter(function (route) {
                if (route.hasOwnProperty('facilityNeeded') && route.facilityNeeded && !AuthService.hasCurrentUserFacility()) return false;
                if (route.permission && !hasPerm(route.permission)) return false;
                if (route.children && !hasChildPerm(route.children)) return false;
                if (route.hide) return false;
                if (route.children) {
                    route.children = route.children.filter(function (childRoute) {
                        if (childRoute.permission && !hasPerm(childRoute.permission)) return false;

                        if (childRoute.children) {
                            childRoute.children = childRoute.children.filter(function (subChildRoute) {
                                if (subChildRoute.permission && !hasPerm(subChildRoute.permission)) return false;
                                return true;
                            });
                        }
                        return true;
                    });
                }
                return true;
            });
            return activeRoutes;
        }

        function setPath(path) {
            $location.path(path);
            $rootScope.showAlerts = false;
        }

        function isActive(route) {
            return route.href === $location.path();
        }

        function hasPerm(permName) {
            return AuthService.hasPermissionOrIsAdmin(permName);
        }

        function getMenuClass(children) {
            var found = false;
            angular.forEach(children, function (child) {
                if (isActive(child)) {
                    found = true;
                }

                if (child.children) {
                    angular.forEach(child.children, function (sub) {
                        if (isActive(sub)) {
                            found = true;
                        }
                    });
                }
            });
            if (found) return 'active-menu-button';
            return '';
        }


        function hasChildPerm(elems) {
            for (var i = 0; elems.length > i; i++) {
                if (elems[i].hasOwnProperty('permission') && AuthService.hasPermissionOrIsAdmin(elems[i].permission)) {
                    return true;
                } else if (!elems[i].hasOwnProperty('permission')) {
                    return true;
                }

                if (elems[i].hasOwnProperty('children') && elems[i].children.length > 0) {
                    hasChildPerm(elems[i].children)
                }
            }
            return false;
        }


        function getItemClass(route) {
            if (isActive(route)) {
                return 'active-menu-button-sub';
            }
            else return '';
        }

        function resetTabIndex() {
            selectedIndex = null;
        }

          $rootScope.$on('changeSidebarTabPosition', function (event, index) {
            selectedIndex = index;
        });

        $scope.$watch(function () {
                return selectedIndex
            },
            function () {
                localStorage.setItem("selectedIndex", selectedIndex);
            });
    },
    templateUrl: 'shared/components/navbar/navbar.html',
    controllerAs: 'navbar'
});