core_module.component('appInfo', {
    controller: function ($mdDialog, HttpService) {
        var vm = this;

        var version = null;
        var parentVersion = null;

        vm.getInfo = getInfo;
        vm.$onInit = init;

        function init() {
            HttpService.get('/auth/version').then(function (response) {
                version = response.data.version;
                parentVersion = response.data.parentVersion;
            });
        }

        function getInfo() {
            $mdDialog.show({
                templateUrl: 'shared/components/appInfo/dialogs/app.info.dialog.html',
                controller: 'AppInfoDialogController',
                controllerAs: 'appInfoDialog',
                clickOutsideToClose: true,
                fullscreen: false,
                locals: {
                    version: version,
                    parentVersion: parentVersion
                }
            });
        }
    },
    templateUrl: 'shared/components/appInfo/app.info.html',
    controllerAs: 'appInfo'
});