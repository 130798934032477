core_module.component('schemeType', {
    controller: function ($scope) {
        $scope.templateTypes = {};

        $scope.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: false,
            add: true

        };


        $scope.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false},
            {
                name: 'deleted',
                field: 'deleted',
                edit: false,
                type: 'number',
                link: false,
                display: false
            },
            {
                name: 'templateType',
                field: 'templateType',
                displayName: 'templateType',
                display: true,
                edit: true,
                type: 'text',
                link: false,
                noSort: false
            }];

        $scope.query = {
            order: 'id',
            limit: 10,
            page: 1
        };
        $scope.initObject = {
            templateType: ''
        }


    },
    templateUrl: 'shared/components/scheme/schemeType.html'
});
