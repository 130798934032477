core_module.factory('RoleService', function (HttpService) {

    var roleService = {
        getCurrentUserRoles: getCurrentUserRoles,
        setCurrentUserRoles: setCurrentUserRoles,
    };


    function getCurrentUserRoles() {
        return HttpService.get('/currentUser/userroles/')
    }

    function setCurrentUserRoles() {
        return getCurrentUserRoles().then(function (response) {
            var currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            currentUser.userRoles = response.data;
            sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
        });
    }

    return roleService;
});
