core_module.component('entryManagement', {
    controller: function ($scope, $translate, $q, $routeParams, $filter, $rootScope, $location, $mdDialog, $timeout, CaseService, PreloaderService, AuthService, AlertService, PdfService) {
        var vm = this;

        vm.structures = [
            {
                name: 'bones_left',
                elements: [
                    {
                        name: 'bones_left',
                        templateUrl: 'resources/images/bones_left.svg'
                    }
                ]
            },
            {
                name: 'amputations_left',
                elements: [
                    {
                        name: 'amputations_left',
                        templateUrl: 'resources/images/amputations_left.svg',
                    }
                ]
            },
            {
                name: 'joints_left',
                elements: [
                    {
                        name: 'joints_left',
                        templateUrl: 'resources/images/joints_left.svg',
                    }
                ]
            },
            {
                name: 'tendons_left',
                elements: [
                    {
                        name: 'tendons_palmar_left',
                        templateUrl: 'resources/images/tendons_palmar_left.svg'
                    },
                    {
                        name: 'tendons_dorsal_left',
                        templateUrl: 'resources/images/tendons_dorsal_left.svg'
                    }
                ]
            },
            {
                name: 'nerves_left',
                elements: [
                    {
                        name: 'nerves_left',
                        templateUrl: 'resources/images/nerves_left.svg'
                    },
                    {
                        name: 'nerves_dorsal_left',
                        templateUrl: 'resources/images/nerves_dorsal_left.svg'
                    }
                ],
                textElements: [
                    {
                        name: 'hidden_nerves_left',
                        text: 'nerves.notShown',
                        templateUrl:'resources/images/hidden_nerves_left.svg'
                    }
                    ]
            },
            {
                name: 'vessels_left',
                elements: [
                    {
                        name: 'vessels_left',
                        templateUrl: 'resources/images/vessels_left.svg'
                    }
                ]
            },
            {
                name: 'tissues_left',
                elements: [
                    {
                        name: 'tissues_dorsal_left',
                        templateUrl: 'resources/images/tissues_dorsal_left.svg'
                    },
                    {
                        name: 'tissues_palmar_left',
                        templateUrl: 'resources/images/tissues_palmar_left.svg'
                    }
                ]
            },
            {
                name: 'burned_left',
                elements: [
                    {
                        name: 'burned_dorsal_left',
                        templateUrl: 'resources/images/burned_dorsal_left.svg'
                    },
                    {
                        name: 'burned_palmar_left',
                        templateUrl: 'resources/images/burned_palmar_left.svg'
                    }
                ]
            },
            {
                name: 'muscles_left',
                elements: [
                    {
                        name: 'muscles_arm_dorsal_left',
                        templateUrl: 'resources/images/muscles_arm_dorsal_left.svg'
                    },
                    {
                        name: 'muscles_arm_palmar_bottom_left',
                        templateUrl: 'resources/images/muscles_arm_palmar_bottom_left.svg'
                    },
                    {
                        name: 'muscles_arm_palmar_top_left',
                        templateUrl: 'resources/images/muscles_arm_palmar_top_left.svg'
                    },
                    {
                        name: 'muscles_hand_dorsal_left',
                        templateUrl: 'resources/images/muscles_hand_dorsal_left.svg'
                    },
                    {
                        name: 'muscles_hand_palmar_left',
                        templateUrl: 'resources/images/muscles_hand_palmar_left.svg'
                    }
                ],
                textElements: [
                    {
                        name: 'hidden_muscles_left',
                        templateUrl: 'resources/images/hidden_muscles_left.svg',
                        text: 'muscles.notShown'
                    }
                ]
            },
            {
                name: 'bones_right',
                elements: [
                    {
                        name: 'bones_right',
                        templateUrl: 'resources/images/bones_right.svg'
                    }
                ]
            },
            {
                name: 'amputations_right',
                elements: [
                    {
                        name: 'amputations_right',
                        templateUrl: 'resources/images/amputations_right.svg',
                    }
                ]
            },
            {
                name: 'joints_right',
                elements: [
                    {
                        name: 'joints_right',
                        templateUrl: 'resources/images/joints_right.svg',
                    }
                ]
            },
            {
                name: 'tendons_right',
                elements: [
                    {
                        name: 'tendons_palmar_right',
                        templateUrl: 'resources/images/tendons_palmar_right.svg'
                    },
                    {
                        name: 'tendons_dorsal_right',
                        templateUrl: 'resources/images/tendons_dorsal_right.svg'
                    }
                ]
            },
            {
                name: 'nerves_right',
                elements: [
                    {
                        name: 'nerves_right',
                        templateUrl: 'resources/images/nerves_right.svg'
                    },
                    {
                        name: 'nerves_dorsal_right',
                        templateUrl: 'resources/images/nerves_dorsal_right.svg'
                    }
                ],
                textElements: [
                    {
                        name: 'hidden_nerves_right',
                        text: 'nerves.notShown',
                        templateUrl:'resources/images/hidden_nerves_right.svg'
                    }
                ]
            },
            {
                name: 'vessels_right',
                elements: [
                    {
                        name: 'vessels_right',
                        templateUrl: 'resources/images/vessels_right.svg'
                    }
                ]
            },
            {
                name: 'tissues_right',
                elements: [
                    {
                        name: 'tissues_dorsal_right',
                        templateUrl: 'resources/images/tissues_dorsal_right.svg'
                    },
                    {
                        name: 'tissues_palmar_right',
                        templateUrl: 'resources/images/tissues_palmar_right.svg'
                    }
                ]
            },
            {
                name: 'burned_right',
                elements: [
                    {
                        name: 'burned_dorsal_right',
                        templateUrl: 'resources/images/burned_dorsal_right.svg'
                    },
                    {
                        name: 'burned_palmar_right',
                        templateUrl: 'resources/images/burned_palmar_right.svg'
                    }
                ]
            },
            {
                name: 'muscles_right',
                elements: [
                    {
                        name: 'muscles_arm_dorsal_right',
                        templateUrl: 'resources/images/muscles_arm_dorsal_right.svg'
                    },
                    {
                        name: 'muscles_arm_palmar_bottom_right',
                        templateUrl: 'resources/images/muscles_arm_palmar_bottom_right.svg'
                    },
                    {
                        name: 'muscles_arm_palmar_top_right',
                        templateUrl: 'resources/images/muscles_arm_palmar_top_right.svg'
                    },
                    {
                        name: 'muscles_hand_dorsal_right',
                        templateUrl: 'resources/images/muscles_hand_dorsal_right.svg'
                    },
                    {
                        name: 'muscles_hand_palmar_right',
                        templateUrl: 'resources/images/muscles_hand_palmar_right.svg'
                    }
                ],
                textElements: [
                    {
                        name: 'hidden_muscles_right',
                        templateUrl: 'resources/images/hidden_muscles_right.svg',
                        text: 'muscles.notShown'
                    }
                ]
            }
        ];

        vm.case = {
            template: {},
            issues: [],
            sheet: {},
            data: {},
            patientSection: {},
            mcase: {},
            treatmentSection: {},
            selectedStructure: []
        };


        vm.$onInit = init;
        vm.filterOutPatientAndTreatment = CaseService.filterOutPatientAndTreatment;
        vm.deleteIssue = deleteIssue;
        vm.back = back;
        vm.svgClick = svgClick;
        vm.svgSelected = svgSelected;
        vm.svgDetailWindowOpen = svgDetailWindowOpen;
        vm.selectStructure = selectStructure;
        vm.saveCase = saveCase;
        vm.openCase = openCase;
        vm.closeCase = closeCase;
        vm.isCaseOpen = isCaseOpen;
        vm.isCaseClosed = isCaseClosed;
        vm.hasCasePermission = hasCasePermission;
        vm.exportToPDF = exportToPDF;

        function init() {
            PreloaderService.particularWaitForDOM('entryPreloader');
            if ($routeParams.id) {
                CaseService.load($routeParams.id).then(function (caseObject) {
                    vm.case = caseObject;
                });
            }
        }

        function deleteIssue(issue) {
            var newCase = angular.copy(vm.case);
            var index = vm.case.issues.indexOf(issue);
            newCase.issues.splice(index, 1);

            for (var prop in newCase.data) {
                if (!newCase.data.hasOwnProperty(prop)) {
                    continue;
                }
                if (newCase.data[prop].sectionVersionCustomId === issue.customId) {
                    delete newCase.data[prop];
                }

            }
            vm.case = newCase;
        }

        function back() {
            $location.path('/');
        }

        function svgClick(id, init) {
            // vm.case = CaseService.svgClick(id, vm.case, init).then(function (updatedCase) {
            CaseService.svgClick(id, vm.case, init).then(function (/*updatedCase*/) {
                if (!init) {
                    CaseService.createIssueModal(id,vm.case).then(function (updatedCase) {
                        console.log('updated', updatedCase);
                        vm.case = updatedCase;
                    });
                }
            });
        }

        function svgSelected(id) {
            return CaseService.svgSelected(id, vm.case);
        }

        function svgDetailWindowOpen(id) {
            return CaseService.svgDetailWindowOpen(id, vm.case);
        }

        function selectStructure(structureName) {
            vm.selectedStructure = vm.structures.find(function (structure) {
                return structure.name === structureName;
            });
        }


        function saveCase() {
            if (validate()) CaseService.saveCaseModal(vm.case);
        }

        function openCase() {
            return CaseService.openCase(vm.case);
        }

        function closeCase() {
            if (validate()) CaseService.closeCaseModal(vm.case);
        }

        function isCaseClosed() {
            return CaseService.isCaseClosed(vm.case);
        }

        function isCaseOpen() {
            return CaseService.isCaseOpen(vm.case);
        }

        function hasCasePermission(namePart) {
            return AuthService.hasPermissionOrIsAdmin('ROLE_P_MCASE_' + namePart);
        }

        function exportToPDF() {
            PdfService.exportCase(vm.case);
        }

        function validate() {
            angular.forEach($scope.caseForm.$$controls, function (field) {
                if (field.$$controls) {
                    field.$setSubmitted();
                    field.$setDirty();
                    angular.forEach(field.$$controls, function (subfield) {
                        subfield.$setTouched();
                        subfield.$setDirty();
                        subfield.$validate();
                    });
                }
                else {
                    field.$setTouched();
                    field.$setDirty();
                    field.$validate();
                }
            });
            if ($scope.caseForm.$invalid) {
                AlertService.renderDialogMessage(" ", "validation.forminvaild");
                return false;
            }
            return true;
        }
    },
    controllerAs: 'entryManagement',
    templateUrl: 'shared/components/entryManagement/entry.management.html'
});
