core_module.component('casefileManagement', {
    controller: function ($scope, $mdDialog, $translate, $location, AuthService, HttpService, $rootScope, AlertService, BreadCrumbsService) {

        $scope.isChild = false;
        $scope.isAdmin = AuthService.isAdmin;

        $scope.status = [{value: 'OPEN', name: 'OPEN'}, {value: 'CLOSED', name: 'CLOSED'}];

        $scope.search = {patientId: ''};

        $scope.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editCondition: editCondition,
            removeCondition: removeCondition,
            editable: true,
            add: AuthService.hasPermissionOrIsAdmin('ROLE_P_CASEFILE_ADMIN'),
            deletable: AuthService.hasPermissionOrIsAdmin('ROLE_P_CASEFILE_DELETE'),
            customActions: [{
                name: 'cases',
                icon: 'dns',
                clickFunction: gotoCases
            }]
        };

        $scope.initObject = {
            patientId: '',
            status: '',
            dateCreated: ''
        };

        $scope.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {
                name: 'dateCreated',
                field: 'dateCreated',
                display: true,
                edit: false,
                type: 'date',
                link: false,
                filter: true
            },
            {
                name: 'status',
                field: 'status',
                display: true,
                edit: false,
                type: 'select',
                selectOptions: $scope.status,
                link: false,
                filter: true
            },
            {name: 'patientId', field: 'patientId', display: true, edit: false, type: 'text', link: false, filter: true}
        ];

        function editCondition(row) {

            // has no open cases

            return row.status === 'OPEN';

        }

        function removeCondition(row) {
            return (AuthService.hasPermissionOrIsAdmin('ROLE_P_CASEFILE_ADMIN') && row.status === 'OPEN');
        }

        $scope.delFunction = function (row) {
            var confirm = $mdDialog.confirm()
                .textContent($translate.instant('casefile.confirm.remove'))
                .ok($translate.instant('ok'))
                .cancel($translate.instant('cancel'));
            $mdDialog.show(confirm).then(function () {
                var showRemoveSuccess = function () {
                    AlertService.renderDialogMessage($translate.instant(' remove successfully'));
                    $rootScope.$broadcast('refreshTable', {entity: 'caseFile'});
                };
                var showErrorRemove = function () {
                    AlertService.renderDialogMessage('remove failed');
                };

                HttpService.head("/mCase/?caseFileId=" + row.id).then(function (response) {
                    AlertService.renderDialogMessage($translate.instant('casefile.error_mcase_exists'));
                }, function (response) {
                    if (response.status === 404 && response.config.method === 'HEAD') {
                        HttpService.shortDelete('/caseFile/' + row.id, showRemoveSuccess, showErrorRemove);
                    }
                });
            });
        };

        $scope.addFunction = function () {
            $mdDialog.show({
                templateUrl: 'shared/components/casefiles/dialogs/add.casefile.html',
                controller: 'CaseFileDialogController',
                fullscreen: false,
                locals: {
                    formData: $scope.formData,
                    caseFile: {},
                    status: $scope.status
                }
            });
        };

        $scope.editFunction = function (row) {

            HttpService.head("/mCase/?status=OPEN&caseFileId=" + row.id).then(function (response) {
                AlertService.renderDialogMessage($translate.instant('casefile.error_open_mcase_exists'));
            }, function (response) {
                if (response.status === 404 && response.config.method === 'HEAD') {
                    $mdDialog.show({
                        templateUrl: 'shared/components/casefiles/dialogs/edit.casefile.html',
                        controller: 'CaseFileDialogController',
                        fullscreen: false,
                        locals: {
                            formData: $scope.formData,
                            caseFile: row,
                            status: $scope.status
                        }
                    });
                }
            });
        };

        function gotoCases(caseFile) {
            BreadCrumbsService.removeAll();
            BreadCrumbsService.addLevel($translate.instant('CaseFiles'), '/caseFileManagement');
            BreadCrumbsService.addLevel($translate.instant('CaseFile') + ': ' + caseFile.patientId, null);
            $rootScope.patientId = caseFile.patientId;
            $location.path('/caseSheetManagement/' + caseFile.id);
        }

        $scope.appendToUrl = function (url) {
            if ($scope.search.patientId && $scope.search.patientId.length > 0) {
                return url + '&patientId=' + $scope.search.patientId; // probably use expdataObject ?!
            } else
                return url;
        };

        $scope.resetSearchForm = function () {
            $scope.search.patientId = "";
        };

    },

    templateUrl: 'shared/components/casefiles/casefileManagement.html'

});