/**
 * Created by Thorsten.Wirth on 11.04.2017.
 */
core_module.controller('FacilityTypeEntryController', function ($rootScope, EntityService, AlertService, DialogService, facilityType, facilityTypeKind) {
    var vm = this;
    var isNewEntry = false;
    var entity = 'facilityType';

    vm.title = 'add_facilityType';

    vm.$onInit = init;
    vm.confirm = confirm;

    function init() {
        vm.facilityTypeKind = facilityTypeKind;
        if (facilityType) {
            vm.facilityType = angular.copy(facilityType);
            vm.title = 'edit_facilityType';
        }
        else {
            vm.facilityParticipation = {};
            isNewEntry = true;
        }
    }

    function confirm() {
        if (isNewEntry) {
            createFacilityType();
        }
        else updateFacilityType();
    }

    function createFacilityType() {
        EntityService.addEntry(entity, vm.facilityType).then(function () {
            $rootScope.$broadcast("refreshTable", {entity: entity});
            AlertService.showToast(entity + 'CreatedSuccessfully');
            DialogService.hideDialog();
        }, function (response) {
            AlertService.renderDialogMessage('app.validation.entryExist', 'app.validation', {name: '"' + vm.facilityType.name + '"'}, response.status);
        });
    }

    function updateFacilityType() {
        EntityService.updateEntry(entity, vm.facilityType).then(function () {
            $rootScope.$broadcast("refreshTable", {entity: entity});
            AlertService.showToast(entity + 'UpdateSuccessfully');
            DialogService.hideDialog();
        }, function (response) {
            AlertService.renderDialogMessage('app.validation.entryExist', 'app.validation', {name: '"' + $scope.facilityType.name + '"'}, response.status);
        });
    }
});