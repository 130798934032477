/**
 * Created by Thorsten.Wirth on 29.03.2017.
 */
core_module.controller('CoreDialogButtonContentTestController', function ($scope, $mdDialog) {
   $scope.text = "Hallo ich bin ein Testtext!";

   $scope.testinput;

   $scope.showTestAlert = function (ev) {
       $mdDialog.show(
           $mdDialog.alert()
               .parent(angular.element(document.querySelector('#popupContainer')))
               .clickOutsideToClose(true)
               .title('This is an alert title')
               .textContent($scope.text)
               .ariaLabel('Alert Dialog Demo')
               .ok('Got it!')
               .targetEvent(ev)
       );
   }


});