core_module.component('statistics', {
    controller: function ($rootScope) {
        var vm = this;

        vm.showChart = false;

        $rootScope.$on('dataForChart', function (event, data) {
            vm.chartData = data;
            if (data.length > 0) {
                vm.showChart = true;
            } else
                vm.showChart = false;
        });


    },
    templateUrl: 'shared/components/boardManagement/statistics/statistics.html',
    controllerAs: 'statistics'
});