/**
 * Created by Thorsten.Wirth on 23.09.2016.
 */
core_module.factory('EntityService', function (HttpService, CONFIG) {

    function addEntry(entity, entry) {
        return HttpService.post('/' + entity + '/', entry);
    }

    function addEntryWithFile(entity, entry) {
        return HttpService.upload('/' + entity + '/', entry);
    }

    function updateEntry(entity, entry) {
        return HttpService.put('/' + entity + '/' + entry.id, entry);
    }

    function updateEntryWithoutId(entity, entry) {
        return HttpService.put('/' + entity, entry);
    }

    function getEntries(url, query, showDeleted) {
        if (typeof showDeleted === 'undefined') showDeleted = false;
        if (typeof query !== 'undefined' && objectHasAnyProperty(query)) {
            return getEntriesWithQuery(url, query, showDeleted);
        }
        return HttpService.get('/' + url);
    }

    function getEntriesWithQuery(url, query, showDeleted) {
        var formattedQuery = formatQuery(query);
        var separator = '?';
        if (url.indexOf('?') > -1)
            separator = '&';

        if (showDeleted) {
            return HttpService.get('/' + url + separator + 'sort=' + formattedQuery.sort
                + "&size=" + formattedQuery.size + "&page=" + formattedQuery.page + '&showDeleted=true');
        } else {
            return HttpService.get('/' + url + separator + 'sort=' + formattedQuery.sort
                + "&size=" + formattedQuery.size + "&page=" + formattedQuery.page);
        }
    }

    function getEntryById(entity, id) {
        return HttpService.get('/' + entity + '/' + id);
    }

    function deleteEntry(entity, id) {
        return HttpService.delete('/' + entity + '/' + id);
    }

    function deleteEntryPhysically(entity, id) {
        return HttpService.delete('/' + entity + '/' + id + '?logdel=false');
    }

    function getMetaModel(entity) {
        return HttpService.get('/' + entity + '/metamodel');
    }

    function getAddresses(entity, id) {
        return HttpService.get('/' + entity + 'Address/?' + entity + 'Id=' + id)
    }

    function objectHasAnyProperty(object) {
        for (var prop in object) {
            if (object.hasOwnProperty(prop)) return true;
        }
        return false;
    }

    function formatQuery(query) {
        var processedQuery = checkQueryParameters(query);
        var requestParams = setRequestParams(processedQuery);
        return requestParams;
    }

    function checkQueryParameters(query) {
        var newQuery = {};
        newQuery.order = (typeof query.order === 'undefined') ? 'id' : query.order;
        newQuery.page = (typeof query.page === 'undefined') ? 1 : query.page;
        newQuery.limit = (typeof query.limit === 'undefined') ? 10 : query.limit;

        if (newQuery.order.charAt(0) === '-') {
            newQuery.order = newQuery.order.substr(1) + ',desc'
        }

        return newQuery;
    }

    function setRequestParams(query) {
        var params = {};
        params.page = query.page;
        params.sort = query.order;
        if (query.limit === -1) params.size = CONFIG.MAX_PAGE_SIZE;
        else params.size = query.limit;
        return params;
    }

    return {
        addEntry: addEntry,
        addEntryWithFile: addEntryWithFile,
        updateEntry: updateEntry,
        updateEntryWithoutId: updateEntryWithoutId,
        getEntries: getEntries,
        deleteEntry: deleteEntry,
        getMetaModel: getMetaModel,
        getEntryById: getEntryById,
        getAddresses: getAddresses,
        deleteEntryPhysically: deleteEntryPhysically
    }
});
