core_module.component('coreTableFilters', {
    bindings: {
        columnDefs: '=?',
        entity: '@',
        performSearch: '&',
        filters: '=',
    },
    controller: function () {
        var vm = this;

        vm.fields = [];

        vm.operators = [];

        vm.update = update;

        function update() {
            var toSave = {};
            vm.filters.forEach(function (filter) {
                toSave[filter.field] = {
                    operator: filter.operator,
                    value: filter.value
                }
            });
            localStorage.setItem('coreTable_' + vm.entity + '_filters', JSON.stringify(toSave));
            vm.performSearch();
        }
    },
    templateUrl: 'shared/components/coreTable/coreTable.filters.html',
    controllerAs:
        'filters'
});
