core_module.component('errorPage', {
    controller: function($location) {
        var vm = this;

        vm.goToHomepage = goToHomepage;

        function goToHomepage() {
            $location.path('/');
        }
    },
    templateUrl: 'shared/components/errorPage/errorPage.html'
});
