core_module.factory('PreloaderService', function ($rootScope, $timeout) {

    function showPreloader(name) {
        $rootScope.$broadcast("showPreloader", name);
    }

    function hidePreloader() {
        $rootScope.$broadcast("hidePreloader", true);
    }

    function hideParticularPreloader(name) {
        $rootScope.$broadcast("hideParticularPreloader", name);
    }

    function waitForDOM() {
        $timeout(function () {
            hidePreloader();
        });
    }

    function particularWaitForDOM(name) {
        $timeout(function () {
            hideParticularPreloader(name);
        });
    }

    return {
        showPreloader: showPreloader,
        hidePreloader: hidePreloader,
        hideParticularPreloader: hideParticularPreloader,
        particularWaitForDOM: particularWaitForDOM,
        waitForDOM: waitForDOM
    };
});
