/**
 * Created by Thorsten.Wirth on 11.04.2017.
 */
core_module.controller('FacilityConnectorAddEntryController', function ($rootScope, $translate, AlertService, EntityService, HttpService, DialogService, facilityId) {
    var vm = this;
    var entity = 'facilityConnector';

    vm.$onInit = init;
    vm.confirm = confirm;

    function init() {
        getFacilities();
    }

    function getFacilities() {
        EntityService.getEntries('facility', {limit: -1}).then(function (response) {
            vm.facilities = response.data.content.map(function (item) {
                return {name: item.name, value: item.id}
            });
        });
    }

    function confirm() {
        HttpService.post('/facilityConnector?parentFacilityId=' + facilityId + '&childFacilityId=' + vm.childFacilityId).then(function () {
            $rootScope.$broadcast("refreshTable", {entity: entity});
            AlertService.showToast(entity + 'CreatedSuccessfully');
            DialogService.hideDialog();
        });
    }
});