core_module.component('userAddressesManagement', {
    bindings: {
        parentId: '<?'
    },
    controller: function ($scope, $mdDialog, EntityService) {
        $scope.userId = (this.parentId) ? this.parentId : null;

        this.$onChanges = function (changes) {
            if (changes.hasOwnProperty('parentId')) {
                $scope.userId = (this.parentId) ? this.parentId : null;
            }
        };

        $scope.getUserAddressUrl = function () {
            if ($scope.userId) {
                return '/userAddress?userId=' + $scope.userId;
            }
        };


        var addressForSelect = [];
        var addressTypeForSelect = [];
        var usersForSelect = [];

        function getAddressForSelect() {
            EntityService.getEntries('address').then(function (response) {
                addressForSelect.length = 0;
                response.data.content.forEach(function (element) {
                    addressForSelect.push({value: element.id, name: element.city });
                });
            })
        }

        function getAddressTypeForSelect() {
            EntityService.getEntries('addressType').then(function (response) {
                addressTypeForSelect.length = 0;
                response.data.content.forEach(function (element) {
                    addressTypeForSelect.push({value: element.id, name: element.name })
                });
            })
        }

        function getUsersForSelect() {
            EntityService.getEntries('users').then(function (response) {
                usersForSelect.length = 0;
                response.data.content.forEach(function (element) {
                    usersForSelect.push({value: element.id, name: element.firstName + " " + element.lastName })
                });
            })
        }

        getAddressForSelect();
        getAddressTypeForSelect();
        getUsersForSelect();

        $scope.options = {
            rowSelection: false,
            title: 'Users/Address',
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            add: true
        };

        $scope.initObject = {
            id: null,
            address_id: null,
            addressType_id: null,
            user_id: null
        };

        $scope.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false},
            {name: 'address', field: 'address_id', display: true, edit: true, type: 'select', selectOptions: addressForSelect,
                link: {
                    id: 'address_id',
                    entity: 'address',
                    properties: ['zip', 'city', 'street']
                }
            },
            {name: 'addressType', field: 'addressType_id', display: true, edit: true, type: 'select',
                link: {
                    id: 'addressType_id',
                    entity: 'addressType',
                    properties: ['name']
                }
            },
            {name: 'user', field: 'user_id', display: true, edit: true, type: 'select',
                link: {
                    id: 'user_id',
                    entity: 'users',
                    properties: ['firstName', 'lastName']
                }
            }
        ];

        $scope.addUserAddress = function () {
            $mdDialog.show({
                templateUrl: 'shared/components/userAddressesManagement/dialogs/add.userAddress.html',
                controller: 'UserAddressDialogsController',
                fullscreen: true,
                locals: {
                    entriesArray: $scope.arrUserAddress,
                    userAddress: null,
                    addressTypeForSelect: addressTypeForSelect,
                    addressForSelect: addressForSelect,
                    usersForSelect: usersForSelect
                }
            });
        };

        $scope.editUserAddress = function (userAddress) {
            $mdDialog.show({
                templateUrl: 'shared/components/userAddressesManagement/dialogs/edit.userAddress.html',
                controller: 'UserAddressDialogsController',
                fullscreen: true,
                locals: {
                    entriesArray: $scope.arrUserAddress,
                    userAddress: userAddress,
                    addressTypeForSelect: addressTypeForSelect,
                    addressForSelect: addressForSelect,
                    usersForSelect: usersForSelect
                }
            });
        }
    },
    templateUrl: 'shared/components/userAddressesManagement/userAddressesManagement.html'
});