core_module.component('schemeManagement', {
    controller: function (EntityService, $mdDialog, $location) {
        var vm = this;

        vm.addScheme = addScheme;
        vm.$onInit = init;

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: false,
            add: true,
            toolbar: {
                customButtons: [
                    {
                        name: 'scheme.CSVImport',
                        clickFunction: openImportDialog
                    }
                ]
            },
            customActions: [{
                name: 'scheme.openDetail',
                icon: 'exit_to_app',
                clickFunction: openDetail
            }]
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {
                name: 'deleted',
                field: 'deleted',
                edit: false,
                type: 'number',
                link: false,
                display: false
            },
            {
                name: 'activeFrom',
                field: 'activeFrom',
                displayName: 'activeFrom',
                display: true,
                edit: true,
                type: 'date',
                link: false,
                noSort: false,
                filter: true
            },
            {
                name: 'activeTo',
                field: 'activeTo',
                displayName: 'activeTo',
                display: true,
                edit: true,
                type: 'date',
                link: false,
                noSort: false,
                filter: true
            },
            {
                name: 'customId',
                field: 'customId',
                displayName: 'CustomID',
                display: true,
                edit: true,
                type: 'text',
                link: false,
                noSort: false,
                filter: true
            },
            {
                name: 'name',
                field: 'name',
                displayName: 'Name',
                display: true,
                edit: true,
                type: 'text',
                link: false,
                noSort: false,
                filter: true
            },
            {
                name: 'templateType', field: 'templateType', display: true, edit: false, type: 'select', link: false
            }
        ];

        function init() {
            getTemplateTypes();
        }

        function getTemplateTypes() {
            var templateTypes = [];
            EntityService.getEntries("documentTemplateType/selectable", {limit: -1}, false).then(function (response) {
                templateTypes = response.data.content.map(function (item) {
                    return {name: item.templateType, value: item.id}
                });
                vm.options.search = [
                    {field: 'templateTypeId', type: 'select', options: templateTypes},
                    {field: 'activeFrom', type: 'date'},
                    {field: 'activeTo', type: 'date'}
                ]
            })
        }

        function openDetail(template) {
            $location.path("/scheme/" + template.id);
        }

        function addScheme() {
            $mdDialog.show({
                templateUrl: 'shared/components/scheme/dialogs/schemeDocument.html',
                controller: 'SchemeDocumentController',
                controllerAs: 'schemeDocumentDialog',
                locals: {
                    document: null
                }
            }).then(function(scheme) {
                openDetail(scheme);
            })
        }

        function openImportDialog() {
            $mdDialog.show({
                templateUrl: 'shared/components/scheme/csv/importCSV.html',
                controller: 'ImportCSVController',
                controllerAs: 'importCsvDialog',
                fullscreen: true,
            });
        }
    },
    templateUrl: 'shared/components/scheme/schemeManagement.html',
    controllerAs: 'schemes'
});
