core_module.component('noFacilityInfo', {
    controller: function ($scope, AuthService, $translate) {
        var vm = this;

        vm.noFacility = false;
        vm.$onInit = init;

        function init() {
            if(AuthService.isSignedIn()) checkCurrentFacility()
        }

        $scope.$on('changeUserState', function (event, isSignedIn) {
           if(isSignedIn) checkCurrentFacility();
           else vm.noFacility = false;
        });

        function checkCurrentFacility() {
            if(!AuthService.hasCurrentUserFacility()) {
                vm.noFacility = true;
            }
        }
    },
    templateUrl: 'shared/components/noFacilityInfo/noFacilityInfo.html',
    controllerAs: 'facilityInfo'
});