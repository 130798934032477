core_module.component('facilityAddressManagement', {
    bindings: {
        parentId: '<?',
        facilityName: '<?'
    },
    controller: function ($mdDialog) {
        var vm = this;

        vm.getAddressUrl = getAddressUrl;
        vm.addEntry = addEntry;
        vm.editEntry = editEntry;

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            add: true
        };

        vm.initObject = {
            deleted: false,
            name: ''
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false},
            {
                name: 'street',
                field: 'address',
                display: true,
                edit: true,
                type: 'text',
                link: false

            },
            {
                name: 'zip',
                field: 'address',
                display: true,
                edit: true,
                type: 'text',
                link: false

            },
            {
                name: 'city',
                field: 'address',
                display: true,
                edit: true,
                type: 'text',
                link: false

            },
            {
                name: 'addressType.title',
                field: 'addressType.title',
                display: true,
                edit: true,
                type: 'text',
                link: {
                    id: 'addressType_id',
                    entity: 'addressType',
                    properties: ['name']
                },
                displayName: 'addressType.title'

            },
            {
                name: 'address.country',
                field: 'address',
                display: true,
                edit: true,
                type: 'text',
                link: {
                    id: 'country_id',
                    entity: 'country',
                    properties: ['countryCode']
                },
                displayName: 'address.country'

            }
        ];

        function getAddressUrl() {
            if (vm.parentId) {
                return '/facilityAddress?facilityId=' + vm.parentId;
            }
        }

        function addEntry() {
            $mdDialog.show({
                templateUrl: 'shared/components/facilityAddressManagement/dialogs/facilityAddressEntry.html',
                controller: 'FacilityAddressController',
                controllerAs: 'addressDialog',
                fullscreen: true,
                locals: {
                    facilityId: vm.parentId,
                    initObject: vm.initObject,
                    facilityAddress: null
                }
            });
        }

        function editEntry(row) {
            $mdDialog.show({
                templateUrl: 'shared/components/facilityAddressManagement/dialogs/facilityAddressEntry.html',
                controller: 'FacilityAddressController',
                controllerAs: 'addressDialog',
                fullscreen: true,
                locals: {
                    facilityId: vm.parentId,
                    initObject: vm.initObject,
                    facilityAddress: row
                }
            });
        }
    },
    templateUrl: 'shared/components/facilityAddressManagement/facilityAddresses.html',
    controllerAs: 'addresses'
});