core_module.component('exportFilesTable', {
    templateUrl: 'shared/components/exportCsv/exportFilesTable/exportFilesTable.html',
    controller: function ($scope, $mdDialog, $rootScope, $q, HttpService, EntityService) {
        var promisses = [];
        var filetypesSelectOptions = [];
        var documentTemplateTypeSelectOptions = [];
        var exportSelectOptions = [];

        init();

        function init() {
            var filetypesUrl = 'export_files/filetypes';
            var exportsUrl = 'exports';
            var documentTemplateTypeUrl = 'documentTemplateType';

            promisses[0] = EntityService.getEntries(filetypesUrl);
            promisses[1] = EntityService.getEntries(documentTemplateTypeUrl);

            $q.all(promisses).then(function (dataArray) {
                var j, tmpArray = [];
                var selectOption = {};

                if (dataArray[0].data) { // Filetypes objects
                    tmpArray = dataArray[0].data;
                    for (j=0; j < tmpArray.length; j++) {
                        selectOption = {};
                        selectOption.name = tmpArray[j];
                        selectOption.value = tmpArray[j];
                        filetypesSelectOptions.push(selectOption);
                    }
                }

                if (dataArray[1].data) { // documentTemplateType objects
                    tmpArray = dataArray[1].data.content;
                    for (j=0; j < tmpArray.length; j++) {
                        selectOption = {};
                        selectOption.name = tmpArray[j].templateType;
                        selectOption.value = tmpArray[j].id;
                        documentTemplateTypeSelectOptions.push(selectOption);
                    }
                }

            }, function (response) {
                AlertService.renderErrorMessage(response.data.details.cause, response.data.details, response.status);
            });
        }

        $scope.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            showDeleted: true,
            add: true
        };

        $scope.query = {
            order: 'id',
            limit: 10,
            page: 1
        };

        $scope.initObject = {
            id: null
        };

        $scope.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {
                name: 'documentTemplateType',
                field: 'documentTemplateType',
                display: false,
                displayName: 'Bogentyp',
                editrules: {required: true},
                edit: true,
                type: 'select',
                selectOptions: documentTemplateTypeSelectOptions,
                link: false,
                filter: true
            },
            {
                name: 'documentTemplateTypeName',
                field: 'documentTemplateType_Name',
                display: true,
                displayName: 'Bogentyp',
                edit: false,
                type: 'text',
                link: false,
                filter: false
            },
            {
                name: 'fileName',
                field: 'fileName',
                display: true,
                edit: true,
                type: 'text',
                link: false,
                filter: true
            },
            {
                name: 'fileType',
                field: 'fileType',
                display: true,
                edit: true,
                type: 'select',
                selectOptions:filetypesSelectOptions,
                filter: true
            }
        ];

    }
});
