/**
 * Created by Thorsten.Wirth on 12.09.2016.
 */

core_module.controller('SchemeDocumentController', function ($rootScope, DialogService, AlertService, PreloaderService, EntityService, HttpService, document) {
    var vm = this;
    var isNewEntry = false;

    vm.title = 'scheme.addDocument';
    vm.documentTemplateTypeSelectOptions = [];

    vm.$onInit = init;
    vm.confirm = confirm;

    function init() {
        getDocumentTemplateTypes();
        prepareDocument();
    }

    function prepareDocument() {
        if (document) {
            vm.document = angular.copy(document);
            vm.title = 'scheme.editDocument';
        }
        else {
            vm.document = {
                children: [],
                activeFrom: '',
                activeTo: '',
                name: '',
                customId: ''
            };
            isNewEntry = true;
        }
    }

    function getDocumentTemplateTypes() {
        EntityService.getEntries("documentTemplateType").then(function (response) {
            vm.documentTemplateTypeSelectOptions = response.data.content.map(function (type) {
                return ({name: type.templateType, value: type.id});
            });
        });
    }

    function confirm() {
        PreloaderService.showPreloader('coreDialog');
        HttpService.shortPut('/documentTemplate/' + vm.document.id, vm.document, function (response) {
            PreloaderService.hideParticularPreloader('coreDialog');
            AlertService.showToast('scheme.created');
            DialogService.hideDialog(response.data);
        }, function () {
            PreloaderService.hideParticularPreloader('coreDialog');
        });
    }
});