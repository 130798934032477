core_module.directive("focusWhen", function ($timeout) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                scope.$on(attrs.focusWhen, function () {
                    $timeout(function () {
                        var input = angular.element(element.find('input'))[0];
                        input.focus();
                    });
                });
            }
        }
    }
);