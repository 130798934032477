/**
 * Created by Thorsten.Wirth on 09.01.2017.
 */
core_module.controller('ManageUnitsDialogController', function ($rootScope, $mdDialog, $translate, EntityService, AlertService, DialogService, parameterId, parameterName) {
    var vm = this;

    vm.parameterName = parameterName;

    vm.$onInit = init;
    vm.saveThis = saveThis;
    vm.addUnit = addUnit;
    vm.editUnit = editUnit;

    function init() {
        getParameter();
    }

    function getParameter() {
        EntityService.getEntryById("parameters", parameterId).then(function (response) {
            vm.parameter = response.data;
        });
    }

    function saveThis(unit) {
        var confirm = $mdDialog.confirm({
            multiple: true
        })
            .title($translate.instant('parameter.setSIUnit'))
            .ok('OK')
            .cancel($translate.instant('cancel'));

        $mdDialog.show(confirm).then(function () {
            setSIUnit(unit);
        });
    }

    function setSIUnit(unit) {
        var updatedParameter = angular.copy(vm.parameter);
        var index = updatedParameter.unit.findIndex(function (el) {
            return el.name === unit.name;
        });
        updatedParameter.unit.splice(index, 1);
        updatedParameter.unit.push(updatedParameter.siUnit);
        updatedParameter.siUnit = unit;
        EntityService.updateEntry("parameters", updatedParameter).then(function (response) {
            vm.parameter = response.data;
            $rootScope.$broadcast("refreshTable", {entity: "parameters"});
        });
    }

    function addUnit() {
        $mdDialog.show({
            templateUrl: 'shared/components/parameter/dialogs/unit.html',
            controller: 'UnitController',
            controllerAs: 'unitDialog',
            fullscreen: false,
            multiple: true,
            locals: {
                unit: null,
                parameter: vm.parameter
            }
        }).then(function () {
            getParameter();
        });
    }

    function editUnit(unit) {
        $mdDialog.show({
            templateUrl: 'shared/components/parameter/dialogs/unit.html',
            controller: 'UnitController',
            controllerAs: 'unitDialog',
            fullscreen: false,
            multiple: true,
            locals: {
                unit: unit,
                parameter: vm.parameter
            }
        }).then(function () {
            getParameter();
        });
    }
});