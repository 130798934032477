core_module.component('changePassword', {
    controller: function ($mdDialog) {
        var vm = this;

        vm.changePassword = changePassword;

        function changePassword() {
            $mdDialog.show({
                templateUrl: 'shared/components/changePassword/dialogs/new.password.html',
                controller: 'NewPasswordController',
                controllerAs: 'newPasswordDialog',
                fullscreen: true
            });
        }
    },
    templateUrl: 'shared/components/changePassword/changePassword.html',
    controllerAs: 'changePassword'
});