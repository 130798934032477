/**
 * Created by Thorsten.Wirth on 14.09.2016.
 */
core_module.controller('SchemeSaveController', function (HttpService, PreloaderService, DialogService, AlertService, uuid, data) {
    var vm = this;

    var treeData = angular.copy(data);

    vm.saveScheme = saveScheme;

    function saveScheme() {
        PreloaderService.showPreloader('saveDialog');
        checkForModifications(treeData, 0);
        updateTree(treeData.children);
        processRoot(treeData);

        if (treeData.id) {
            updateScheme();
        } else {
            createScheme();
        }
    }

    function updateTree(children) {
        children.forEach(function (child, index) {
            if (child.hasOwnProperty('ok')) child.ok = index + 1;
            else if (child.hasOwnProperty('orderKey')) child.orderKey = index + 1;

            if (child.hasOwnProperty('modified')) delete child.modified;

            if (child.children && child.children.length) {
                updateTree(child.children);
            }
            else if (child.entries && child.entries.length) {
                updateTree(child.entries);
            }
            else if (child.repeatEntries && child.repeatEntries.length) {
                updateTree(child.repeatEntries);
            }
        });
    }

    function checkForModifications(node, level) {
        var isChildModified = false;

        if (node.children && node.children.length) {
            isChildModified = checkDescendants(node.children, level + 1);
        }
        else if (node.entries && node.entries.length) {
            isChildModified = checkDescendants(node.entries, level + 1);
        }
        else if (node.repeatEntries && node.repeatEntries.length) {
            isChildModified = checkDescendants(node.repeatEntries, level + 1);
        }
        if (level > 0 && (isChildModified || updateIdentifier(node))) node.identifier = uuid.v4();

        return !!isChildModified || !!node.modified;
    }

    function checkDescendants(elements, level) {
        var isChildModified = false;
        elements.forEach(function (element) {
            isChildModified = checkForModifications(element, level) ? true : isChildModified;
        });
        return isChildModified;
    }

    function updateIdentifier(node) {
        return node.modified || !node.hasOwnProperty('identifier');
    }

    function processRoot(root) {
        if (root.hasOwnProperty('modified')) delete root.modified;
    }

    function updateScheme() {
        HttpService.shortPut('/documentTemplate/' + treeData.id, treeData, function (response) {
            PreloaderService.hideParticularPreloader('saveDialog');
            AlertService.showToast('scheme.updated');
            DialogService.hideDialog(response.data);
        }, function () {
            PreloaderService.hideParticularPreloader('saveDialog');
        });
    }

    function createScheme() {
        HttpService.shortPost('/documentTemplate', treeData, function (response) {
            PreloaderService.hideParticularPreloader('saveDialog');
            AlertService.showToast('scheme.created');
            DialogService.hideDialog(response.data);
        }, function () {
            PreloaderService.hideParticularPreloader('saveDialog');
        });
    }
});