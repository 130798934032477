core_module.component('coreDialogButtonContent', {
    transclude: true,
    bindings: {
        defaultFunction: "&?",
        defaultButtonName: '@?',
        form: '=?',
        autoFocus: '<?',
        closeFunction: "&?",
        noScroll: "<?"
    },
    controller: function (DialogService, AlertService, $translate) {
        var vm = this;

        vm.showDefault = false;
        vm.close = close;
        vm.defaultButtonAction = defaultButtonAction;
        vm.$onInit = init;

        function init() {
            if (vm.defaultFunction) {
                vm.showDefault = true;
            }

            if (!vm.defaultButtonName) {
                vm.defaultButtonText = "ok"
            }
            else {
                vm.defaultButtonText = $translate.instant(vm.defaultButtonName);
            }
        }

        function close() {
            if (angular.isFunction(vm.closeFunction)) {
                vm.closeFunction();
            }
            DialogService.cancelDialog();
        }

        function defaultButtonAction() {
            if(validate()) vm.defaultFunction();
        }

        function validate() {
            angular.forEach(vm.form.$$controls, function (field) {
                if (field.$$controls) {
                    field.$setSubmitted();
                    field.$setDirty();
                    angular.forEach(field.$$controls, function (subfield) {
                        validateField(subfield)
                    });
                }
                else {
                    validateField(field)
                }
            });
            if(vm.form.$invalid) {
                AlertService.renderDialogMessage(" ","validation.forminvaild");
                return false;
            }
            return true;
        }

        function validateField(field) {
            field.$setTouched();
            field.$setDirty();
            field.$validate();
        }
    },
    templateUrl: 'shared/components/coreDialogButtonContent/coreDialogButtonContent.html',
    controllerAs: 'coreDialogButtons'
});