core_module.component('userAccount', {
    controller: function ($scope, $mdDialog, AuthService) {

        $scope.editUserAccount = editUserAccount;

        function editUserAccount() {
            var currentUser = AuthService.getCurrentUser();
            $mdDialog.show({
                templateUrl: 'shared/components/usersManagement/dialogs/edit.user.html',
                controller: 'EditUserDialogController',
                fullscreen: true,
                locals: {
                    user: currentUser
                }
            });
        }
    },
    templateUrl: 'shared/components/userAccount/userAccount.html'
});