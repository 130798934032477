/**
 * Created by Thorsten.Wirth on 27.03.2017.
 */
core_module.factory('LanguageService', function ($translate, $q, HttpService) {
    var languageService = {
        getCurrentUserLanguage: getCurrentUserLanguage,
        getMappedLanguages: getMappedLanguages,
        getLanguages: getLanguages
    };

    function getMappedLanguages() {
        var deferred = $q.defer();
        getLanguages().then(function (response) {
            var options = response.data.map(function (locale) {
                return {'value': locale, 'name': locale}
            });
            deferred.resolve(options);
        });
        return deferred.promise;
    }

    function getLanguages() {
        return HttpService.get('/localizations/locallanguages');
    }

    function getCurrentUserLanguage() {
        return $translate.use();
    }

    return languageService;
});