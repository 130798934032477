core_module.component('sectionForm', {
    bindings: {
        model: '=?',
        section: '=?'
    },
    // http://schemaform.io todo currently the section needs to be a leaf section, i.e. there must be entries in the section. this needs to be extended so that we can pass sections with subsections - adjust the model

    controller: function ($rootScope, $scope, $translate) {
        var vm = this;

        vm.scheme = {};
        vm.form = {};

        vm.$onInit = init;

        function init() {
            if (vm.section) {
                vm.schema = generateSchemaFromSection(vm.section);
                vm.form = generateFormFromSection(vm.section);
            }
            setFormName();
        }

        function setFormName() {
            vm.formName = vm.section.customId.replace(/[\. ,:-]+/g, '_');
        }

        function generateFormFromSection(section) {
            var form = [];
            if (section.entries) {
                section.entries.sort(function (a, b) {
                    return a.ok - b.ok;
                });
            }
            section.entries.forEach(function (entry) {
                var formEntry = {
                    "title": $translate.instant(entry.customId),
                    "sanitizedName": entry.customId.replace(/[\. ,:-]+/g, '_'),
                    "key": entry.customId,
                    "placeholder": entry.ph,
                    "required": entry.m,
                    "description": entry.d,
                    "type": getFormEntryType(entry.ct)
                };

                switch (entry.ct) {
                    case 'ENUMERATION':
                        handleFormEnumeration(entry, formEntry);
                        break;
                    case 'ARRAY':
                        handleFormArray(entry, formEntry);
                        break;
                    case 'TIME':
                        handleFormTime(entry, formEntry);
                        break;
                    case 'AGE':
                        handleFormBirthday(entry, formEntry);
                        break;
                    case 'PARAMETER':
                        handleFormParameter(entry, formEntry);
                        break;
                    case 'REPEAT':
                        handleFormRepeat(entry, formEntry);
                        break;
                }
                form.push(formEntry);
            });
            return form;
        }

        function generateSchemaFromSection(section) {
            var schema = {
                type: "object",
                title: "Schema",
                properties: {}
            };

            section.entries.forEach(function (entry) {
                var schemaEntry = {
                    "title": $translate.instant(entry.customId),
                    "type": getSchemaEntryType(entry.ct),
                    "pattern": entry.p ? entry.p : '',
                    "minlength": entry.mi ? entry.mi : undefined,
                    "maxlength": entry.ma ? entry.ma : undefined,
                    "validationMessage": $translate.instant(entry.vm),
                    "description": $translate.instant(entry.d),
                    "required": entry.m ? entry.m : false,
                    "readonly": entry.da ? entry.da : false,
                    "default": entry.dv ? entry.dv.name : undefined
                };

                switch (entry.ct) {
                    case 'DATE':
                        handleSchemaDate(entry, schemaEntry);
                        break;
                    case 'AGE':
                        handleSchemaBirthday(entry, schemaEntry);
                        break;
                    case 'TIME':
                        handleSchemaTime(entry, schemaEntry);
                        break;
                    case 'SLIDER':
                        handleSchemaSlider(entry, schemaEntry);
                        break;
                    case 'ENUMERATION':
                        handleSchemaEnumeration(entry, schemaEntry);
                        break;
                    case 'ARRAY':
                        handleSchemaArray(entry, schemaEntry);
                        break;
                    case 'REPEAT':
                        handleSchemaRepeat(entry, schemaEntry);
                        break;
                }
                schema.properties[entry.customId] = schemaEntry;
            });
            return schema;
        }

        function handleFormEnumeration(entry, formEntry) {
            formEntry.inputType = "enumeration";
            formEntry.titleMap = [];
            fillTitleMap(entry, formEntry);
        }

        function handleFormArray(entry, formEntry) {
            formEntry.titleMap = [];
            fillTitleMap(entry, formEntry);
        }

        function handleFormTime(entry, formEntry) {
            formEntry.format = "HH.mm.ss";
        }

        function handleFormBirthday(entry, formEntry) {
            formEntry.placeholder = "core.birthday.age";
            formEntry.schema = {relative: entry.r};
        }

        function handleFormParameter(entry, formEntry) {
            formEntry.parameter = entry.pa;
        }

        function handleFormRepeat(entry, formEntry) {
            formEntry.key = entry.customId;
            formEntry.add = $translate.instant("add_new_repeat");
            formEntry.style = {
                add: "btn-success"
            };
            formEntry.items = formEntry.items ? formEntry.items : [];
            entry.repeatEntries.forEach(function (contentValue) {
                var strKey = entry.customId + "[]." + contentValue.customId;
                var opt = {
                    key: strKey,
                    type: getRepeatEntryType(contentValue.ct),
                    title: contentValue.customId,
                    sanitizedName: contentValue.customId.replace(/[\. ,:-]+/g, '_'),
                    required: contentValue.m,
                    validationMessage: $translate.instant(contentValue.vm),
                    description: $translate.instant(contentValue.d),
                    minlength: contentValue.mi ? contentValue.mi : undefined,
                    maxlength: contentValue.ma ? contentValue.ma : undefined,
                    condition: contentValue.vc ? contentValue.vc : undefined
                };
                if (contentValue.ct === 'TIME') {
                    opt.format = "HH.mm.ss";
                    opt.schema = {type: 'time'};
                }
                if (contentValue.ct === 'AGE') {
                    opt.format = ".mm.ss";
                    opt.schema = {type: 'time'};
                }
                else if (contentValue.ct === 'ENUMERATION') {
                    opt.type = "radios-inline";
                    opt.inputType = "enumeration";
                } else if (contentValue.ct === 'PARAMETER') {
                    opt.type = "parameter";
                    opt.parameter = contentValue.pa;
                } else if (contentValue.ct === 'ARRAY') {
                    opt.type = "checkboxes";
                }

                if (contentValue.ct === 'ENUMERATION' || contentValue.ct === 'ARRAY') {
                    opt.titleMap = [];
                    contentValue.cv.sort(function (a, b) {
                        return a.orderKey - b.orderKey;
                    });
                    contentValue.cv.forEach(function (cV) {
                        opt.titleMap.push({
                            "value": cV.name,
                            "name": $translate.instant(cV.name)
                        })
                    });
                }
                formEntry.items.push(opt);
            });
        }


        function fillTitleMap(entry, formEntry) {
            entry.cv.sort(function (a, b) {
                return a.orderKey - b.orderKey;
            })
            entry.cv.forEach(function (contentValue) {
                formEntry.titleMap.push({
                    "value": contentValue.name,
                    "name": $translate.instant(contentValue.name)
                })
            });
        }

        function handleSchemaDate(entry, schemaEntry) {
            schemaEntry.format = "date";
        }

        function handleSchemaBirthday(entry, schemaEntry) {
            schemaEntry.format = "birthday";
            schemaEntry.relativeDate = vm.model[entry.r];
        }

        function handleSchemaTime(entry, schemaEntry) {
            schemaEntry.format = "time";
        }

        function handleSchemaSlider(entry, schemaEntry) {
            var min = 0;
            if (entry.mi) min = entry.mi;
            schemaEntry.default = entry.dv ? entry.dv.name : min;
        }

        function handleSchemaEnumeration(entry, schemaEntry) {
            schemaEntry.enum = [];
            entry.cv.forEach(function (contentValue) {
                schemaEntry.enum.push(contentValue.name)
            });
        }

        function handleSchemaArray(entry, schemaEntry) {
            schemaEntry.items = {type: "string", enum: []};
            entry.cv.forEach(function (contentValue) {
                schemaEntry.items.enum.push(contentValue.name)
            });
        }

        function handleSchemaRepeat(entry, schemaEntry) {
            schemaEntry.minItems = 1;
            schemaEntry.items = {type: "object", properties: {}};

            entry.repeatEntries.forEach(function (contentValue) {
                schemaEntry.items.properties[contentValue.customId] = {
                    title: contentValue.customId,
                    type: getRepeatEntryType(contentValue.ct),
                    default: contentValue.dv ? contentValue.dv.name : undefined,
                    validationMessage: $translate.instant(contentValue.vm),
                    description: $translate.instant(contentValue.d),
                };

                if (contentValue.ct === 'TIME') {
                    schemaEntry.items.properties[contentValue.customId].format = 'time';
                } else if (contentValue.ct === 'ARRAY') {

                    schemaEntry.items.properties[contentValue.customId].type = "array";
                    schemaEntry.items.properties[contentValue.customId].items = {
                        default: [],
                        type: "string",
                        enum: []
                    };

                    contentValue.cv.forEach(function (cV) {
                        schemaEntry.items.properties[contentValue.customId].items.enum.push(cV.name)
                    })
                } else if (contentValue.ct === 'ENUMERATION') {
                    schemaEntry.items.properties[contentValue.customId].type = "enumeration";
                    schemaEntry.items.properties[contentValue.customId].enum = [];

                    contentValue.cv.forEach(function (cV) {
                        schemaEntry.items.properties[contentValue.customId].enum.push(cV.name)
                    });
                }
            });
        }

        function getSchemaEntryType(type) {
            var types = {
                STRING: 'string',
                ARRAY: 'checkboxes_section',
                ENUMERATION: 'enumeration',
                NUMBER: 'number',
                TIME: 'date',
                PARAMETER: 'string',
                DATE: 'date',
                AGE: 'date',
                REPEAT: 'array',
                SLIDER: 'string'
            };
            return types[type];
        }

        function getFormEntryType(type) {
            var types = {
                STRING: 'string',
                ARRAY: 'checkboxes',
                ENUMERATION: 'radios-inline',
                NUMBER: 'number',
                TIME: 'time',
                PARAMETER: 'parameter',
                DATE: 'date',
                AGE: 'birthday',
                REPEAT: 'array',
                SLIDER: 'slider',
            };
            return types[type];
        }

        function getRepeatEntryType(type) {
            var types = {
                STRING: 'string',
                ARRAY: 'checkboxes',
                ENUMERATION: 'enumeration',
                NUMBER: 'number',
                TIME: 'time',
                PARAMETER: 'parameter',
                DATE: 'date',
                AGE: 'date',
                REPEAT: 'array',
                SLIDER: 'slider'
            };
            return types[type];
        }

    },
    templateUrl: 'shared/components/sectionForm/sectionForm.html',
    controllerAs: 'sectionForm'
});