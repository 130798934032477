core_module.component('permissions', {
    controller: function () {
        var vm = this;

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: true,
            add: true
        };

        vm.query = {
            order: 'name',
            limit: 10,
            page: 1
        };

        vm.initObject = {
            id: null,
            name: null
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {name: 'name', field: 'name', display: true, edit: true, type: 'text', link: false, filter: true}
        ];

    },
    templateUrl: 'shared/components/permissions/permissions.html',
    controllerAs: 'permissions'
});