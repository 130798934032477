core_module.controller('CaseFileDialogController', function ($scope, $rootScope, $translate, $mdDialog, HttpService, AlertService, EntityService, DialogService, caseFile, status) {

    $scope.caseFile = caseFile;
    $scope.status = status;

    $scope.cancel = function () {
        $mdDialog.cancel();
    };

    $scope.addConfirm = function () {

        var newCaseFile = {
            patientId: $scope.caseFile.patientId
        };

        HttpService.head("/caseFile/?patientId=" + newCaseFile.patientId).then(function (response) {
            AlertService.renderDialogMessage($translate.instant('casefile.error_file_exists'));
        }, function (response) {
            if (response.status === 404 && response.config.method === 'HEAD') {
                EntityService.addEntry('caseFile', newCaseFile).then(function (response) {
                    AlertService.showToast($translate.instant('casefiles.stored'));
                    $rootScope.$broadcast('refreshTable', {entity: 'caseFile'});
                }, function (response) {
                    AlertService.renderGenericMessage(response);
                });
            } else {
                AlertService.renderGenericMessage(response);
            }

        });

        DialogService.closeDialog();
    };

    $scope.editConfirm = function (caseFile) {

        EntityService.updateEntry('caseFile', caseFile).then(function (response) {
            AlertService.showToast($translate.instant('casefiles.saved'));
            $rootScope.$broadcast('refreshTable', {entity: 'caseFile'});
        }, function (response) {
        });

        DialogService.closeDialog();
    }

});