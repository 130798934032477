core_module.controller('FacilityDialogsController', function ($rootScope, $mdDialog, $translate, EntityService, AlertService, DialogService, formData, facility, initObject) {
    var vm = this;
    var isNewEntry = false;
    var facilityTypes = [];

    vm.title = 'add_facility';
    vm.formData = formData;

    vm.$onInit = init;
    vm.confirm = confirm;
    vm.onFacilityChange = onFacilityChange;

    function init() {
        getFacilityTypes();
        initDialogData();
    }

    function getFacilityTypes() {
        EntityService.getEntries('facilityType').then(function (response) {
            vm.facilityTypes = response.data.content.map(function (type) {
                return {name: type.name, value: type.id}
            });
            facilityTypes = response.data.content;
        });
    }

    function onFacilityChange(value) {
        vm.facility.facilityType = facilityTypes.find(function (type) {
            return type.id === value;
        });
    }

    function initDialogData() {
        if (facility) {
            vm.facility = angular.copy(facility);
            vm.title = 'edit_facility';
        }
        else {
            vm.facility = angular.copy(initObject);
            isNewEntry = true;
        }
    }

    function confirm() {
        if (isNewEntry) {
            createFacility();
        }
        else updateFacility();
    }

    function createFacility() {
        var newFacility = getFacility();
        EntityService.addEntry('facility', newFacility).then(function () {
            AlertService.renderDialogMessage($translate.instant('facilityCreatedSuccessfully'));
            $rootScope.$broadcast('refreshTable', {entity: 'facility'});
            DialogService.hideDialog();
        }, function () {
            AlertService.renderDialogMessage($translate.instant('createFacilityFailed'));
        });
    }

    function updateFacility() {
        var editedFacility = getFacility();
        editedFacility.id = vm.facility.id;
        EntityService.updateEntry('facility', editedFacility).then(function (response) {
            AlertService.renderDialogMessage($translate.instant('facilityUpdateSuccessfully'));
            $rootScope.$broadcast('refreshTable', {entity: 'facility'});
            DialogService.hideDialog();
        }, function () {
            AlertService.renderDialogMessage($translate.instant('updateFacilityFailed'))
        });

    }

    function getFacility() {
        return {
            contact: vm.facility.contact,
            facilityDetail: vm.facility.facilityDetail,
            facilityInvoiceDetail: vm.facility.facilityInvoiceDetail,
            facilityType: vm.facility.facilityType,
            name: vm.facility.name,
            displayName: vm.facility.displayName
        };
    }
});