core_module.component('auditing', {
    controller: function ($scope, $mdDialog) {
        var vm = this;
        vm.entity = "auc.dev.models.auth.User";
        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: false,
            showDeleted: false,
            add: false,
            customActions: [{
                name: 'auditing.entity',
                icon: 'dns',
                clickFunction: manageChildren
            }]
        };

        vm.initObject =
            {
                id: '',
                timestamp: '',
                modifiedEntityNames: '',
                currentFacility: '',
                username: ''
            };

        vm.columnDefs = [
            {
                name: 'id',
                field: 'id',
                display: true,
                displayName:'logging.revision',
                edit: false,
                type: 'number',
                link: false
            },
            {
                name: 'timestamp',
                field: 'timestamp',
                display: true,
                displayName: 'timestamp',
                edit: false,
                type: 'datetime',
                link: false,
                filter: true
            },
            {
                name: 'modifiedEntityNames',
                field: 'modifiedEntityNames',
                display: true,
                displayName: 'logging.entity',
                edit: false,
                type: 'array',
                link: false, noSort: true
            },
            {
                name: 'currentFacility',
                field: 'currentFacility',
                display: false,
                displayName: 'logging.facility',
                edit: false,
                type: 'text',
                link: false, noSort: true
            },
            {
                name: 'facilityName',
                field: 'facilityName',
                display: true,
                displayName: 'logging.facility',
                edit: false,
                type: 'text',
                link: false, noSort: true
            },
            {
                name: 'username',
                field: 'username',
                display: true,
                displayName: 'logging.username',
                edit: false,
                type: 'text',
                link: false, noSort: true,
                filter: true
            }
        ];

        function manageChildren(rev) {
            var entity, data;
            if (rev.modifiedEntityNames != undefined && rev.modifiedEntityNames.length > 1) {
                // show dialog for selecting Entity Name
                $mdDialog.show({
                    templateUrl: 'shared/components/auditing/dialogs/select.className.html',
                    controller: 'SelectClassNameDialogController',
                    controllerAs: 'selectClassNameDialog',
                    fullscreen: true,
                    locals: {
                        modifiedEntityNames: rev.modifiedEntityNames
                    }
                }).then(function (entityName) {
                    entity = entityName.split('.').pop();
                    data = {
                        title: "" + entityName,
                        className: entityName,
                        childEntity: entity,
                        parentEntityId: rev.id,
                        parentEntity: rev
                    };
                    $scope.$emit('fromParentTableEvent', data);
                });
            } else {
                entity = rev.modifiedEntityNames[0].split('.').pop();
                data = {
                    title: "" + rev.modifiedEntityNames[0],
                    className: rev.modifiedEntityNames[0],
                    childEntity: entity,
                    parentEntityId: rev.id,
                    parentEntity: rev
                };
                $scope.$emit('fromParentTableEvent', data);
            }
        }
    },
    templateUrl: 'shared/components/auditing/auditing.html',
    controllerAs: 'auditing'
});


