/**
 * Created by Thorsten.Wirth on 19.09.2016.
 */
core_module.controller('SchemeSelectController', function (DialogService) {
    var vm = this;

    vm.putEntry = putEntry;
    vm.putSection = putSection;

    function putEntry() {
        DialogService.hideDialog('ENTRY');
    }

    function putSection() {
        DialogService.hideDialog('SECTION');
    }
});
