core_module.controller('NewPasswordController', function ($scope, $translate, HttpService, AuthService, $mdDialog, $location) {

    $scope.pwdObj = {
        newPassword:'',
        passwordConfirm:''
    }
    $scope.changePassword = function () {

        var confirm = $mdDialog.confirm()
            .title($translate.instant('dialog.warning'))
            .textContent($translate.instant('change.password.warning'))
            .ok($translate.instant('change.password.confirm'))
            .cancel($translate.instant('cancel'));

        $mdDialog.show(confirm).then(function () {
            HttpService.shortPut('/currentUser/password', $scope.pwdObj, function (response) {
                    AlertService.showToast($translate.instant('Password updated successfully'));
                    $mdDialog.cancel();
                }, function (response) {
                    AlertService.showToast($translate.instant('password update failed'));
                    $mdDialog.cancel();
                }
            );
            $mdDialog.cancel();
        });
    };

});