/**
 * Created by Thorsten.Wirth on 21.02.2017.
 */
core_module.controller('SchemeRuleController', function ($scope, $rootScope, EntityService, AlertService, DialogService, initObject, rule, doc) {
    var vm = this;
    var isNewEntry = false;

    vm.title = 'scheme.addentry';
    vm.doc = doc;
    vm.options = [{name: "WARN", value: "WARN"}, {name: "ERROR", value: "ERROR"}];

    vm.$onInit = init;
    vm.confirm = confirm;
    vm.appendToEditor = appendToEditor;

    function init() {
        if (rule) {
            vm.rule = angular.copy(rule);
            vm.title = 'scheme.editrule';
        }
        else {
            vm.rule = angular.copy(initObject);
            isNewEntry = true;
        }
    }

    function confirm() {
        if (isNewEntry) {
            createRule();
        }
        else updateRule();
    }

    function createRule() {
        EntityService.addEntry("rules", vm.rule).then(function () {
            $rootScope.$broadcast("refreshTable", {entity: 'rules'});
            AlertService.showToast('rule CreatedSuccessfully');
            DialogService.hideDialog();
        });
    }

    function updateRule() {
        EntityService.updateEntry("rules", vm.rule, vm.rule.id).then(function () {
            $rootScope.$broadcast("refreshTable", {entity: 'rules'});
            AlertService.showToast('rule UpdatedSuccessfully');
            DialogService.hideDialog();
        });
    }

    function appendToEditor(value) {
        console.log(value);
        vm.rule.ruleCondition = vm.rule.ruleCondition ? vm.rule.ruleCondition += 'model.' + value : 'model.' + value;
        $scope.ruleAdd.ruleCondition.code.$setTouched();
        $scope.ruleAdd.ruleCondition.code.$setValidity('required', true);
    }
});
