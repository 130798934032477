core_module.controller('CheckUserController', function ($scope, DialogService, HttpService, $mdDialog) {
    $scope.email = "";
    $scope.checkIfEmailExists = checkIfEmailExists;

    function checkIfEmailExists() {
        HttpService.shortGet("/users/registration/" + $scope.email, function (response) {
            var user = response.data;
            var userExists = !!user;
            $mdDialog.hide({userExists: userExists, user: user, email: $scope.email});
        }, function () {
            $mdDialog.cancel();
        })
    }
});
