core_module.factory('HttpService', function ($http, CONFIG, AlertService, Upload) {

    var defaultSuccessFunction = function (response) {
        // do nothing
    };

    var defaultErrorFunction = function (response) {
        // do nothing
    };

    return {
        get: function (url) {
            return $http.get(CONFIG.REST_BASE_URL + url);
        },
        head: function (url) {
            return $http.head(CONFIG.REST_BASE_URL + url)
        },
        post: function (url, data) {
            return $http.post(CONFIG.REST_BASE_URL + url, data);
        },
        put: function (url, data) {
            return $http.put(CONFIG.REST_BASE_URL + url, data);
        },
        delete: function (url, data) {
            if (typeof data === 'undefined') {
                return $http.delete(CONFIG.REST_BASE_URL + url);
            } else {
                return $http({
                    method: "DELETE",
                    headers: {
                        'Content-type': 'application/json;charset=utf-8'
                    },
                    url: CONFIG.REST_BASE_URL + url,
                    data: data
                });
            }
        },
        upload: function (url, data) {
            return Upload.upload({
                url: CONFIG.REST_BASE_URL + url,
                data: data
            });
        },

        shortGet: function (url, successFunction, errorFunction) {
            if (!errorFunction)
                errorFunction = defaultErrorFunction;
            if (!successFunction)
                successFunction = defaultSuccessFunction;
            return $http({method: 'GET', url: CONFIG.REST_BASE_URL + url}).then(successFunction, errorFunction);
        },
        directGet: function (url, errorFunction) { // errorFunction optional!
            if (!errorFunction)
                errorFunction = defaultErrorFunction;
            return $http({method: 'GET', url: CONFIG.REST_BASE_URL + url}).then(function (response) {
                return response
            }, errorFunction);
        },
        shortGetBlob: function (url, successFunction, errorFunction) {
            if (!errorFunction)
                errorFunction = defaultErrorFunction;
            if (!successFunction)
                successFunction = defaultSuccessFunction;
            return $http({
                method: 'GET',
                url: CONFIG.REST_BASE_URL + url,
                responseType: 'arraybuffer'
            }).then(successFunction, errorFunction);
        },
        downloadFile: function (url) {
            this.shortGetBlob(url,
                function (response) {
                    if (response.data.byteLength == 0) {
                        AlertService.renderErrorMessage('File is empty.');
                        return;
                    }
                    var headers = response.headers();
                    var linkElement = document.createElement('a');

                    try {
                        var contentType = headers['content-type'];
                        var contentFileName = headers['core-filename'];
                        var blob = new Blob([response.data], {type: contentType});
                        var blobUrl = window.URL.createObjectURL(blob);

                        linkElement.setAttribute('href', blobUrl);
                        linkElement.setAttribute("download", contentFileName);

                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        linkElement.dispatchEvent(clickEvent);
                    } catch (ex) {
                        console.log(ex);
                        AlertService.renderErrorMessage(ex);
                    }
                },
                function (response) {
                    console.log("Cannot File download!");
                    AlertService.renderErrorMessage("Cannot File download!");
                }
            );
        },
        shortPost: function (url, data, successFunction, errorFunction) {
            if (!errorFunction)
                errorFunction = defaultErrorFunction;
            if (!successFunction)
                successFunction = defaultSuccessFunction;
            return $http.post(CONFIG.REST_BASE_URL + url, data).then(successFunction, errorFunction);
        },
        shortPut: function (url, data, successFunction, errorFunction) {
            if (!errorFunction)
                errorFunction = defaultErrorFunction;
            if (!successFunction)
                successFunction = defaultSuccessFunction;
            return $http.put(CONFIG.REST_BASE_URL + url, data).then(successFunction, errorFunction);
        },
        shortDelete: function (url, successFunction, errorFunction) {
            if (!errorFunction)
                errorFunction = defaultErrorFunction;
            if (!successFunction)
                successFunction = defaultSuccessFunction;
            return $http.delete(CONFIG.REST_BASE_URL + url).then(successFunction, errorFunction);
        }
    };

});