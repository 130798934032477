core_module.factory('PatientSelectService', function () {

        var options = {
            genders: [
                {value: 'männlich', name: 'männlich'},
                {value: 'weiblich', name: 'weiblich'},
                {value: 'unbekannt', name: 'unbekannt'}
            ],

            handedness: [
                {value: 'Rechtshänder', name: 'Rechtshänder'},
                {value: 'Linkshänder', name: 'Linkshänder'},
                {value: 'Beidhänder', name: 'Beidhänder'},
                {value: 'unbekannt', name: 'unbekannt'}
            ],

            iside: [
                {value: 'rechts', name: 'rechts'},
                {value: 'links', name: 'links'},
                {value: 'beide', name: 'beide'}
            ],

            kindOfIssue: [
                {value: 'isolierte Verletzung der Hand', name: 'isolierte Verletzung der Hand'},
                {value: 'Mehrfachverletzung', name: 'Mehrfachverletzung'},
                {value: 'unbekannt', name: 'unbekannt'}
            ],

            accident: [
                {value: 'Anprall', name: 'Anprall'},
                {value: 'Avulsion', name: 'Avulsion'},
                {value: 'Biß', name: 'Biß'},
                {value: 'Distorsion', name: 'Distorsion'},
                {value: 'Elektrizität', name: 'Elektrizität'},
                {value: 'Explosion', name: 'Explosion'},
                {value: 'Fräse', name: 'Fräse'},
                {value: 'Hieb', name: 'Hieb'},
                {value: 'Guillotine', name: 'Guillotine'},
                {value: 'Kettensäge', name: 'Kettensäge'},
                {value: 'Kreissäge', name: 'Kreissäge'},
                {value: 'Quetschung', name: 'Quetschung'},
                {value: 'Rasenmäher', name: 'Rasenmäher'},
                {value: 'Schlag', name: 'Schlag'},
                {value: 'Schnitt', name: 'Schnitt'},
                {value: 'Schuß', name: 'Schuß'},
                {value: 'Stich', name: 'Stich'},
                {value: 'Sturz', name: 'Sturz'},
                {value: 'Verbrennung', name: 'Verbrennung'},
                {value: 'Verkehrsunfall', name: 'Verkehrsunfall'},
                {value: 'sonstige', name: 'sonstige'},
                {value: 'Hochdruckeinspritzverletzung', name: 'Hochdruckeinspritzverletzung'}
            ].sort(function(a, b) {
                    return (''+a.name).localeCompare(b.name);
                }),

            therapy: [
                {value: 'definitive Versorgung', name: 'definitive Versorgung'},
                {value: 'temporäre Versorgung', name: 'temporäre Versorgung'},
                {value: 'Replantation/Revaskularisation', name: 'Replantation/Revaskularisation'},
                {value: 'keine operative Versorgung', name: 'keine operative Versorgung'}
            ],

            insuarance: [
                {value: 'Gesetzliche Krankenkasse', name: 'Gesetzliche Krankenkasse'},
                {value: 'Private Kasse', name: 'Private Kasse'},
                {value: 'Berufsgenossenschaft', name: 'Berufsgenossenschaft'}
            ],

            anesthesia: [
                {value: 'Allgemeinanästhesie', name: 'Allgemeinanästhesie'},
                {value: 'Plexus', name: 'Plexus'},
                {value: 'Lokalanästhesie', name: 'Lokalanästhesie'},
                {value: 'unbekannt', name: 'unbekannt'}
            ],

            care: [
                {value: 'Primärversorgung', name: 'Primärversorgung'},
                {value: 'Sekundärversorgung', name: 'Sekundärversorgung'},
                {value: 'unbekannt', name: 'unbekannt'}
            ],

            transfer: [
                {value: 'ja', name: 'ja'},
                {value: 'nein', name: 'nein'}
            ],

            transferKind: [
                {value: 'Verband', name: 'Verband'},
                {value: 'Wundversorgung', name: 'Wundversorgung'},
                {value: 'Reposition', name: 'Reposition'},
                {value: 'Immobilisation', name: 'Immobilisation'},
                {value: 'Andere', name: 'Andere'},
                {value: 'Keine', name: 'Keine'},
            ],

            primaryCare: [
                {value: 'Verband', name: 'Verband'},
                {value: 'Wundversorgung', name: 'Wundversorgung'},
                {value: 'Reposition', name: 'Reposition'},
                {value: 'Immobilisation', name: 'Immobilisation'},
                {value: 'Andere', name: 'Andere'},
            ],

            surgeryKind: [
                {value: 'Ambulante Operation', name: 'Ambulante Operation'},
                {value: 'Stationäre Operation', name: 'Stationäre Operation'}
            ],
        };

        return {
            selectOptions: options
        };

    }
);