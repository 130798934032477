core_module.factory('DialogService', function ($rootScope, $translate, $q, $mdDialog) {
    return {
        showDialog: function (controller, templateUrl) {
            showDialog(controller, templateUrl);
        },

        cancelDialog: function (message) {
            message = (typeof message !== 'undefined') ? message : '';
            $mdDialog.cancel(message);
            $rootScope.dialogOpen = false;
            $rootScope.showAlerts = false;
        },

        closeDialog: function (message) {
            message = (typeof message !== 'undefined') ? message : '';
            $mdDialog.cancel(message);
            $rootScope.dialogOpen = false;
            $rootScope.showAlerts = false;
        },

        hideDialog: function (message) {
            message = (typeof message !== 'undefined') ? message : '';
            $mdDialog.hide(message);
            $rootScope.dialogOpen = false;
            $rootScope.showAlerts = false;
        },

        signIn: function () {
            showDialog('UserSignInDialogController', 'userDialog', 'shared/components/usersManagement/dialogs/user.sign.in.html');
        },

        passwordRecovery: function () {
            showDialog('PasswordRecoveryController', 'userDialog', 'shared/components/usersManagement/dialogs/password.recovery.html');
        },
        passwordChange: function(){
            showDialog('PasswordChangeController', 'userDialog', 'shared/components/passwordRecovery/password.change.html');
        },
        dialogNoBackdrop: dialogNoBackdrop,
        displayHelpDialog: displayHelpDialog,
        completionOfRegistration: function (email, userUuid) {
            return $mdDialog.show({
                templateUrl: 'shared/components/usersManagement/dialogs/user.completeRegistration.html',
                controller: 'UserCompleteRegistrationController',
                controllerAs: 'userDialog',
                fullscreen: true,
                locals: {
                    email: email,
                    userUuid: userUuid
                },
                clickOutsideToClose: true
            });
        }
    };

    function showDialog(controller, controllerAs, templateUrl) {

        $mdDialog.hide(); // be sure to close most recent dialog

        $rootScope.dialogOpen = true;

        $mdDialog.show({
            templateUrl: templateUrl,
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            controller: controller,
            controllerAs: controllerAs,
            onShowing: dialogIsShowing,
            onRemoving: dialogIsHiding,
            locals: {alerts: $rootScope.alerts},
            bindToController: true
        });
    }

    function dialogIsShowing() {
        $rootScope.dialogOpen = true;
    }

    function dialogIsHiding() {
        $rootScope.dialogOpen = false;
        $rootScope.showAlerts = false;
    }

    function dialogNoBackdrop(template, templateUrl) {
        var dialog = getNoBackdropConfig();
        if (template) {
            dialog.template = template
        } else if (templateUrl) {
            dialog.templateUrl = templateUrl;
        }
        $mdDialog.show(dialog);
    }

    function displayHelpDialog(imageUrl, textContent) {
        var dialog = getNoBackdropConfig();
        dialog.controller = 'HelpDialogController';
        dialog.controllerAs = 'helpDialog';
        dialog.templateUrl = 'shared/components/coreInput/dialogs/help.html';
        dialog.locals = {
            imageUrl: imageUrl,
            textContent: textContent
        };
        $mdDialog.show(dialog);
    }

    function getNoBackdropConfig() {
        var dialog = {
            fullscreen: true,
            hasBackdrop: false,
            clickOutsideToClose: false,
            disableParentScroll: false,
            parent: angular.element('#dialog-trap')
        };
        return dialog;
    }
})
;
