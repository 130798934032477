core_module.directive("scrollTopWhen", function ($timeout) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                scope.$on(attrs.scrollTopWhen, function () {
                    $timeout(function () {
                        angular.element(element)[0].scrollTop = 0;
                    });
                });
            }
        }
    }
);