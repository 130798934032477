core_module.component('medicalSpecialityManagement', {
    controller: function ($scope, $mdDialog) {
        var vm = this;

        vm.addEntry = addEntry;
        vm.editEntry = editEntry;

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: true,
            add: true
        };

        vm.initObject = {
            deleted: false,
            name: ''
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {name: 'deleted', field: 'deleted', display: true, edit: true, type: 'boolean', link: false},
            {
                name: 'name',
                field: 'name',
                displayName: 'medicalSpeciality.title',
                display: true,
                edit: true,
                type: 'text',
                link: false,
                filter: true,
                editrules: {required: true}
            }
        ];

        function addEntry() {
            $mdDialog.show({
                templateUrl: 'shared/components/medicalSpecialityManagement/dialogs/medicalSpecialityEntry.html',
                controller: 'MedicalSpecialityEntryController',
                controllerAs: 'specialityDialog',
                fullscreen: true,
                locals: {
                    medicalSpeciality: null,
                    initObject: vm.initObject
                }
            });
        }

        function editEntry(medicalSpeciality) {
            $mdDialog.show({
                templateUrl: 'shared/components/medicalSpecialityManagement/dialogs/medicalSpecialityEntry.html',
                controller: 'MedicalSpecialityEntryController',
                controllerAs: 'specialityDialog',
                fullscreen: true,
                locals: {
                    medicalSpeciality: medicalSpeciality,
                    initObject: vm.initObject
                }
            });
        }
    },
    templateUrl: 'shared/components/medicalSpecialityManagement/medicalSpecialityManagement.html',
    controllerAs: 'medicalSpeciality'
});