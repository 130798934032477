core_module.component('newsPreview', {
    bindings: {
        newsCount: '='
    },
    controller: function ($scope, NewsService, $translate) {
        var newsPreview = this;

        init();

        function init() {
            prepareValues();
            getData();
        }

        function prepareValues() {
            $scope.news = [];
            newsPreview.newsCount = 0;
        }

        function getData() {
            NewsService.getAllNews($translate.use()).then(function (response) {
                $scope.news = response.data.content;
                newsPreview.newsCount = response.data.content.length;
            });
        }

    },
    templateUrl: 'shared/components/newsPreview/newsPreview.html'
});