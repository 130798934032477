core_module.component('breadcrumbs', {
    controller: function ($location, BreadCrumbsService) {
        var vm = this;

        vm.breadCrumbs = BreadCrumbsService.getBreadCrumbs();
        vm.goTo = goTo;

        function goTo(location, index) {
            $location.path(location);
            vm.breadCrumbs = BreadCrumbsService.removeAbove(index);
        }
    },
    templateUrl: 'shared/components/breadcrumbs/breadcrumbs.html',
    controllerAs: 'breadcrumbs'
});
