core_module.component('scheme', {
    controller: function ($scope, $rootScope, $mdDialog, $q, uuid, HttpService, $translate, EntityService, AlertService, PreloaderService, $routeParams, $location) {
        var vm = this;

        var emptyEntry = {
            customId: "",
            ct: "STRING",
            cv: null,
            dv: {name: ''},
            deleted: false,
            d: "",
            m: true,
            ma: null,
            mi: null,
            ok: 0,
            p: null,
            ph: null,
            vm: ""
        };

        vm.forceCollapse = false;
        vm.documents = [];
        vm.currentSchemeId = null;

        vm.$onInit = init;

        vm.sortDescendants = sortDescendants;
        vm.toggleNode = toggleNode;
        vm.removeItem = removeItem;
        vm.editDocument = editDocument;
        vm.editExistingEntry = editExistingEntry;
        vm.editExistingSection = editExistingSection;
        vm.addChild = addChild;
        vm.addEntry = addEntry;
        vm.addRepeatEntry = addRepeatEntry;
        vm.addSectionOrEntry = addSectionOrEntry;
        vm.saveSchemeDialog = saveSchemeDialog;
        vm.openPreview = openPreview;
        vm.openValidation = openValidation;

        vm.treeOptions = {
            beforeDrop: beforeNodeDrop,
            dragStart: nodeDragStart,
            removed: nodeRemoved
        };


        function init() {
            if ($routeParams.templateId) {
                getDocumentTemplate($routeParams.templateId);
            }
        }

        function getDocumentTemplate(id) {
            PreloaderService.showPreloader('documentTemplate');
            HttpService.shortGet('/documentTemplate/' + id + '?fullTemplate=true', function (response) {
                var rawDataTree = response.data;
                vm.documents = [];
                vm.documents[0] = add_treeID(rawDataTree);
                vm.currentSchemeId = vm.documents[0].id;
                sortDescendants(vm.documents[0]);
                PreloaderService.hideParticularPreloader('documentTemplate');
            }, function () {
                PreloaderService.hideParticularPreloader('documentTemplate');
            });
        }

        function sortDescendants(node) {
            if (node.children && node.children.length) sortChildren(node.children);
            if (node.entries && node.entries.length) sortEntries(node.entries);
            if (node.repeatEntries && node.repeatEntries.length) sortEntries(node.repeatEntries);
        }

        function sortChildren(children) {
            children.sort(function (a, b) {
                return a.orderKey - b.orderKey
            });
            children.forEach(function (child) {
                sortDescendants(child);
            })
        }

        function sortEntries(entries) {
            entries.sort(function (a, b) {
                return a.ok - b.ok
            });
            entries.forEach(function (child) {
                sortDescendants(child);
            })
        }

        function toggleNode(scope) {
            scope.toggle();
        }

        function removeItem(scope) {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('scheme.delete_entry'))
                .ok($translate.instant('delete'))
                .cancel($translate.instant('cancel'));
            $mdDialog.show(confirm).then(function () {
                scope.remove();
            });
        }

        function editExistingEntry(scope) {
            editEntry(scope.$modelValue)
        }

        function editEntry(node) {
            return $mdDialog.show({
                templateUrl: 'shared/components/scheme/dialogs/schemeEntry.html',
                fullscreen: true,
                controller: 'SchemeEntryController',
                controllerAs: 'schemeEntryDialog',
                locals: {
                    node: node,
                    doc: vm.documents[0]
                }
            });
        }

        function editExistingSection(scope) {
            editSection(scope.$modelValue)
        }

        function editSection(node) {
            return $mdDialog.show({
                templateUrl: 'shared/components/scheme/dialogs/schemeSection.html',
                controller: 'SchemeSectionController',
                controllerAs: 'schemeSectionDialog',
                locals: {
                    node: node,
                    doc: vm.documents[0]
                }
            })
        }

        function addChild(scope) {
            var nodeData = scope.$modelValue;
            var node = {
                customId: "",
                orderKey: 0,
                children: [],
                entries: []
            };
            editSection(node).then(function () {
                nodeData.children.push(node);
            });
        }

        function addEntry(scope) {
            var nodeData = scope.$modelValue;
            var node = angular.copy(emptyEntry);
            editEntry(node).then(function () {
                nodeData.entries.push(node);
            });
        }

        function addRepeatEntry(scope) {
            var nodeData = scope.$modelValue;
            if (!nodeData.hasOwnProperty('repeatEntries')) nodeData.repeatEntries = [];
            var node = angular.copy(emptyEntry);
            editEntry(node).then(function () {
                nodeData.repeatEntries.push(node);
            });
        }

        function addSectionOrEntry(scope) {
            $mdDialog.show({
                templateUrl: 'shared/components/scheme/dialogs/schemeSelect.html',
                controller: 'SchemeSelectController',
                controllerAs: 'schemeSelectDialog'
            }).then(function (response) {
                if (response === 'SECTION') addChild(scope);
                else if (response === 'ENTRY') addEntry(scope);
            });
        }

        function beforeNodeDrop(e) {
            var destType = e.dest.nodesScope.$element[0].dataset.type;
            var sourceType = e.source.nodeScope.$element[0].dataset.type;

            if (typeof destType === 'undefined') return false;
            if (destType === 'entries-container' && sourceType !== 'entry') return false;
            if ((destType === 'section-container' || destType === 'root') && sourceType !== 'section') return false;
        }

        function nodeDragStart(e) {
            var type = e.source.nodeScope.$element[0].dataset.type;
            vm.container = type;
        }

        function nodeRemoved(node) {
            node.$parentNodeScope.$modelValue.modified = true;
            node.$modelValue.modified = true;
        }

        function add_treeID(treeData) {
            if (Array.isArray(treeData)) {
                angular.forEach(treeData, function (value, key) {

                    if (treeData[key] && treeData[key].hasOwnProperty("version")) {
                        treeData[key].version['treeid'] = uuid.v4();
                        add_treeID(treeData[key]);
                    }

                });
            } else {
                if (treeData && treeData.hasOwnProperty("version")) {
                    treeData.version['treeid'] = uuid.v4();
                }
                angular.forEach(treeData, function (v, k) {
                    if (k === 'entries' || k === 'children') {
                        add_treeID(treeData[k]);
                    }
                })
            }
            return treeData;
        }

        function editDocument(scope) {
            var document = scope.$modelValue;
            $mdDialog.show({
                templateUrl: 'shared/components/scheme/dialogs/schemeDocument.html',
                controller: 'SchemeDocumentController',
                controllerAs: 'schemeDocumentDialog',
                locals: {
                    document: document
                }
            }).then(function (document) {
                vm.documents[0] = document;
            });
        }

        function saveSchemeDialog() {
            $mdDialog.show({
                templateUrl: 'shared/components/scheme/dialogs/schemeSave.html',
                controller: 'SchemeSaveController',
                controllerAs: 'saveDialog',
                locals: {
                    data: vm.documents[0]
                }
            }).then(function (scheme) {
                vm.currentSchemeId = scheme.id;
                vm.documents[0] = scheme;
                sortDescendants(vm.documents[0]);
            });
        }

        function openPreview() {
            if (vm.documents && vm.documents[0]) {
                $mdDialog.show({
                    templateUrl: 'shared/components/scheme/dialogs/schemePreview.html',
                    controller: 'SchemePreviewController',
                    controllerAs: 'schemePreviewDialog',
                    locals: {
                        doc: vm.documents[0]
                    }
                });
            } else {
                var alert = $mdDialog.alert()
                    .title()
                    .content($translate.instant('scheme.noTemplate'))
                    .ok('ok');

                $mdDialog
                    .show(alert)
                    .finally(function () {
                        alert = undefined;
                    });
            }
        }

        function openValidation() {
            $mdDialog.show({
                templateUrl: 'shared/components/scheme/dialogs/validator/schemeOpenValidator.html',
                controller: 'OpenValidatorController',
                controllerAs: 'validationDialog',
                multiple: true,
                locals: {
                    doc: vm.documents[0]
                }
            });
        }
    },
    templateUrl: 'shared/components/scheme/scheme.html',
    controllerAs: 'scheme'
});
