/**
 * Created by Thorsten.Wirth on 03.04.2017.
 */
core_module.directive('showPassword',function ($compile) {
        return {
            restrict: 'A',
            link: function ($scope, element, attrs) {

                $scope.iconName = "visibility";
                $scope.isVisibility = false;
                $scope.action = function () {
                    $scope.isVisibility = !$scope.isVisibility;
                    if ($scope.isVisibility) {
                        $scope.iconName = "visibility_off";
                        attrs.$set('type','text');
                    } else {
                        $scope.iconName = "visibility";
                        attrs.$set('type','password');
                    }
                };

                var content = angular.element(
                    '<md-button class="md-icon-button"' +
                    'ng-click="action()"' +
                    'style="position: absolute; top: 0px; right: -6px; margin: 0px 0px;" tabindex="-1">' +
                    '<md-icon> {{iconName}}  </md-icon>' +
                    '</md-button>');
                content.insertAfter(element);

                $compile(content)($scope);
            }


        }


    }
);

