core_module.component('parameter', {
    controller: function ($mdDialog) {
        var vm = this;

        vm.addNewParameter = addNewParameter;

        vm.query = {
            order: 'name',
            limit: 10,
            page: 1
        };

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            showDeleted: false,
            add: true,
            customActions: [{
                name: 'manage_units',
                icon: 'straighten',
                clickFunction: manageUnits
            }]
        };

        vm.initObject = {
            id: '',
            name: '',
            siUnit: {},
            unit: []
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {name: 'deleted', field: 'deleted', display: true, edit: false, type: 'text', link: false},
            {
                name: 'name',
                field: 'name',
                display: true,
                displayName: 'Parameter',
                edit: true,
                type: 'text',
                link: false,
                filter: true
            },
            {
                name: 'name',
                field: 'siUnit',
                display: true,
                displayName: 'SI-Unit',
                edit: false,
                type: 'text',
                link: false
            }
        ];

        function manageUnits(entry) {
            $mdDialog.show({
                templateUrl: 'shared/components/parameter/dialogs/manage.units.html',
                controller: 'ManageUnitsDialogController',
                controllerAs: 'unitsDialog',
                fullscreen: true,
                locals: {
                    parameterId: entry.id,
                    parameterName: entry.name
                }
            });
        }

        function addNewParameter() {
            $mdDialog.show({
                templateUrl: 'shared/components/parameter/dialogs/add.parameter.html',
                controller: 'AddParameterController',
                controllerAs: 'parameterDialog',
                fullscreen: true,
                locals: {
                    initObject: vm.initObject
                }
            });
        }
    },
    templateUrl: 'shared/components/parameter/parameter.html',
    controllerAs: 'parameter'
});

