/**
 * Created by Thorsten.Wirth on 16.11.2016.
 */
core_module.controller('SchemePreviewController', function ($timeout, doc, PreloaderService) {
    var vm = this;

    vm.doc = doc;
    vm.model = [];

    vm.$onInit = init;

    function init() {
        $timeout(function () {
            PreloaderService.particularWaitForDOM('previewDialog');
        }, 200);
    }
});