/**
 * Created by Thorsten.Wirth on 19.09.2016.
 */
core_module.controller('SchemeEntryController', function ($scope, $mdDialog, $translate, EntityService, DialogService, PreloaderService, node, doc) {
    var vm = this;

    vm.$onInit = init;
    vm.contentTypeChange = contentTypeChange;
    vm.typeProperForContentValues = typeProperForContentValues;
    vm.typeProperForMinMax = typeProperForMinMax;
    vm.typeProperForPattern = typeProperForPattern;
    vm.addNewContent = addNewContent;
    vm.deleteEntry = deleteEntry;
    vm.save = save;

    vm.contentTypes = [{name: 'STRING', value: 'STRING'},
        {name: 'BOOLEAN', value: 'BOOLEAN'},
        {name: 'REPEAT', value: 'REPEAT'},
        {name: 'NUMBER', value: 'NUMBER'},
        {name: 'SLIDER', value: 'SLIDER'},
        {name: 'DATE', value: 'DATE'},
        {name: 'TIME', value: 'TIME'},
        {name: 'ENUMERATION', value: 'ENUMERATION'},
        {name: 'LIST', value: 'LIST'},
        {name: 'ARRAY', value: 'ARRAY'},
        {name: 'PARAMETER', value: 'PARAMETER'},
        {name: 'FIELDSET', value: 'FIELDSET'}];

    vm.doc = doc;

    function init() {
        vm.node = angular.copy(node);
        getParameters();
        PreloaderService.particularWaitForDOM('coreDialog');
    }

    function getParameters() {
        EntityService.getEntries('parameters').then(function (response) {
            vm.parameters = response.data.content.map(function (el) {
                return {name: el.name, value: el.id}
            });
        });
    }

    function contentTypeChange(value) {
        if (!['ENUMERATION', 'ARRAY', 'LIST', 'BOOLEAN'].includes(value)) {
            vm.node.cv = [];
        }
    }

    function typeProperForContentValues() {
        return (['ENUMERATION', 'ARRAY', 'LIST', 'BOOLEAN'].includes(vm.node.ct));
    }

    function typeProperForPattern() {
        return (['STRING', 'NUMBER', 'PARAMETER', 'INTEGER'].includes(vm.node.ct));
    }

    function typeProperForMinMax() {
        return (['SLIDER', 'NUMBER', 'PARAMETER', 'INTEGER', 'DATE'].includes(vm.node.ct));
    }

    function showIncorrectTypeAlert() {
        var alert = $mdDialog.alert()
            .title()
            .content($translate.instant('scheme.noContent'))
            .ok('ok');

        $mdDialog
            .show(alert)
            .finally(function () {
                alert = undefined;
            });
    }

    function addNewContent() {
        if (typeProperForContentValues()) {
            $scope.entryForm.$dirty = true;
            vm.node.cv.push({
                deleted: false,
                name: ''
            });
        } else {
            showIncorrectTypeAlert();
        }
    }

    function deleteEntry(index) {
        $scope.entryForm.$dirty = true;
        vm.node.cv.splice(index, 1);
    }

    function save() {
        if (customIdChanged() && checkIfExists(vm.doc.children)) {
            showAlertDialog();
        }
        else {
            if ($scope.entryForm.$dirty) vm.node.modified = true;
            angular.copy(vm.node, node);
            DialogService.hideDialog();
        }
    }

    function customIdChanged() {
        return node.customId !== vm.node.customId
    }

    function checkIfExists(children) {
        var duplicatedCustomId = false;
        children.forEach(function (child) {
            if (child.entries && child.entries.length > 0) {
                child.entries.forEach(function (child) {
                    if (child.customId === vm.node.customId) duplicatedCustomId = true;
                    else if (child.entries && child.entries.length) {
                        return checkIfExists(child.entries);
                    }
                    else if (child.repeatEntries && child.repeatEntries.length) {
                        return checkIfExists(child.repeatEntries);
                    }
                });
            }
        });
        return duplicatedCustomId;
    }

    function showAlertDialog() {
        var alert = $mdDialog.alert()
            .title()
            .content($translate.instant('scheme.customIdExists'))
            .ok('ok');

        $mdDialog
            .show(alert)
            .finally(function () {
                alert = undefined;
            });
    }
});
