/**
 * Created by Thorsten.Wirth on 20.02.2017.
 */
core_module.controller('OpenValidatorController', function ($scope, doc, $mdDialog, EntityService) {
    var vm = this;

    vm.newRule = newRule;
    vm.editRule = editRule;
    vm.$onInit = init;

    vm.rulesUrl = "rules/?documentTemplateId=" + doc.id;

    vm.options = {
        rowSelection: false,
        multiSelect: true,
        autoSelect: true,
        decapitate: false,
        largeEditDialog: false,
        boundaryLinks: false,
        limitSelect: true,
        pageSelect: true,
        editable: true,
        deletable: true,
        add: true
    };

    vm.columnDefs = [
        {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
        {
            name: 'ruleCondition',
            field: 'ruleCondition',
            type: 'text',
            link: false,
            display: true
        },
        {
            name: 'ruleType',
            field: 'ruleType',
            type: 'text',
            link: false,
            display: true
        },
        {
            name: 'message',
            field: 'message',
            type: 'text',
            link: false,
            display: true
        }

    ];

    vm.initObject = {
            documentTemplate_Id: doc.id,
            ruleCondition: null
    };

    function init() {
        EntityService.getEntries("rules/?documentTemplateId=" + doc.id, { limit: -1}).then(function (response) {
            $scope.rules = response.data.content;
        });
    }

    function newRule() {
        $mdDialog.show({
            templateUrl: 'shared/components/scheme/dialogs/validator/schemeRule.html',
            controller: 'SchemeRuleController',
            controllerAs: 'ruleDialog',
            multiple: true,
            locals: {
                doc: doc,
                initObject: vm.initObject,
                rule: null
            }
        });
    }

    function editRule(rule) {
        $mdDialog.show({
            templateUrl: 'shared/components/scheme/dialogs/validator/schemeRule.html',
            multiple:true,
            controller: 'SchemeRuleController',
            controllerAs: 'ruleDialog',
            locals: {
                doc: doc,
                initObject: vm.initObject,
                rule: rule
            }
        });
    }
});