core_module.component('toggleCard', {
    transclude: true,
    bindings: {
        headerText: '@',
        subheader: '@',
        maximized: '<'
    },
    controller: function () {
        var vm = this;

        vm.$onInit = init;
        vm.toggleState = toggleState;

        function init() {
            if (typeof vm.maximized === 'undefined') vm.maximized = true;
        }

        function toggleState() {
            vm.maximized = !vm.maximized;
        }
    },
    templateUrl: 'shared/components/toggleCard/toggleCard.html',
    controllerAs: 'toggleCard'
});