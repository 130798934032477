core_module.component('coreTableFilterToolbar', {
    bindings: {
        columnDefs: '=?',
        entity: '@',
        performSearch: '&',
        clearFilters: '&',
        filters: '=',
    },
    controller: function ($scope, $mdDialog, $q, $translate, $timeout) {
        var vm = this;

        vm.fields = [];
        vm.update = update;
        vm.resetFilters = resetFilters;

        function update() {
            var key = 'coreTable_' + vm.entity + '_filters';
            var toSave = {};
            vm.filters.forEach(function (filter) {
                toSave[filter.field] = {
                    operator: filter.operator,
                    value: filter.value
                }
            });
            if (vm.filters) localStorage.setItem(key, JSON.stringify(toSave));
            else localStorage.removeItem(key);
            vm.performSearch();
        }

        function resetFilters() {
            vm.clearFilters();
            var key = 'coreTable_' + vm.entity + '_filters';
            localStorage.removeItem(key);

            //wait for digest cycle and update bindings
            $timeout(function () {
                vm.performSearch();
            });
        }
    },
    templateUrl: 'shared/components/coreTable/coreTable.filterToolbar.html',
    controllerAs:
        'filterToolbar'
});
