core_module.component('auditDocument', {
    bindings: {
        className:'<?',
        parentId: '<?',
        entity: '<?'
    },
    controller: function ($mdDialog) {
        var vm = this;
        vm.getDetailedLogsUrl = getDetailedLogsUrl;
        vm.query = {
            order: 'rev',
            limit: 10,
            page: 1
        };
        vm.items = [];
        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: false,
            add: false
        };

        vm.initObject = {
            deleted: false,
            name: ''
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, noSort: true},
            {name: 'deleted', field: 'deleted', display: true, edit: false, type: 'boolean', link: false, noSort: true},
            {
                name: 'logging.revision',
                field: 'revision',
                display: true,
                edit: false,
                type: 'number',
                link: false,
                noSort: true
            },
            {
                name: 'logging.revisionType',
                field: 'revisionType',
                display: true,
                edit: false,
                type: 'text',
                link: false,
                noSort: true
            },
            {name: 'data', field: 'data', display: true, edit: false, type: 'text', link: false},
            {name: 'dataType', field: 'dataType', display: true, edit: false, type: 'text', link: false},
            {name: 'modifiedDate', field: 'modifiedDate', display: true, edit: false, type: 'datetime', link: false}
        ];

        function getDetailedLogsUrl() {
            return '/auditing/' + vm.className + "/" + vm.parentId;
        }

        vm.getDetailedLogsUrl = getDetailedLogsUrl;
    },
    templateUrl: 'shared/components/auditDocument/auditDocument.html',
    controllerAs: 'auditDocument'
});