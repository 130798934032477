core_module.controller('SelectLanguageDialogController', function ($translate, HttpService, DialogService) {
    var vm = this;

    vm.locales = [];

    vm.$onInit = init;
    vm.changeLanguage = changeLanguage;

    function init() {
        getLocales();
    }

    function getLocales() {
        HttpService.shortGet('/localizations/locallanguages', function (response) {
            vm.locales = response.data;
        });
    }

    function changeLanguage(lang) {
        $translate.use(lang);
        moment.locale(lang);
        DialogService.hideDialog();
    }
});
