core_module.component('userDependManagement', {
    controller: function ($scope) {
        var vm = this;

        vm.isRoleManagement = false;

        $scope.$on('fromParentTableEvent', function (event, data) {
            if (data.childEntity === 'userRole') {
                vm.isRoleManagement = true;
                vm.parentId = data.parentEntityId;
            }
        });
    },
    templateUrl: 'shared/components/userDependManagement/userDependManagement.html',
    controllerAs: 'userDepend'
});
