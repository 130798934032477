core_module.controller('WarningDialogController', function ($scope, DialogService) {

    $scope.close = function () {
        DialogService.closeDialog();
    };

    $scope.ok = function() {
        //$mdDialog.close('ok');
        DialogService.closeDialog();
    };

    $scope.cancel = function() {
        DialogService.closeDialog();
    };
});
