core_module.factory('LocalizationService', function (HttpService,$q) {

    function addEntry(entry, language) {
        return HttpService.post('/localizations', {
            prop: entry.prop,
            language: language,
            langValue: entry[language]
        });
    }

    function checkIfEntryExists(entry, language) {
        return HttpService.get('/localizations/exists?prop=' + entry.prop + '&language=' + language)
    }




    function updateEntry(entry, language) {
        return HttpService.put('/localizations/edit?prop=' + entry.prop + '&language=' + language, {
            prop: entry.prop,
            language: language,
            langValue: entry[language]
        })
    }

    function getEntries() {
        return HttpService.get('/localizations/');
    }

    function deleteEntry(entry) {
        return HttpService.delete('/localizations/delprop?prop=' + entry.prop);
    }

    function getLanguages() {
        return HttpService.get('/localizations/locallanguages');
    }

    function checkIfPropertyExists(prop) {
        return HttpService.get('/localizations/exists/p?prop=' + prop)
    }

    function updateProperty(oldProp, newProp) {
        return HttpService.put('/localizations/edit/p?oldProp=' + oldProp + '&newProp=' + newProp);
    }

    function restructureEntries(entries) {
        var entriesMapped = [];
        var entriesArray = [];

        for (var i = 0; i < entries.length; i++) {
            var item = entries[i];

            if (!entriesMapped[item.prop]) {
                entriesMapped[item.prop] = {};
            }
            entriesMapped[item.prop]['prop'] = item.prop;
            entriesMapped[item.prop][item.language] = item.langValue;
            entriesMapped[item.prop]['disableEdit'] = false;
            entriesMapped[item.prop]['isSelected'] = false;
        }

        var result = [];

        for (var x in entriesMapped) {
            if (Object.prototype.hasOwnProperty.call(entriesMapped, x)) {
                var obj = {};
                obj[x] = entriesMapped[x];
                result.push(obj);
            }
        }

        for (var item in entriesMapped) {
            entriesArray.push(entriesMapped[item]);
        }
        return entriesArray;
    }

    return {
        checkIfEntryExists: checkIfEntryExists,
        addEntry: addEntry,
        updateEntry: updateEntry,
        getEntries: getEntries,
        deleteEntry: deleteEntry,
        getLanguages: getLanguages,
        checkIfPropertyExists: checkIfPropertyExists,
        updateProperty: updateProperty,
        restructureEntries: restructureEntries

    }
});