core_module.controller('OccupationSelectController', function (DialogService, data) {
    var vm = this;

    vm.nodeClick = nodeClick;
    vm.toggleNode = toggleNode;
    vm.changeShowDefinition = changeShowDefinition;
    vm.treeOptions = {};
    vm.data = data;

    function toggleNode(scope) {
        scope.toggle();
    }

    function nodeClick(node) {
        DialogService.hideDialog(node);
    }

    function changeShowDefinition(node) {
        const code = node.isco08code;
        findNode(vm.data, code);
    }

    function findNode(data, code){
        data.forEach(d =>{
            if(d.children && d.children.length > 0)
                findNode(d.children, code);
            if(d.isco08code === code){
                d.showDefinition = !d.showDefinition;
            }

        })
    }
});