core_module.component('corePreloader', {
    bindings: {
        name: "@",
        hideOnStart: "<?"
    },
    controller: function ($scope) {
        var vm = this;

        vm.$onInit = init;

        function init() {
            if (vm.hideOnStart === true || vm.hideOnStart === 'true')
                vm.hide = true;
            else vm.hide = false;
        }

        $scope.$on("showPreloader", function (event, argument) {
            if (argument === vm.name) {
                vm.hide = false;
            }
        });

        $scope.$on("hidePreloader", function (event, toHide) {
            if (toHide) {
                vm.hide = true
            }
        });

        $scope.$on("hideParticularPreloader", function (event, argument) {
            if (argument === vm.name) {
                vm.hide = true;
            }
        });
    },
    templateUrl: 'shared/components/corePreloader/corePreloader.html',
    controllerAs: 'preloader'
});
