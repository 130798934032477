core_module.component('auditDependManagement', {
    controller: function ($rootScope, $scope, $location, AuthService) {
        var vm = this;

        function resetChildDisplay() {
            vm.Document = false;
            vm.CaseFile = false;
            vm.MCase = false;
            vm.Sheet = false;
            vm.User = false;
            vm.Facility = false;
            vm.DocumentTemplateType = false;
        }

        function resetSecondChildDisplay() {
            vm.Document2 = false;
            vm.CaseFile2 = false;
            vm.MCase2 = false;
            vm.Sheet2= false;
            vm.User2 = false;
            vm.Facility2 = false;
            vm.DocumentTemplateType2 = false;
        }

        vm.$onInit = function (){
            resetChildDisplay();
            resetSecondChildDisplay();
        }

        function displayChild(child) {
            resetChildDisplay();
            vm[child] = true;
        }

        function setChildData(data) {
            vm.parentId = data.parentEntity.id;
            vm.entity = data.childEntity;
            vm.className = data.className;
        }

        $scope.$on('fromParentTableEvent', function (event, data) {
            setChildData(data);
            displayChild(data.childEntity);
        });
    },
    templateUrl: 'shared/components/auditDependManagement/auditDependManagement.html',
    controllerAs: 'auditDepender'
});