/**
 * Created by Thorsten.Wirth on 16.09.2016.
 */
core_module.controller('LocalizationEntryController', function ($rootScope, $translate, $q, DialogService, LocalizationService, AlertService, entry, initObject, languages) {
    var vm = this;
    var entity = 'localizations';
    var isNewEntry = false;

    vm.title = 'add_entry';
    vm.languages = languages;

    vm.$onInit = init;
    vm.confirm = confirm;

    function init() {
        if (entry) {
            vm.entry = angular.copy(entry);
            vm.title = 'edit_entry';
        }
        else {
            vm.entry = angular.copy(initObject);
            isNewEntry = true;
        }
    }

    function confirm() {
        if (isNewEntry) {
            createEntry();
        }
        else editEntry();
    }

    function createEntry() {
        LocalizationService.checkIfPropertyExists(vm.entry.prop).then(function (response) {
            if (response.data > 0) {
                AlertService.renderErrorMessage('Property exists');
            } else {
                var promises = [];

                angular.forEach(languages, function (language) {
                    var promise = LocalizationService.addEntry(vm.entry, language);
                    promises.push(promise);
                });
                $q.all(promises).then(function () {
                    AlertService.showToast($translate.instant(entity + ' create entry successfully'));
                    $rootScope.$broadcast("refreshTable", {entity: entity});
                    DialogService.hideDialog();
                });
            }
        });
    }

    function editEntry() {
        LocalizationService.updateProperty(entry.prop, vm.entry.prop).then(function () {
            angular.forEach(languages, function (languages) {
                LocalizationService.updateEntry(vm.entry, languages).then(function () {
                    $rootScope.$broadcast("refreshTable", {entity: entity});
                    DialogService.hideDialog();
                });
            });
        });
    }
});