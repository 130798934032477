core_module.component('sectionTemplateTable', {
    bindings: {
        parentId: '<?'
    },
    templateUrl: 'shared/components/registryManagement/sectionTemplateTable.html',
    controller: function ($scope, $mdDialog, $rootScope, $q, HttpService, $translate, EntityService, AlertService) {
        var self = this;

        processBindings();

        this.$onChanges = function (changes) {
            if (changes.hasOwnProperty('parentId')) {
                processBindings();
            }
        };

        function processBindings() {
            $scope.exportCsvFieldsGroupId = (self.parentId) ? self.parentId : null;
            if ($scope.exportCsvFieldsGroupId) {
                $scope.addFunction = function () {
                    $mdDialog.show({
                        templateUrl: 'shared/components/registryManagement/dialogs/add.sectionTemplateToExportCsvFieldsGroup.html',
                        controller: 'SectionTemplateExportCsvFieldsGroupController',
                        fullscreen: false,
                        locals: {
                            parentId: $scope.exportCsvFieldsGroupId
                        }
                    });
                };

                $scope.removeSectionTemplateFromExportCsvFieldsGroup = function (sectiontemplates) {
                    var confirm = $mdDialog.confirm()
                        .title($translate.instant('confirm_changes'))
                        .textContent($translate.instant('confirm_changes'))
                        .ok($translate.instant('delete'))
                        .cancel($translate.instant('cancel'));
                    $mdDialog.show(confirm).then(function () {
                            var showDeleteSuccess = function () {
                                AlertService.showToast($translate.instant('sectiontemplates' + ' deleted successfully'));
                            };
                            var url = "/sectiontemplates/delSectionTemplate?exportCsvFieldsGroupId=" + $scope.exportCsvFieldsGroupId + "&sectionTemplateId="
                                + sectiontemplates.id;

                            return HttpService.delete(url).then(function () {
                                $rootScope.$broadcast('refreshTable', {entity: 'sectiontemplates'});
                                showDeleteSuccess();
                            });
                        }
                    );
                };

                $scope.editFunction = function () {
                    AlertService.showToast('Editierung von SectionTemplate ist in Schemaverwaltung.');
                }
            } else {
                $scope.addFunction = undefined;
                $scope.removeSectionTemplateFromExportCsvFieldsGroup = undefined;
                $scope.editFunction = undefined;
            }
        }

        $scope.getSectionTemplatesUrl = function () {
            if ($scope.exportCsvFieldsGroupId) {
                return 'sectiontemplates/exportCsvFieldsGroup/' + $scope.exportCsvFieldsGroupId;
            }
        };

        $scope.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            showDeleted: true,
            add: true
        };

        $scope.query = {
            order: 'id',
            limit: 10,
            page: 1
        };

        $scope.initObject = {
            id: null
        };

        $scope.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {name: 'identifier', field: 'identifier', display: true, edit: false, type: 'text', link: false, filter: true},
            {name: 'customId', field: 'customId', display: true, edit: true, type: 'text', link: false, filter: true},
            {name: 'name', field: 'name', display: true, edit: true, type: 'text', link: false, filter: true},
            {name: 'orderKey', field: 'orderKey', display: true, edit: true, type: 'number', link: false, filter: true}
        ];

    }
});
