core_module.directive('htrAddress', ['CONFIG', function (CONFIG) {
        return {
            restrict: 'E',
            scope: {
                address: '='
            },
            templateUrl: 'shared/directives/htr-address.html',
            link: function (scope, element, attrs, ctrl) {
                scope.address = scope.address || {};
                scope.countries = CONFIG.COUNTRIES;


                angular.forEach(scope.countries, function (key, value) {

                    if (scope.countries[value].name == scope.address.country) {
                        scope.address.country = scope.countries[value];
                    }
                });

            }
        }
    }]
);
