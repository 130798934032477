core_module.component('clientlog', {

    controller: function ($scope, $mdDialog) {
        var vm = this;

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: false,
            showDeleted: false,
            add: false,
            customActions: [{
                name: 'logging.showFullInfo',
                icon: 'info',
                clickFunction: showFullInfo
            }]
        };

        vm.initObject = {
            id: '',
            eventDate: '',
            logger: '',
            message: '',
            exception: '',
            marker: '',
            level: ''
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {
                name: 'eventDate',
                field: 'eventDate',
                display: true,
                edit: false,
                type: 'date',
                link: false,
                filter: true
            },
            {name: 'logger', field: 'logger', display: true, edit: false, type: 'text', link: false, filter: true},
            {name: 'message', field: 'message', display: true, edit: false, type: 'text', link: false, filter: true},
            {name: 'exception', field: 'exception', display: false, edit: false, type: 'text', link: false},
            {name: 'marker', field: 'marker', display: true, edit: false, type: 'text', link: false, filter: true},
            {name: 'level', field: 'level', display: true, edit: false, type: 'text', link: false, filter: true}
        ];

        function showFullInfo(log) {
            $mdDialog.show({
                templateUrl: 'shared/components/clientLog/dialogs/logDetails.html',
                controller: 'LogDetailsController',
                controllerAs: 'logDetailsDialog',
                locals: {log: log}
            });
        }
    },
    templateUrl: 'shared/components/clientLog/clientLog.html',
    controllerAs: 'clientLog'
});


