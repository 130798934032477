core_module.component('usersManagement', {
    controller: function ($scope, $mdDialog, $translate, AuthService, DialogService, HttpService, PreloaderService) {

        this.$onInit = function () {
            PreloaderService.particularWaitForDOM('usersPreloader');
        };

        this.addNewUser = addNewUser;
        this.showEditUserDialog = showEditUserDialog;

        this.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: true,
            limitSelect: true,
            pageSelect: true,
            showDeleted: false,
            editable: AuthService.hasPermissionOrIsAdmin('ROLE_P_USER_UPDATE'),
            deletable: AuthService.hasPermissionOrIsAdmin('ROLE_P_USER_DELETE'),
            add: AuthService.hasPermissionOrIsAdmin('ROLE_P_USER_CREATE'),
            customActions: [{
                isActiveCondition: isUserLocked,
                name: 'user.unlock',
                icon: 'lock_open',
                clickFunction: unlockUser
            }, {
                isActiveCondition: isUserUnlocked,
                name: 'user.lock',
                icon: 'lock',
                clickFunction: lockUser
            },{
                name: 'manage_roles',
                icon: 'group',
                clickFunction: manageRoles
            }, {
                name: 'reset_password',
                icon: 'backspace',
                clickFunction: resetPassword
            }]
        };

        this.query = {
            order: 'id',
            limit: 10,
            page: 1
        };

        this.initObject = {};

        this.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {name: 'email', field: 'email', display: true, edit: true, type: 'email', link: false, filter: true},
            {name: 'title', field: 'title', display: false, edit: true, type: 'text', link: false, filter: false},
            {name: 'gender', field: 'gender', display: false, edit: true, type: 'text', link: false, filter: false},
            {name: 'firstName', field: 'firstName', display: false, edit: true, type: 'text', link: false, filter: true},
            {name: 'lastName', field: 'lastName', display: false, edit: true, type: 'text', link: false, filter: true},
            {name: 'role', field: 'role', filterField: 'userRoles->role.name', display: false, edit: true, type: 'text',
                link: false, filter: true},
            {name: 'facility.title', field: 'facility', filterField: 'userRoles->facility.displayName', display: false,
                edit: true, type: 'text', link: false, filter: true},
            {name: 'fullName', field: 'fullName', display: true, edit: false, type: 'text', link: false, filter: false},
            {name: 'roleModuleFacility', field: 'roleModuleFacility', display: true, edit: false, type: 'html',
                noSort: true, link: false, filter: false},
            {name: 'recognition', field: 'recognition', display: AuthService.isAdmin(), edit: false, type: 'html', noSort: true,
                link: false, filter: false},
            {name: 'locked', field: 'locked', display: false, edit: true, type: 'boolean', noSort: true, link: false, filter: true},
            {name: 'createDate', field: 'createDate', display: false, edit: false, type: 'date', noSort: true, link: false, filter: true},
            {name: 'zip', field: 'zip', filterField: 'userRoles.facility.facilityAddresses->address.zip', display: false,
                edit: false, type: 'text', noSort: true, link: false, filter: false},
            {name: 'address.city', field: 'city', filterField: 'userRoles.facility.facilityAddresses->address.city', display: false,
                edit: false, type: 'text', noSort: true, link: false, filter: false},
            {name: 'lastLoginDate', field: 'lastLoginDate', display: false, edit: false, type: 'date', noSort: true, link: false, filter: true},
            {name: 'language', field: 'language', display: false, edit: true, type: 'language', link: false, filter: false}
        ];

        function addNewUser() {
            checkIfUserExists().then(function (response) {
                registerUser(response.user, response.userExists, response.email)
            });
        }

        function isUserLocked(user) {
            return user.locked;
        }

        function isUserUnlocked(user) {
            return !user.locked;
        }

        function unlockUser(user) {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('dialog.confirm'))
                .textContent($translate.instant('user.confirm.unlock'))
                .ok($translate.instant('ok'))
                .cancel($translate.instant('cancel'));
            $mdDialog.show(confirm).then(function () {
                var requestSuccess = function () {
                    AlertService.showToast($translate.instant('user.unlocked'));
                    $rootScope.$broadcast('refreshTable', {entity: 'users'});
                    user.locked = false;
                };
                var requestError = function () {
                    AlertService.renderDialogMessage($translate.instant('user.cantUnlock'));
                };

                HttpService.shortPut('/users/switchLocked/' + user.id, null, requestSuccess, requestError);
            });
        }

        function lockUser(user) {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('dialog.confirm'))
                .textContent($translate.instant('user.confirm.lock'))
                .ok($translate.instant('ok'))
                .cancel($translate.instant('cancel'));
            $mdDialog.show(confirm).then(function () {
                var requestSuccess = function () {
                    AlertService.showToast($translate.instant('user.locked'));
                    $rootScope.$broadcast('refreshTable', {entity: 'users'});
                    user.locked = true;
                };
                var requestError = function () {
                    AlertService.renderDialogMessage($translate.instant('user.cantLock'));
                };

                HttpService.shortPut('/users/switchLocked/' + user.id, null, requestSuccess, requestError);
            });
        }

        function checkIfUserExists() {
            return $mdDialog.show({
                templateUrl: 'shared/components/usersManagement/dialogs/check.user.html',
                controller: 'CheckUserController',
                fullscreen: true
            })
        }

        function manageRoles(user) {
            var data = {
                title: "" + user.firstName + " " + user.lastName,
                childEntity: 'userRole',
                parentEntityId: user.id,
                parentEntity: user
            };
            $scope.$emit('fromParentTableEvent', data);
        }

        function resetPassword(user) {
            var confirm = $mdDialog.confirm({
                multiple: true,
                onComplete: function afterShowAnimation() {
                    var $dialog = angular.element(document.querySelector('md-dialog'));
                    var $actionsSection = $dialog.find('md-dialog-actions');
                    var $cancelButton = $actionsSection.children()[0];
                    var $confirmButton = $actionsSection.children()[1];
                    angular.element($confirmButton).removeClass('md-focused');
                    angular.element($cancelButton).addClass('md-focused');
                    $cancelButton.focus();
                }
            })
                .title($translate.instant('user.resetPasswort'))
                .ok('OK')
                .cancel($translate.instant('cancel'));

            $mdDialog.show(confirm).then(function () {
                PreloaderService.showPreloader('usersPreloader');
                HttpService.put("/users/reset/" + user.id).then(function (response) {
                    PreloaderService.hideParticularPreloader('usersPreloader');
                }, function () {
                    AlertService.renderDialogMessage('app.validation.passwordreset', 'app.validation', user.email, response.status);
                    PreloaderService.hideParticularPreloader('usersPreloader');
                });
            });
        }

        function registerUser(user, userExists, email) {
            $mdDialog.show({
                templateUrl: 'shared/components/usersManagement/dialogs/user.registration.html',
                controller: 'UserRegistrationController',
                fullscreen: true,
                clickOutsideToClose: true,
                locals: {
                    user: user,
                    userExists: userExists,
                    email: email
                }
            });
        }

        function showEditUserDialog(user) {
            $mdDialog.show({
                templateUrl: 'shared/components/usersManagement/dialogs/edit.user.html',
                controller: 'EditUserDialogController',
                fullscreen: true,
                locals: {
                    user: user
                }
            });
        }
    },
    templateUrl: 'shared/components/usersManagement/usersManagement.html'
});
