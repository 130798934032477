core_module.controller('CoreInputTestController', function ($scope, $translate, $mdDialog) {

    $scope.changeValue = "Hallo Welt";
    $scope.model1 = "";
    $scope.model2 = "";

    $scope.age = 3;
    $scope.ageRelative = 5;
    $scope.date = '2005-05-12';

    $scope.getValueFromModel = function (value) {
        console.log(value);
        $scope.model1 = value;
        $scope.model2 = value;
    };
    $scope.getDateFromModel = function (value) {
        console.log(value);
        $scope.model2 = value;
        $scope.model4 = value;
    };

    $scope.testDate = function () {
        return '2017-05-01';
    };


    $scope.openCoreDialogContentTest = function () {
        $mdDialog.show({
            templateUrl: 'views/coreInputTest/dialogs/coreDialogButtonContentTest.html',
            controller: 'CoreDialogButtonContentTestController',
            fullscreen: true

        });
    };


    $scope.modelPickers;

});


