core_module.component('entryTabs', {
    bindings: {
        selectStructure: '<',
        case: '<',
        selectedIndex: '='
    },
    controller: function ($scope) {


        $scope.tabs = [
            {
                id: 'bones_left', name: 'Knochen (l)'
            }, {
                id: 'amputations_left', name: 'Amputationen (l)'
            }, {
                id: 'joints_left', name: 'Gelenke (l)'
            }, {
                id: 'tendons_left', name: 'Sehnen (l)'
            }, {
                id: 'nerves_left', name: 'Nerven (l)'
            }, {
                id: 'vessels_left', name: 'Arterien (l)'
            }, {
                id: 'tissues_left', name: 'Weichteile (l)'
            }, {
                id: 'burned_left', name: 'Verbrennungen (l)'
            }, {
                id: 'muscles_left', name: 'Muskeln (l)'
            }, {
                id: 'bones_right', name: 'Knochen (r)'
            }, {
                id: 'amputations_right', name: 'Amputationen (r)'
            }, {
                id: 'joints_right', name: 'Gelenke (r)'
            }, {
                id: 'tendons_right', name: 'Sehnen (r)'
            }, {
                id: 'nerves_right', name: 'Nerven (r)'
            }, {
                id: 'vessels_right', name: 'Arterien (r)'
            }, {
                id: 'tissues_right', name: 'Weichteile (r)'
            }, {
                id: 'burned_right', name: 'Verbrennungen (r)'
            }, {
                id: 'muscles_right', name: 'Muskeln (r)'
            }
        ];

        $scope.leftTabs = $scope.tabs.filter(function (tab) {
            return tab.id.indexOf('_left') !== -1;
        });

        $scope.rightTabs = $scope.tabs.filter(function (tab) {
            return tab.id.indexOf('_right') !== -1;
        });




    },
    templateUrl: "shared/components/entryManagement/entry/entry.tabs.html"
});