/**
 * Created by Thorsten.Wirth on 11.04.2017.
 */
core_module.controller('MedicalSpecialityEntryController', function ($rootScope, EntityService, AlertService, HttpService, DialogService, medicalSpeciality, initObject) {
    var vm = this;
    var entity = 'medicalSpeciality';
    var isNewEntry = false;

    vm.title = 'add_medicalSpeciality';

    vm.$onInit = init;
    vm.confirm = confirm;

    function init() {
        if (medicalSpeciality) {
            vm.medicalSpeciality = angular.copy(medicalSpeciality);
            vm.title = 'edit_medicalSpeciality';
        }
        else {
            vm.medicalSpeciality = angular.copy(initObject);
            isNewEntry = true;
        }
    }

    function confirm() {
        checkIfMedicalSpecialityExists().then(function (response) {
            AlertService.renderDialogMessage('app.validation.entryExist', 'app.validation', {name: '"' + vm.medicalSpeciality.name + '"'}, response.status);
        }, function () {
            if (isNewEntry) {
                createMedicalSpeciality();
            }
            else updateMedicalSpeciality();
        });
    }

    function createMedicalSpeciality() {
        EntityService.addEntry(entity, vm.medicalSpeciality).then(function () {
            $rootScope.$broadcast("refreshTable", {entity: entity});
            AlertService.showToast(entity + 'CreatedSuccessfully');
            DialogService.hideDialog();
        });
    }

    function updateMedicalSpeciality() {
        EntityService.updateEntry(entity, vm.medicalSpeciality).then(function () {
            $rootScope.$broadcast("refreshTable", {entity: entity});
            AlertService.showToast(entity + 'UpdateSuccessfully');
            DialogService.hideDialog();
        });
    }

    function checkIfMedicalSpecialityExists() {
        return HttpService.head("/medicalSpeciality/name/" + vm.medicalSpeciality.name);
    }
});