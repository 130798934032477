core_module.controller('WorkflowController', function ($scope) {

    $scope.options = {
        rowSelection: false,
        multiSelect: true,
        autoSelect: true,
        decapitate: false,
        largeEditDialog: false,
        boundaryLinks: false,
        limitSelect: true,
        pageSelect: true,
        editable:true,
        deletable:true,
        add:true
    };

    $scope.query = {
        order: 'id',
        limit:10,
        page: 1
    };

    $scope.initObject = {
        id:'',
        active:false
    };

    $scope.columnDefs = [
        { name:'id', field:'id',display:true,edit:false,type:'text',displayName:'ID' },
        { name:'deleted', field:'deleted',display:false,edit:true,type:'boolean',displayName:'Deleted' },
        { name:'active', field:'active',display:true,edit:true,type:'boolean',displayName:'Active'}
    ];

    $scope.selRow = [];

});


