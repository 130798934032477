core_module.component('exportsTable', {
    controller: function ($rootScope, EntityService, AlertService, HttpService) {
        var vm = this;
        var documentTemplateTypeSelectOptions = [],
            facilitySelectOptions = [],
            caseStatusSelectOptions = [
                {name: "alle Fälle", value: "ALL"},
                {name: "alle offene Fälle", value: "OPEN"},
                {name: "alle abgeschlossen Fälle", value: "CLOSED"}];
        vm.$onInit = init;

        function init() {
            var documentTemplateTypeUrl = 'documentTemplateType';
            getFacilities();

            EntityService.getEntries(documentTemplateTypeUrl).then(function (response) {
                var selectOption = {};
                response.data.content.forEach(function (templateType) {
                    selectOption = {};
                    selectOption.name = templateType.templateType;
                    selectOption.value = templateType.id;
                    documentTemplateTypeSelectOptions.push(selectOption);
                });
            }, function (response) {
                AlertService.renderErrorMessage(response.data.details.cause, response.data.details, response.status);
            });
        }

        function getFacilities() {
            EntityService.getEntries('/facility', {limit: -1}).then(function (response) {
                var selectOption = {};
                response.data.content.forEach(function (facility) {
                    selectOption = {};
                    selectOption.name = facility.displayName;
                    selectOption.value = facility.id;
                    facilitySelectOptions.push(selectOption);
                });
            });
        }

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            deletable: true,
            removeCondition: viewDeleteBtn,
            showDeleted: true,
            add: true,
            customActions: [
                {
                    isActiveCondition: viewZipDownload,
                    name: 'ZIP download',
                    icon: 'archive',
                    clickFunction: zipDownload
                },
                {
                    isActiveCondition: viewExportRetain,
                    name: 'Export archivieren',
                    icon: 'save',
                    clickFunction: retainExport
                }
            ]
        };

        function viewDeleteBtn(pExport) {
            if (pExport.retain && (pExport.exportJobState == 'SUCCESS')) {
                return false;
            }
            return true;
        }

        function viewExportRetain(pExport) {
            var t=0;
            return !pExport.retain && (pExport.exportJobState == 'SUCCESS');
        }

        function viewZipDownload(pExport) {
            return pExport.exportJobState == 'SUCCESS';
        }

        function zipDownload(pExport) {
            HttpService.downloadFile('/exports/zip/' + pExport.id);
        }

        // if Export is retain (is boolean TRUE), then this Export row can not be deleted from GUI
        function retainExport(pExport) {
            var url = "/exports/retainExport/" + pExport.id;
            HttpService.put(url).then(function (response) {
                $rootScope.$broadcast('refreshTable', {entity: 'exports'});
            }, function (response) {
            });
        }

        vm.initObject = {
            id: null
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {name: 'jobState', field: 'jobState', displayName: 'Status', display: true, edit: false, type: 'html', link: false},

            {name: 'userInfo', field: 'user_info', displayName: 'Auftrag (wann/wer)', display: true, edit: false, type: 'html', link: false},
            {name: 'specifications', field: 'specifications', displayName: 'Export Festlegungen', display: true, edit: false, type: 'html', link: false},
            {name: 'documentTemplateType', field: 'documentTemplateType', display: false, displayName: 'Bogentyp', editrules: {required: true},
                edit: true, type: 'select', selectOptions: documentTemplateTypeSelectOptions, link: false, filter: true},
            {name: 'caseStatus', field: 'caseStatus', display: false, displayName: 'Fallauswahl', editrules: {required: true},
                edit: true, type: 'select', selectOptions: caseStatusSelectOptions, link: false, filter: true},

            {name: 'facility', field: 'facility', display: false, displayName: 'Einrichtung',
                edit: true, type: 'select-search', selectOptions: facilitySelectOptions, link: false, filter: true},

            {name: 'accidentFrom', field: 'accidentFrom', display: false, displayName: 'Unfalldatum ab',
                edit: true, type: 'date', link: false, filter: true},
            {name: 'accidentTo', field: 'accidentTo', display: false, displayName: 'Unfalldatum bis',
                edit: true, type: 'date', link: false, filter: true},
            {name: 'admissionFrom', field: 'admissionFrom', display: false, displayName: 'Datum der Erstvorstellung in der eigenen Klinik ab',
                edit: true, type: 'date', link: false, filter: true},
            {name: 'admissionTo', field: 'admissionTo', display: false, displayName: 'Datum der Erstvorstellung in der eigenen Klinik bis',
                edit: true, type: 'date', link: false, filter: true},
            // is now in HTR not used
            // {name: 'modifiedFrom', field: 'modifiedFrom', display: false, displayName: 'Änderungsdatum ab',
            //     edit: true, type: 'date', link: false, filter: true},
            // {name: 'modifiedTo', field: 'modifiedTo', display: false, displayName: 'Änderungsdatum bis',
            //     edit: true, type: 'date', link: false, filter: true},
            {name: 'documentTemplateTypeName', field: 'documentTemplateType_Name', display: true, displayName: 'Bogentyp',
                edit: false, type: 'text', link: false, filter: true}
        ];
    },
    templateUrl: 'shared/components/exportCsv/exportsTable/exportsTable.html',
    controllerAs: 'exportsTable'
});
