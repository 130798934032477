core_module.component('appMain', {
    controller: function ($scope, $timeout, $rootScope, $http, PreloaderService) {

        this.$onInit = handlePreloader;

        function handlePreloader() {
            var waitForLoad = $scope.$watch(function () {
                return isLoading()
            }, function (isLoading) {
                if (!isLoading) {
                    PreloaderService.particularWaitForDOM('pagePreloader');
                    waitForLoad();
                }
            });
        }

        $rootScope.$on('$routeChangeSuccess', function () {
            $timeout(function () {
                handlePreloader();
            }, 200);
        });

        $rootScope.$on('$routeChangeStart', function () {
            PreloaderService.showPreloader('pagePreloader');
        });


        function isLoading() {
            return $http.pendingRequests.length > 0
        }


    },
    templateUrl: 'shared/components/main/main.html'
});