core_module.component('facilitiesManagement', {
    controller: function ($scope, $route, $mdDialog, $translate, $location, HttpService, AuthService) {
        var vm = this;

        var formData = {
            financialCarrier: [{name: 'PUBLIC', value: 'PUBLIC'}, {
                name: 'NON_PROFIT',
                value: 'NON_PROFIT'
            }, {name: 'PRIVATE', value: 'PRIVATE'}],
            supplyLevel: [{name: 'MAX', value: 'MAX'}, {name: 'COMMON', value: 'COMMON'}, {
                name: 'BASIC',
                value: 'BASIC'
            }]
        };

        vm.addFacility = addFacility;
        vm.editFacility = editFacility;

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: AuthService.isAdmin(),
            add: AuthService.isAdmin(),
            customActions: [
                {
                    isActiveCondition: isFacilityAddressesAllowed,
                    name: 'manage_addresses',
                    icon: 'home',
                    clickFunction: manageAddresses
                }, {
                    isActiveCondition: isAdmin,
                    name: 'manage_children',
                    icon: 'dns',
                    clickFunction: manageChildren
                },
                {
                    isActiveCondition: isFacilitySpecialityAllowed,
                    name: 'medicalSpeciality.title',
                    icon: 'local_hospital',
                    clickFunction: getMedical
                }
            ]
        };

        vm.query = {
            order: 'name',
            limit: 10,
            page: 1
        };

        vm.initObject = {
            deleted: false,
            locked: false,
            name: '',
            facilityType_id: null
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', filter: true},
            {name: 'deleted', field: 'deleted', display: AuthService.isAdmin(), edit: true, type: 'boolean'},
            {name: 'medicalSpeciality', field: 'medicalSpeciality', display: true, edit: false, type: 'text', noSort: true},
            {name: 'name', field: 'name', display: true, edit: true, type: 'text', filter: true},
            {name: 'displayName', field: 'displayName', display: false, edit: true, type: 'text'},
            {name: 'zip', field: 'zip', filterField: 'facilityAddresses->address.zip', display: false,
                edit: false, type: 'text', noSort: true, link: false, filter: true},
            {name: 'address.city', field: 'city', filterField: 'facilityAddresses->address.city', display: false,
                edit: false, type: 'text', noSort: true, link: false, filter: true},
            {name: 'address.street', field: 'city', filterField: 'facilityAddresses->address.street', display: false,
                edit: false, type: 'text', noSort: true, link: false, filter: true},
            {name: 'facilityInfo', field: 'facilityInfo', display: true, edit: true, type: 'html', noSort: true, filter: false}
        ];

        function addFacility() {
            $mdDialog.show({
                templateUrl: 'shared/components/facilitiesManagement/dialogs/facilityEntry.html',
                controller: 'FacilityDialogsController',
                controllerAs: 'facilityDialog',
                fullscreen: true,
                locals: {
                    formData: formData,
                    initObject: vm.initObject,
                    facility: null
                }
            });
        }

        function editFacility(facility) {
            $mdDialog.show({
                templateUrl: 'shared/components/facilitiesManagement/dialogs/facilityEntry.html',
                controller: 'FacilityDialogsController',
                controllerAs: 'facilityDialog',
                fullscreen: true,
                locals: {
                    formData: formData,
                    initObject: vm.initObject,
                    facility: facility
                }
            });
        }

        function manageAddresses(facility) {
            var data = {
                title: "" + facility.name,
                childEntity: 'facilityAddress',
                parentEntityId: facility.id,
                parentEntity: facility
            };
            $scope.$emit('fromParentToAddresses', data);
        }

        function manageChildren(facility) {
            var data = {
                title: "" + facility.name,
                childEntity: 'facilityConnector',
                parentEntityId: facility.id,
                parentEntity: facility
            };
            $scope.$emit('fromParentToConnector', data);
        }

        function isFacilityParameterAllowed() {
            return AuthService.hasPermissionOrIsAdmin("ROLE_P_FACILITYPARAMETER_LIST");
        }

        function isFacilitySpecialityAllowed() {
            return AuthService.hasPermissionOrIsAdmin("ROLE_P_FACILITYMEDICALSPECIALITY_LIST");
        }

        function isFacilityAddressesAllowed() {
            return AuthService.hasPermissionOrIsAdmin("ROLE_P_FACILITYADDRESS_LIST");
        }

        function isAdmin() {
            return AuthService.isAdmin();
        }

        function getMedical(facility) {
            var data = {
                title: "" + facility.name,
                childEntity: 'facilityMedicalSpeciality',
                parentEntityId: facility.id,
                parentEntity: facility
            };
            $scope.$emit('fromParentToMedical', data);
        }

        function getFacilityParameter(facility) {
            var data = {
                title: "" + facility.name,
                childEntity: 'facilityParameters',
                parentEntityId: facility.id,
                parentEntity: facility
            };
            $scope.$emit('fromParentToParameter', data);
        }

    },
    templateUrl: 'shared/components/facilitiesManagement/facilitiesManagement.html',
    controllerAs: 'facilities'
});
