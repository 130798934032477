core_module.component('periodicReport', {
    controller: function ($mdDialog, HttpService) {
        var vm = this;
        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            deletable: true,
            add: true,
            customActions: [{
                name: 'Report download',
                icon: 'file_download',
                clickFunction: downloadInvoicePdf
            }]
        };

        function downloadInvoicePdf(periodicReport) {
            HttpService.downloadFile('/periodicreport/pdf/' + periodicReport.id);
        }

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false},
            {name: 'name', field: 'name', display: true, edit: true, type: 'text', link: false},
            {name: 'facilityName', field: 'facility_name', display: true, edit: true, type: 'text', link: false}
        ];

        vm.addNewReport = function () {
            $mdDialog.show({
                templateUrl: 'shared/components/periodicReport/dialogs/add.periodicReport.html',
                controller: 'AddPeriodicReportController',
                controllerAs: 'reportDialog',
                fullscreen: true
            });
        }

    },
    templateUrl: 'shared/components/periodicReport/periodicReport.html',
    controllerAs: 'periodicReport'
});