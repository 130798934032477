core_module.factory('CaseService', function ($rootScope, $mdDialog, $q, $http, HttpService, AlertService, AuthService) {
        var caseService = {
            alignPatientIDAndName: alignPatientIDAndName,
            saveCase: save,
            closeCase: close,
            openCase: openCase,
            createNewCase: create,
            load: load,
            deleteCase: remove,
            validateCase: validate,
            updateSheet: updateSheet,
            initPatientAndTreatmentSection: initPatientAndTreatmentSection,
            openModal: openModal,
            svgClick: svgClick,
            svgSelected: svgSelected,
            svgDetailWindowOpen: svgDetailWindowOpen,
            clearUp: clearUp,
            filterOutPatientAndTreatment: filterOutPatientAndTreatment,
            closeCaseModal: closeCaseModal,
            createCaseModal: createCaseModal,
            saveCaseModal: saveCaseModal,
            createIssueModal: createIssueModal,
            isCaseClosed: isCaseClosed,
            isSheetClosed: isSheetClosed,
            isSheetOpen: isSheetOpen,
            isCaseOpen: isCaseOpen,
            loadTemplate: loadTemplate,
            hasTemplate: hasTemplate,
        };


        var init = function () {
            loadTemplate()
        };
        init();

        var template = {};
        var currentDate = new Date();
        var isDetailWindowOpen = [];

        var fineStructures = [
            {
                id: 'L2.E',
                subs: [
                    {
                        id: 'L2.7.24',
                        name: 'Handgelenk - D I	Beugesehne - Zone IV - FPL (M. flexor pollicis longus)'
                    },
                    {
                        id: 'L2.7.25',
                        name: 'Handgelenk - D II	Beugesehne - Zone IV - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'L2.7.26',
                        name: 'Handgelenk - D II	Beugesehne - Zone IV - FDS (M. flexor digitorum superficialis)'
                    },
                    {
                        id: 'L2.7.27',
                        name: 'Handgelenk - D III	Beugesehne - Zone IV - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'L2.7.28',
                        name: 'Handgelenk - D III	Beugesehne - Zone IV - FDS (M. flexor digitorum superficialis)'
                    },
                    {
                        id: 'L2.7.29',
                        name: 'Handgelenk - D IV	Beugesehne - Zone IV - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'L2.7.30',
                        name: 'Handgelenk - D IV	Beugesehne - Zone IV - FDS (M. flexor digitorum superficialis)'
                    },
                    {
                        id: 'L2.7.31',
                        name: 'Handgelenk - D V	Beugesehne - Zone IV - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'L2.7.32',
                        name: 'Handgelenk - D V	Beugesehne - Zone IV - FDS (M. flexor digitorum superficialis)'
                    }]
            },
            {
                id: 'L2.F',
                subs: [
                    {id: 'L2.8.35', name: 'Unterarm - D I	Beugesehne - Zone V - FPL (M. flexor pollicis longus)'},
                    {
                        id: 'L2.8.36',
                        name: 'Unterarm - D II	Beugesehne - Zone V - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'L2.8.37',
                        name: 'Unterarm - D II	Beugesehne - Zone V - FDS (M. flexor digitorum superficialis)'
                    },
                    {
                        id: 'L2.8.38',
                        name: 'Unterarm - D III	Beugesehne - Zone V - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'L2.8.39',
                        name: 'Unterarm - D III	Beugesehne - Zone V - FDS (M. flexor digitorum superficialis)'
                    },
                    {
                        id: 'L2.8.40',
                        name: 'Unterarm - D IV	Beugesehne - Zone V - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'L2.8.41',
                        name: 'Unterarm - D IV	Beugesehne - Zone V - FDS (M. flexor digitorum superficialis)'
                    },
                    {
                        id: 'L2.8.42',
                        name: 'Unterarm - D V	Beugesehne - Zone V - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'L2.8.43',
                        name: 'Unterarm - D V	Beugesehne - Zone V - FDS (M. flexor digitorum superficialis)'
                    }]
            },

            {
                id: 'R2.E',
                subs: [
                    {
                        id: 'R2.7.24',
                        name: 'Handgelenk - D I	Beugesehne - Zone IV - FPL (M. flexor pollicis longus)'
                    },
                    {
                        id: 'R2.7.25',
                        name: 'Handgelenk - D II	Beugesehne - Zone IV - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'R2.7.26',
                        name: 'Handgelenk - D II	Beugesehne - Zone IV - FDS (M. flexor digitorum superficialis)'
                    },
                    {
                        id: 'R2.7.27',
                        name: 'Handgelenk - D III	Beugesehne - Zone IV - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'R2.7.28',
                        name: 'Handgelenk - D III	Beugesehne - Zone IV - FDS (M. flexor digitorum superficialis)'
                    },
                    {
                        id: 'R2.7.29',
                        name: 'Handgelenk - D IV	Beugesehne - Zone IV - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'R2.7.30',
                        name: 'Handgelenk - D IV	Beugesehne - Zone IV - FDS (M. flexor digitorum superficialis)'
                    },
                    {
                        id: 'R2.7.31',
                        name: 'Handgelenk - D V	Beugesehne - Zone IV - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'R2.7.32',
                        name: 'Handgelenk - D V	Beugesehne - Zone IV - FDS (M. flexor digitorum superficialis)'
                    }]
            },
            {
                id: 'R2.F',
                subs: [
                    {id: 'R2.8.35', name: 'Unterarm - D I	Beugesehne - Zone V - FPL (M. flexor pollicis longus)'},
                    {
                        id: 'R2.8.36',
                        name: 'Unterarm - D II	Beugesehne - Zone V - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'R2.8.37',
                        name: 'Unterarm - D II	Beugesehne - Zone V - FDS (M. flexor digitorum superficialis)'
                    },
                    {
                        id: 'R2.8.38',
                        name: 'Unterarm - D III	Beugesehne - Zone V - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'R2.8.39',
                        name: 'Unterarm - D III	Beugesehne - Zone V - FDS (M. flexor digitorum superficialis)'
                    },
                    {
                        id: 'R2.8.40',
                        name: 'Unterarm - D IV	Beugesehne - Zone V - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'R2.8.41',
                        name: 'Unterarm - D IV	Beugesehne - Zone V - FDS (M. flexor digitorum superficialis)'
                    },
                    {
                        id: 'R2.8.42',
                        name: 'Unterarm - D V	Beugesehne - Zone V - FDP (M. flexor digitorum profundus)'
                    },
                    {
                        id: 'R2.8.43',
                        name: 'Unterarm - D V	Beugesehne - Zone V - FDS (M. flexor digitorum superficialis)'
                    }]
            },


            {
                id: 'R2.A',
                subs: [
                    {id: 'R2.7.78', name: 'Handgelenk - D II	Strecksehne - Zone VII - EDC (M. extensor digitorum)'},
                    {id: 'R2.7.79', name: 'Handgelenk - D II	Strecksehne - Zone VII - EI (M. extensor indicis)'},
                    {
                        id: 'R2.7.80',
                        name: 'Handgelenk - D III	Strecksehne - Zone VII - EDC (M. extensor digitorum communis)'
                    },
                    {
                        id: 'R2.7.81',
                        name: 'Handgelenk - D IV	Strecksehne - Zone VII - EDC (M. extensor digitorum communis)'
                    },
                    {
                        id: 'R2.7.82',
                        name: 'Handgelenk - D V	Strecksehne - Zone VII - EDM (M. extensor digiti minimi)'
                    }
                ]
            },

            {
                id: 'R2.B',
                subs: [
                    {id: 'R2.8.89', name: 'Unterarm - D II	Strecksehne - Zone VIII - EDC (M. extensor digitorum)'},
                    {id: 'R2.8.90', name: 'Unterarm - D II	Strecksehne - Zone VIII - EI (M. extensor indicis)'},
                    {
                        id: 'R2.8.91',
                        name: 'Unterarm - D III	Strecksehne - Zone VIII - EDC (M. extensor digitorum communis)'
                    },
                    {
                        id: 'R2.8.92',
                        name: 'Unterarm - D IV	Strecksehne - Zone VIII - EDC (M. extensor digitorum communis)'
                    },
                    {id: 'R2.8.93', name: 'Unterarm - D V	Strecksehne - Zone VIII - EDM (M. extensor digiti minimi)'}
                ]
            },
            {
                id: 'R2.C',
                subs: [
                    {id: 'R2.7.84', name: 'Handgelenk - Strecksehne - Zone VII - ECRL (M. extensor carpi radialis longus)'},
                    {id: 'R2.7.85', name: 'Handgelenk - Strecksehne - Zone VII - ECRB (M. extensor carpi radialis brevis)'}
                ]
            },
            {
                id: 'R2.D',
                subs: [
                    {id: 'R2.8.95', name: 'Unterarm - Strecksehne - Zone VIII - ECRL (M. extensor carpi radialis longus)'},
                    {id: 'R2.8.96', name: 'Unterarm - Strecksehne - Zone VIII - ECRB (M. extensor carpi radialis brevis)'}
                ]
            },

            {
                id: 'L2.A',
                subs: [
                    {id: 'L2.7.78', name: 'Handgelenk - D II	Strecksehne - Zone VII - EDC (M. extensor digitorum)'},
                    {id: 'L2.7.79', name: 'Handgelenk - D II	Strecksehne - Zone VII - EI (M. extensor indicis)'},
                    {
                        id: 'L2.7.80',
                        name: 'Handgelenk - D III	Strecksehne - Zone VII - EDC (M. extensor digitorum communis)'
                    },
                    {
                        id: 'L2.7.81',
                        name: 'Handgelenk - D IV	Strecksehne - Zone VII - EDC (M. extensor digitorum communis)'
                    },
                    {
                        id: 'L2.7.82',
                        name: 'Handgelenk - D V	Strecksehne - Zone VII - EDM (M. extensor digiti minimi)'
                    }
                ]
            },

            {
                id: 'L2.B',
                subs: [
                    {id: 'L2.8.89', name: 'Unterarm - D II	Strecksehne - Zone VIII - EDC (M. extensor digitorum)'},
                    {id: 'L2.8.90', name: 'Unterarm - D II	Strecksehne - Zone VIII - EI (M. extensor indicis)'},
                    {
                        id: 'L2.8.91',
                        name: 'Unterarm - D III	Strecksehne - Zone VIII - EDC (M. extensor digitorum communis)'
                    },
                    {
                        id: 'L2.8.92',
                        name: 'Unterarm - D IV	Strecksehne - Zone VIII - EDC (M. extensor digitorum communis)'
                    },
                    {id: 'L2.8.93', name: 'Unterarm - D V	Strecksehne - Zone VIII - EDM (M. extensor digiti minimi)'}
                ]
            },
            {
                id: 'L2.C',
                subs: [
                    {id: 'L2.7.84', name: 'Handgelenk - Strecksehne - Zone VII - ECRL (M. extensor carpi radialis longus)'},
                    {id: 'L2.7.85', name: 'Handgelenk - Strecksehne - Zone VII - ECRB (M. extensor carpi radialis brevis)'}
                ]
            },
            {
                id: 'L2.D',
                subs: [
                    {id: 'L2.8.95', name: 'Unterarm - Strecksehne - Zone VIII - ECRL (M. extensor carpi radialis longus)'},
                    {id: 'L2.8.96', name: 'Unterarm - Strecksehne - Zone VIII - ECRB (M. extensor carpi radialis brevis)'}
                ]
            }


        ];

        function loadTemplate() {
            HttpService.get("/documentTemplate").then(loadTemplateCompleted)
        }

        function loadTemplateCompleted(response) {
            template = response.data.content[0];
        }

        function hasTemplate() {
            return template && template.hasOwnProperty('id');
        }

        function alignPatientIDAndName(caseObject, name) {
            caseObject.mcase.name = name;
            caseObject.mcase.patientId = name;
            caseObject.mcase.caseCustomId = name;
        }

        function save(caseObject) {
            var deferred = $q.defer();
            caseObject.mcase.currentFacility_Id = AuthService.getCurrentUserFacility().id;
            caseObject.mcase.dateOfAccident = caseObject.treatmentSection.dateOfAccident;
            caseObject.mcase.dateOfAdmission = caseObject.treatmentSection.dateOfAdmission;

            alignPatientIDAndName(caseObject, caseObject.mcase.name);
            caseObject.sheet.document.data = caseObject.data;
            caseObject.sheet.document.documentTemplateId = caseObject.template.id;
            delete caseObject.sheet.document.documentTemplate;
            updateOrCreateMcase(caseObject).then(function (res) {
                deferred.resolve(res);
            });
            return deferred.promise;
        }

        function updateOrCreateMcase(caseToSave) {
            var deferred = $q.defer();
            if (caseToSave.mcase.id) {
                $http.put("api/mCase/" + caseToSave.mcase.id, caseToSave.mcase).then(function (response) {
                    updateMcaseData(response, caseToSave).then(function (res) {
                        deferred.resolve(res);
                    });
                });
            } else {
                $http.post("api/mCase/", caseToSave.mcase).then(function (response) {
                    updateMcaseData(response, caseToSave).then(function (res) {
                        deferred.resolve(res);
                    });
                });
            }
            return deferred.promise;
        }

        function updateMcaseData(response, caseObject) {
            var deferred = $q.defer();
            var temporaryMcase = caseObject.mcase;
            caseObject.mcase = response.data;
            caseObject.mcase.patientID = caseObject.mcase.name;
            if (caseObject.mcase.status !== "CLOSED") {
                updateSheet(caseObject, temporaryMcase).then(function (res) {
                    deferred.resolve(res);
                });
            } else {
                deferred.resolve(caseObject)
            }
            return deferred.promise;
        }

        function updateSheet(caseToSave, mcase) {
            var deferred = $q.defer();
            if (caseToSave.sheet.id) {
                $http.put("api/sheet/" + caseToSave.sheet.id, caseToSave.sheet).then(function (response) {
                    updateSheetData(response, caseToSave).then(function (res) {
                        deferred.resolve(res);
                    });
                });
            } else {
                $http.post("api/sheet/?mCaseId=" + caseToSave.mcase.id, caseToSave.sheet).then(function (response) {
                    updateSheetData(response, caseToSave).then(function (res) {
                        deferred.resolve(res);
                    });
                });
            }
            return deferred.promise;
        }

        function updateSheetData(response, caseObject) {
            var deferred = $q.defer();
            caseObject.sheet = response.data;
            deferred.resolve(caseObject)
            return deferred.promise;
        }

        function close(caseObject) {
            var deferred = $q.defer();
            caseObject.sheet.status = "CLOSED";
            caseObject.mcase.status = "CLOSED";
            caseObject.mcase.currentFacility_Id = AuthService.getCurrentUserFacility().id;
            alignPatientIDAndName(caseObject, caseObject.mcase.name);
            caseObject.sheet.document.data = caseObject.data;
            caseObject.sheet.document.documentTemplateId = caseObject.template.id;
            delete caseObject.sheet.document.documentTemplate;
            caseObject.sheet.mcase = caseObject.mcase;
            updateSheet(caseObject).then(function (res) {
                deferred.resolve(res);
            });
            return deferred.promise;
        };

        function openCase(caseObject) {
            var deferred = $q.defer();
            caseObject.sheet.status = "OPEN";
            caseObject.mcase.status = "OPEN";
            save(caseObject).then(function (savedCaseObject) {
                deferred.resolve(savedCaseObject);
            });
            return deferred.promise;
        }

        function create() {
            var caseObject = clearUp({});
            caseObject.template = template;
            var document = {'documentTemplate': caseObject.template, 'data': caseObject.data};
            caseObject.sheet = {
                'document': document,
                'currentFacility_Id': AuthService.getCurrentUserFacility().id,
                'createdByUser_Id': AuthService.getCurrentUser().id,
                'createdByFacility_Id': AuthService.getCurrentUserFacility().id
            };
            caseObject.mcase = {
                'sheetList': [caseObject.sheet],
                'currentFacility_Id': AuthService.getCurrentUserFacility().id,
                'createdByUser_Id': AuthService.getCurrentUser().id,
                'createdByFacility_Id': AuthService.getCurrentUserFacility().id
            };

            caseObject.issues = [];
            initPatientAndTreatmentSection(caseObject);
            return caseObject;

        }

        function load(caseId) {
            return HttpService.get("/mCase/" + caseId).then(loadCase);
        }

        function loadCase(response) {
            var caseObject = {mcase: response.data};
            return HttpService.get("/sheet?mCaseId=" + caseObject.mcase.id).then(function (response) {
                return loadSheet(response, caseObject)
            });

        };

        function loadSheet(response, caseObject) {
            caseObject.sheet = response.data.content[0]; // FIXME: ONE SAME TEMPLATE FOR HTR ?
            caseObject.data = caseObject.sheet.document.data;
            caseObject.template = {id: caseObject.sheet.document.documentTemplateId};
            return loadDocumentTemplate(caseObject.template.id, caseObject).then(function (loadedCaseObject) {
                loadedCaseObject.issues = [];
                for (var key in loadedCaseObject.data) {
                    if (loadedCaseObject.data[key].sectionVersionCustomId && loadedCaseObject.data[key].sectionVersionCustomId !== undefined
                        && loadedCaseObject.data[key].sectionVersionCustomId !== "patient"
                        && loadedCaseObject.data[key].sectionVersionCustomId !== "treatment") {

                        svgClick(loadedCaseObject.data[key].sectionVersionCustomId, loadedCaseObject, true);
                    }
                }
                initPatientAndTreatmentSection(loadedCaseObject);
                return loadedCaseObject;
            });
        }

        function loadDocumentTemplate(documentTemplateID, caseObject) {
            var deferred = $q.defer();
            HttpService.get("/documentTemplate/" + documentTemplateID + "?fullTemplate=true").then(function (response) {
                deferred.resolve(setTemplate(response, caseObject));
            });
            return deferred.promise;
        }

        function setTemplate(response, caseObject) {
            caseObject.template = response.data;
            return caseObject;
        }

        function initPatientAndTreatmentSection(caseObject) {
            svgClick('patient', caseObject, true);
            if (!caseObject.data[caseObject.section.identifier])
                caseObject.data[caseObject.section.identifier] = {
                    'sectionVersionName': caseObject.section.name,
                    'sectionVersionCustomId': caseObject.section.customId
                };
            caseObject.patientSection = caseObject.data[caseObject.section.identifier];
            svgClick('treatment', caseObject, true);
            if (!caseObject.data[caseObject.section.identifier])
                caseObject.data[caseObject.section.identifier] = {
                    'sectionVersionName': caseObject.section.name,
                    'sectionVersionCustomId': caseObject.section.customId
                };

            caseObject.treatmentSection = caseObject.data[caseObject.section.identifier];

            if (caseObject.treatmentSection.dateOfAccident)
                caseObject.treatmentSection.dateOfAccident = new Date(caseObject.treatmentSection.dateOfAccident);
            if (caseObject.treatmentSection.timeOfAccident)
                caseObject.treatmentSection.timeOfAccident = new Date(caseObject.treatmentSection.timeOfAccident);
            if (caseObject.treatmentSection.dateOfAdmission)
                caseObject.treatmentSection.dateOfAdmission = new Date(caseObject.treatmentSection.dateOfAdmission);
            if (caseObject.treatmentSection.timeOfAdmission)
                caseObject.treatmentSection.timeOfAdmission = new Date(caseObject.treatmentSection.timeOfAdmission);
            if (caseObject.treatmentSection.dateOfCut)
                caseObject.treatmentSection.dateOfCut = new Date(caseObject.treatmentSection.dateOfCut);
            if (caseObject.treatmentSection.timeOfCut)
                caseObject.treatmentSection.timeOfCut = new Date(caseObject.treatmentSection.timeOfCut);
            if (caseObject.treatmentSection.dateOfSeal)
                caseObject.treatmentSection.dateOfSeal = new Date(caseObject.treatmentSection.dateOfSeal);
            if (caseObject.treatmentSection.timeOfSeal)
                caseObject.treatmentSection.timeOfSeal = new Date(caseObject.treatmentSection.timeOfSeal);

            return caseObject
        }

        function remove(mcase) {
            if (mcase.status !== 'OPEN') {
                AlertService.renderInfoMessage("case.closed.cannotDevare");
                return;
            }
            HttpService.delete("/mCase/" + mcase.id).then(function (response) {
                $rootScope.$broadcast('refreshTable', {entity: 'mCase'});
                $rootScope.$broadcast('facilityTemplateChange');
            });

        }

        function svgClick(id, caseObject, init) {
            var deferred = $q.defer();
            // caseObject = angular.copy(caseObject);
            if (!init || typeof init === 'undefined') {
                var structures = fineStructures.filter(function (element) {
                    return element.id === id;
                })[0];
                if (structures && structures.subs.length > 0) {
                    showDialog(structures.subs, caseObject).then(function (updatedCase) {
                        deferred.resolve(updatedCase);
                    });
                    return deferred.promise;
                }
            }
            var path = [];
            var issueElement = findObjectById(caseObject.template, path, id);
            caseObject.section = issueElement ? issueElement[issueElement.length - 1] : undefined;

            if (issueElement && issueElement[issueElement.length - 2] && issueElement[issueElement.length - 2] && issueElement[issueElement.length - 2].customId) {
                caseObject.section.structure = issueElement[issueElement.length - 2].customId;
            }

            if (init && caseObject.section) {
                caseObject.issues.push(caseObject.section);
                deferred.resolve(caseObject);
                return deferred.promise;
            }

            if (!caseObject.section || typeof caseObject.section === 'undefined') {
                console.log("WARNING_UNKNOWN_STRUCTURE");
                deferred.resolve(caseObject);
                return deferred.promise;
            } else if (caseObject.issues.indexOf(caseObject.section) >= 0) {
                AlertService.renderInfoMessage("INFO_STRUCTURE_ALREADY_STORED");
            }

            deferred.resolve(caseObject);
            return deferred.promise;
        }

        function svgSelected(id, caseObject) {
            var subs = [];
            var filteredFineStructures = fineStructures.filter(function (element) {
                return element.id && element.id === id;
            });

            if (filteredFineStructures && filteredFineStructures.length > 0) {
                subs = filteredFineStructures[0].subs.map(function (element) {
                    return element.id;
                })
            }

            if (subs.length == 0) {
                subs.push(id);
            }

            if (!caseObject || !caseObject.issues)
                return false;

            for (var i = 0; i < caseObject.issues.length; i++) {
                if (caseObject.issues[i]) {
                    var arrFilteredSubs = subs.filter(function (element) {
                        return element === caseObject.issues[i].customId;
                    });
                    if (arrFilteredSubs && arrFilteredSubs.length > 0) {
                        return true;
                    }
                }
            }
            return false;
        }

        function svgDetailWindowOpen(id) {
            return isDetailWindowOpen.includes(id);
        }

        function validate(caseObject) {

            var validationObject = validationCheck(caseObject.treatmentSection);

            if (!validationObject.valid) {
                AlertService.renderDialogMessage(validationObject.reason);
            }

            return validationObject.valid;

        }

        function validationCheck(section) {

            var validationObject = {section: section, currentDate: new Date(), valid: true, reason: ''};

            if ((validationObject.section.dateOfAccident > validationObject.currentDate) ||
                (validationObject.section.dateOfAdmission > validationObject.currentDate) ||
                (validationObject.section.dateOfCut > validationObject.currentDate) ||
                (validationObject.section.dateOfSeal > validationObject.currentDate)) {
                validationObject.valid = false;
                validationObject.reason = "Einige Datumswerte liegen in der Zukunft.";
            } else if (validationObject.section.dateOfAccident > validationObject.section.dateOfAdmission && validationObject.section.dateOfAdmission != null) {
                validationObject.valid = false;
                validationObject.reason = "Das Unfalldatum muss vor dem Datum der Erstvorstellung in der eigenen Klinik liegen.";
            } else if (validationObject.section.dateOfAccident > validationObject.section.dateOfCut && validationObject.section.dateOfCut != null) {
                validationObject.valid = false;
                validationObject.reason = "Das Unfalldatum muss vor dem OP-Datum liegen.";
            } else if (validationObject.section.dateOfAdmission > validationObject.section.dateOfCut && validationObject.section.dateOfCut != null) {
                validationObject.valid = false;
                validationObject.reason = "Das Datum der Erstvorstellung in der eigenen Klinik muss vor dem OP-Datum liegen.";
            } else if (validationObject.section.dateOfCut > validationObject.section.dateOfSeal) {
                validationObject.valid = false;
                validationObject.reason = "Das OP-Datum muss vor dem Nahtdatum liegen.";
            } else if ((validationObject.section.dateOfAccident === validationObject.section.dateOfAdmission) &&
                (validationObject.section.timeOfAccident > validationObject.section.timeOfAdmission)) {
                validationObject.valid = false;
                validationObject.reason = "Das Unfalldatum muss vor dem Datum der Erstvorstellung in der eigenen Klinik liegen.";
            } else if ((validationObject.section.dateOfAdmission === validationObject.section.dateOfCut) &&
                (validationObject.section.timeOfAdmission > validationObject.section.timeOfCut)) {
                validationObject.valid = false;
                validationObject.reason = "Die Aufnahmezeit muss vor dem Schnittzeitpunkt liegen.";
            } else if ((validationObject.section.dateOfCut === validationObject.section.dateOfSeal) &&
                (validationObject.section.timeOfCut > validationObject.section.timeOfSeal)) {
                validationObject.valid = false;
                validationObject.reason = "Der Schnittzeitpunkt muss vor dem Nahtzeitpunkt liegen.";
            } else {
                validationObject.valid = true;
                validationObject.reason = "";
            }
            return validationObject;
        }


        function findObjectById(root, path, id) {
            var me = path.concat([root]);
            if (root.customId === id) {
                return me;
            }

            var arrays = [
                root.children ? root.children : []
            ];
            var children = [].concat.apply([], arrays);

            if (!children || !children.length) {
                children = [];
            }

            for (var i = 0; i < children.length; i++) {
                var child = children[i];
                var x = findObjectById(child, me, id);
                if (Array.isArray(x) && !!x.length) {
                    return x;
                }
            }
        }

        function clearUp(caseObject) {
            caseObject.template = {};
            caseObject.issues = [];
            caseObject.section = {};
            caseObject.sheet = {};
            caseObject.data = {};
            caseObject.patientSection = {};
            caseObject.mcase = {};
            caseObject.treatmentSection = {};
            return caseObject;
        }

        function openModal() {
            $mdDialog.show({
                templateUrl: 'shared/components/casesManagement/dialogs/add.issue.html',
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                onRemoving: dialogOnClose,
                controller: 'EntryDialogsController'
            });
        }

        function showDialog(citems, caseObject) {
            $rootScope.citems = citems;
            return $mdDialog.show({
                templateUrl: 'shared/components/entryManagement/entry/section.dialog.html',
                controller: 'SectionDialogController',
                clickOutsideToClose: false,
                onRemoving: dialogOnClose,
                caseObject: caseObject
            });
        }

        function filterOutPatientAndTreatment(section) {
            if (section && section.customId)
                return (section.customId !== 'patient' && section.customId !== 'treatment');
            else
                return true;
        }

        function closeCaseModal(caseObject) {
            $mdDialog.show({
                templateUrl: 'shared/components/entryManagement/entry/details.issue.html',
                clickOutsideToClose: false,
                onRemoving: dialogOnClose,
                controller: 'DetailsIssueController',
                controllerAs: 'issueDialog',
                caseObject: caseObject,
                filter: filterOutPatientAndTreatment,
                action: "CLOSE"
            });
        }

        function createCaseModal() {
            $mdDialog.show({
                templateUrl: 'shared/components/casesManagement/dialogs/add.case.html',
                parent: angular.element(document.body),
                clickOutsideToClose: false,
                onRemoving: dialogOnClose,
                controller: 'CreateCaseController',
                controllerAs: 'caseDialog'
            });
        }

        function saveCaseModal(caseObject) {
            $mdDialog.show({
                templateUrl: 'shared/components/entryManagement/entry/details.issue.html',
                clickOutsideToClose: false,
                onRemoving: dialogOnClose,
                controller: 'DetailsIssueController',
                controllerAs: 'issueDialog',
                caseObject: caseObject,
                filter: filterOutPatientAndTreatment,
                action: "SAVE"
            });
        }

        function createIssueModal(id,caseObject) {
            isDetailWindowOpen.push(id);
            var deferred = $q.defer();
            $mdDialog.show({
                templateUrl: 'shared/components/entryManagement/entry/add.issue.html',
                clickOutsideToClose: false,
                controller: 'CreateIssueController',
                controllerAs: 'issueDialog',
                onRemoving: dialogOnClose,
                caseObject: caseObject
            }).then(function (updatedCase) {
                deferred.resolve(updatedCase);
            });
            return deferred.promise;
        }

        function dialogOnClose() {
            isDetailWindowOpen = [];
            $rootScope.citems = undefined;
        }

        function isCaseClosed(caseObject) {
            return caseObject.mcase.status === "CLOSED" && isSheetClosed(caseObject);
        }

        function isCaseOpen(caseObject) {
            return caseObject.mcase.status === "OPEN" && isSheetOpen(caseObject);
        }

        function isSheetOpen(caseObject) {
            return caseObject.sheet.status === "OPEN";
        }

        function isSheetClosed(caseObject) {
            return caseObject.sheet.status === "CLOSED";
        }

        return caseService;
    }
);
