core_module.controller('CreateCaseController', function ($location, $mdDialog, $q, $scope, $translate, $window, DialogService, HttpService, AlertService, CaseService, PatientSelectService, PreloaderService) {
    var vm = this;

    vm.step = 1;
    vm.options = PatientSelectService.selectOptions;

    vm.$onInit = init;
    vm.nextStep = nextStep;
    vm.previousStep = previousStep;
    vm.createCase = createCase;
    vm.closeWizard = closeWizard;
    vm.setDateOfSeal = setDateOfSeal;
    vm.showOccupationSelect = showOccupationSelect;
    vm.clearTimeOfStay = clearTimeOfStay;
    vm.clearTransferKindOther = clearTransferKindOther;
    vm.clearTransferKind = clearTransferKind;
    vm.clearPrimaryCareOther = clearPrimaryCareOther;
    vm.currentDate = new Date();
    vm.getDateOfAccidentPlus14Days = getDateOfAccidentPlus14Days;
    vm.changeDateOfAccident = changeDateOfAccident;
    vm.dateOfAccidentPlus14Days = null;

    vm.occupationOptions = [];
    vm.occupationData = [];

    function init() {
        loadNewCase();
        fetchOccupations();
        PreloaderService.waitForDOM();
    }

    function fetchOccupations() {
        PreloaderService.showPreloader('occupationSelect');

        HttpService.get('/occupation').then(function (response) {
            vm.occupationData = response.data;
            flattenData(vm.occupationData);
            PreloaderService.hideParticularPreloader('occupationSelect');
        }).catch(function () {
            PreloaderService.hideParticularPreloader('occupationSelect');
        })
    }

    function flattenData(data) {
        data.forEach(code => {
            if (code.children && code.children.length > 0) {
                flattenData(code.children)
            } else {
                vm.occupationOptions.push({
                    name: code.title,
                    value: code.isco08code,
                })
            }
        })
    }


    function showOccupationSelect() {
        $mdDialog.show({
            multiple: true,
            templateUrl: 'shared/components/casesManagement/dialogs/occupation-select.html',
            controller: 'OccupationSelectController',
            controllerAs: 'occupationSelect',
            locals: {
                data: vm.occupationData
            }
        }).then(function (node) {
            selectOccupation(node);
        })
    }

    function selectOccupation(node) {
        vm.case.patientSection.profession = node.isco08code;
    }

    function loadNewCase() {
        vm.case = CaseService.createNewCase();
    }

    function nextStep() {
        PreloaderService.showPreloader('case-wizard');
        CaseService.alignPatientIDAndName(vm.case, vm.case.mcase.name);

        HttpService.head("/mCase/?caseCustomId=" + vm.case.mcase.name).then(function () {
            AlertService.renderDialogMessage("Diese klinikeigene Fall-ID ist in Ihrem Hause bereits in Verwendung!");
            PreloaderService.hidePreloader();
        }, function () {
            vm.step = 2;
            $scope.$broadcast("stepChange");
            PreloaderService.hideParticularPreloader('case-wizard');
        });
        CaseService.validateCase(vm.case);
    }

    function previousStep() {
        vm.step = 1;
        $scope.$broadcast("stepChange");
    }

    function closeWizard() {
        $location.path("/");
        DialogService.closeDialog();
    }

    function saveStructure() {
        var deferred = $q.defer();
        var valid = CaseService.validateCase(vm.case);
        if (!valid) {
            deferred.reject("Validation failed: ");
            return deferred.promise;
        }

        if (typeof vm.case.issues === 'undefined') {
            vm.case.issues = [];
        }
        if (vm.case.issues.indexOf(vm.case.section) === -1)
            vm.case.issues.push(vm.case.section);
        CaseService.saveCase(vm.case).then(function (res) {
            deferred.resolve(res);
        });
        return deferred.promise;
    }

    function setDateOfSeal(value) {
        vm.case.treatmentSection.dateOfSeal = value;
    }

    function createCase() {
        PreloaderService.showPreloader('case-wizard');
        saveStructure().then(function (savedCase) {
            DialogService.closeDialog();
            PreloaderService.hideParticularPreloader('case-wizard');
            $location.path("/entry/" + savedCase.mcase.id);
        }, function () {
            PreloaderService.hideParticularPreloader('case-wizard')
        })
    }

    function clearTimeOfStay(val) {
        vm.case.patientSection.timeOfStay = undefined;
    }

    function clearTransferKindOther(val) {
        vm.case.patientSection.transferKindOther = undefined;
    }

    function clearTransferKind(val) {
        vm.case.patientSection.transferKind = undefined;
        vm.case.patientSection.transferKindOther = undefined;
    }

    function clearPrimaryCareOther(val) {
        vm.case.patientSection.primaryCareOther = undefined;
    }

    function getDateOfAccidentPlus14Days() {
        if (vm.case.treatmentSection.dateOfAccident && vm.dateOfAccidentPlus14Days === null) {
            vm.dateOfAccidentPlus14Days = new Date(vm.case.treatmentSection.dateOfAccident);
            vm.dateOfAccidentPlus14Days.setDate(vm.dateOfAccidentPlus14Days.getDate() + 14);
        }
        return vm.dateOfAccidentPlus14Days;
    }

    function changeDateOfAccident(value) {
        vm.dateOfAccidentPlus14Days = new Date(value);
        vm.dateOfAccidentPlus14Days.setDate(vm.dateOfAccidentPlus14Days.getDate() + 14);
    }
});
