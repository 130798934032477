core_module.controller('SelectClassNameDialogController', function ($mdDialog, modifiedEntityNames) {
    var vm = this;
    vm.$onInit = init;
    vm.selectClassName = selectClassName;

    function init() {
        vm.modifiedEntityNames = modifiedEntityNames;
    }

    function selectClassName(className) {
        $mdDialog.hide(className);
    }
});
