core_module.component('coreTableTest', {
    controller: function ($scope) {
        $scope.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            add: true
        };

        $scope.query = {
            order: 'name',
            limit: 999999,
            page: 1
        };

        $scope.initObject = {
            deleted: false,
            headLine: '',
            newsDate: '',
            textNews: '',
            title: ''
        };

        $scope.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', displayName: 'ID'},
            {name: 'deleted', field: 'deleted', display: false, edit: true, type: 'boolean', displayName: 'Deleted'},
            {name: 'title', field: 'title', display: true, edit: true, type: 'text', displayName: 'Title'},
            {name: 'newsDate', field: 'newsDate', display: true, edit: true, type: 'date', displayName: 'news.date'},
            {name: 'textNews', field: 'textNews', display: true, edit: true, type: 'text', displayName: 'news'},
            {name: 'headLine', field: 'headLine', display: true, edit: true, type: 'boolean', displayName: 'Headline'}

        ];

        $scope.selRow = [];

    },
    templateUrl: 'shared/components/coreTableTest/coreTableTest.html'
});