core_module.controller('EditUserDialogController', function ($scope, $rootScope, $translate, $mdDialog, UsersService, DialogService, HttpService, PreloaderService, AlertService, AuthService, user) {

    $scope.user = angular.copy(user);
    $scope.data = {
        genders: [{'value': 'M', 'name': $translate.instant('salutation.male')}, {
            'value': 'F',
            'name': $translate.instant('salutation.female')
        }]
    };

    $scope.editUser = editUser;

    function editUser() {
        var currentUserId = AuthService.getCurrentUser().id;
        if (currentUserId === $scope.user.id) {
            editCurrentUser();
        } else {
            editOtherUser();
        }
    }

    function editOtherUser() {
        UsersService.updateUser($scope.user).then(function () {
                editUserSuccess()
            }, function () {
                editUserError();
            }
        );
    }

    function editCurrentUser() {
        UsersService.updateCurrentUser($scope.user).then(function (response) {
                setCurrentUser(response.data);
                editUserSuccess()
            }, function () {
                editUserError();
            }
        );
    }

    function editUserSuccess() {
        AlertService.showToast($translate.instant('userUpdatedSuccessfully'));
        $rootScope.$broadcast('refreshTable', {entity: 'users'});
        $mdDialog.cancel();
    }

    function editUserError() {
        AlertService.showToast($translate.instant('userUpdatedFailed'));
        $rootScope.$broadcast('refreshTable', {entity: 'users'});
        $mdDialog.cancel();
    }

    function setCurrentUser(user) {
        sessionStorage.setItem('currentUser', JSON.stringify(user));
    }
});
