core_module.factory('PdfService', function ($translate, $q, AuthService, CaseService) {
    var pdfService = {
        exportCase: exportCase
    };

    var pageWidth = 210;
    var pageHeight = 297;
    var pageCenter = pageWidth / 2;
    var pageMarginBase = 20;
    var pageMarginLeft = pageMarginBase;
    var pageMarginRight = pageWidth - pageMarginBase;
    var pageMarginBottom = pageHeight - pageMarginBase;
    var textSize = 11;
    var textOffset = 4.4;
    var subHeaderSize = 12;
    var subHeaderOffset = 6;
    var headerSize = 14;
    var headerOffset = 7;
    var sectionMargin = 4;
    var margin = 2;

    var pageNumber = 1;

    var excludedFields = ['sectionVersionCustomId', 'sectionVersionName'];
    var logo = null;

    function exportCase(patientCase) {
        var doc = new jsPDF({unit: 'mm'});

        loadImage('build/images/DGH_HTR_Logo_register_black.jpg').then(function (image) {
            logo = image;
        }).then(function () {
            pageNumber = 1;
            
            preparePage(doc, true);
            printCaseInfo(patientCase, doc);
            printPatientSections(patientCase, doc);
            printIssues(patientCase, doc);

            doc.save('case.pdf')
        });
    }

    function loadImage(src) {
        var deferred = $q.defer();
        var img = new Image();
        img.src = src;
        img.onload = function () {
            deferred.resolve(img);
        };

        return deferred.promise;
    }

    function preparePage(doc, firstPage) {
        printPageHeader(doc);

        if(firstPage) {
            doc.line(pageCenter, 45, pageCenter, pageMarginBottom);
        } else {
            doc.line(pageCenter, 30, pageCenter, pageMarginBottom);
        }

        printPageFooter(doc);
    }

    function insertPage(doc, firstPage) {
        pageNumber++;
        doc.addPage();
        preparePage(doc, firstPage);
    }

    function printPageHeader(doc) {
        var imageWidth = 45;
        var imageHeight = logo.height / logo.width * imageWidth;
        var currentDate = moment(new Date()).format('DD.MM.YYYY');
        var currentFacility = AuthService.getCurrentUserFacility().name;

        doc.setFont("times");
        doc.setFontType("normal");

        doc.addImage(logo, 'JPEG', 15, 0, imageWidth, imageHeight);
        doc.setFontSize(subHeaderSize);
        doc.text(pageMarginRight, 15, currentDate, null, null, 'right');
        doc.text(pageMarginRight, 20, currentFacility, null, null, 'right');

        doc.line(pageMarginLeft, 25, pageMarginRight, 25);
    }

    function printPageFooter(doc) {
        doc.text(pageCenter, pageMarginBottom + textOffset + margin, pageNumber.toString(), null, null, 'center');
    }

    function printCaseInfo(patientCase, doc) {
        doc.setFontSize(16);
        doc.text(pageMarginLeft, 40, 'Falleingabe zu Fall ' + patientCase.mcase.name);

        doc.setFontSize(14);
        doc.text(pageMarginLeft, 50, 'Verletzte Strukturen');
    }

    function printIssues(patientCase, doc) {
        var issues = patientCase.issues.filter(function (issue) {
            return CaseService.filterOutPatientAndTreatment(issue);
        });

        var index = 50 + headerOffset + margin;

        issues.forEach(function (issue, issueNumber) {
            var subHeader = (issueNumber + 1) + '. ' + issue.name;
            var subHeaderText = doc.splitTextToSize(subHeader, 75);

            if ((index + (subHeaderText.length * subHeaderOffset)) > pageMarginBottom) {
                insertPage(doc, false);
                index = 35;
            }

            doc.setFontSize(subHeaderSize);
            doc.text(pageMarginLeft, index, subHeaderText);
            doc.setFontSize(textSize);

            index += (subHeaderText.length * subHeaderOffset) + margin;

            var fields = patientCase.data[issue.identifier];

            Object.keys(fields).forEach(function (id) {
                if (!excludedFields.includes(id)) {
                    if (fields[id]) {
                        try {
                            var text = doc.splitTextToSize(fields[id], 40);

                            if ((index + text.length * textOffset) > pageMarginBottom) {
                                insertPage(doc, false);
                                index = 35;
                            }

                            doc.setFontStyle('italic');
                            doc.text(pageMarginLeft, index, $translate.instant(id));
                            doc.setFontStyle('normal');

                            doc.text(100, index, text, null, null, 'right');
                            index += (text.length * textOffset) + margin;
                            doc.line(pageMarginLeft, index - textOffset, 100, index - textOffset);
                        } catch(err) {
                            console.log('Error parsing', err);
                        }
                    }
                }
            });
            index += sectionMargin;
        });
    }

    function printPatientSections(patientCase, doc) {
        var patientSections = patientCase.issues.filter(function (issue) {
            return !CaseService.filterOutPatientAndTreatment(issue);
        });

        var index = 50;

        patientSections.forEach(function (issue) {

            doc.setFontSize(headerSize);
            doc.text(110, index, issue.name);
            doc.setFontSize(textSize);

            index += headerOffset + margin;

            var fields = patientCase.data[issue.identifier];

            Object.keys(fields).forEach(function (id) {
                if (!excludedFields.includes(id) && fields[id]) {
                    var translatedId = $translate.instant(id);
                    var type = checkType(id, issue);

                    doc.setFontStyle('italic');
                    doc.text(110, index, translatedId);
                    doc.setFontStyle('normal');

                    switch (type) {
                        case 'DATE':
                            doc.text(pageMarginRight, index, moment(fields[id]).format('DD.MM.YYYY'), null, null, 'right');
                            index += textOffset + margin;
                            break;
                        case 'TIME':
                            doc.text(pageMarginRight, index, moment(fields[id]).format('HH:mm'), null, null, 'right');
                            index += textOffset + margin;
                            break;
                        case 'NUMBER':
                            doc.text(pageMarginRight, index, fields[id].toString(), null, null, 'right');
                            index += textOffset + margin;
                            break;
                        default:
                            var text = doc.splitTextToSize(fields[id], 40);
                            doc.text(pageMarginRight, index, text, null, null, 'right');
                            index += (text.length * textOffset) + margin;
                            break;
                    }
                    doc.line(110, index - textOffset, pageMarginRight, index - textOffset);
                }
            });
            index += sectionMargin;
        });
    }

    function checkType(entryId, issue) {
        var type;

        var entry = issue.entries.find(function (entry) {
            return entry.customId === entryId;
        });

        if (angular.isUndefined(entry)) {
            type = entryId.includes('time') ? 'TIME' : entryId.includes('date') ? 'DATE' : 'STRING';
        } else {
            type = entry.ct;
        }
        return type;
    }

    return pdfService;
});