core_module.component('casesManagement', {

    controller: function ($rootScope, $scope, $location, $mdDialog, $translate, $timeout, PreloaderService, CaseService, AuthService, moment, HttpService) {

        var casesManagement = this;

        $scope.mcases = [];

        var statuses = [
            {value: 'OPEN', name: $translate.instant('case.openCases')},
            {value: 'CLOSED', name: $translate.instant('case.closedCases')}
        ];


        $scope.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: false,
            add: true,
            toolbar: {addLabel: 'Neuer Fall'},
            customActions: [
                {
                    isActiveCondition: isCaseEditable,
                    name: 'editCaseID',
                    icon: 'edit',
                    clickFunction: editCaseID
                },
                {
                    isActiveCondition: isCaseEditable,
                    name: 'delete',
                    icon: 'delete',
                    clickFunction: deleteCase,
                },
                {
                    isActiveCondition: isCaseEditable,
                    name: 'edit',
                    icon: 'content_paste',
                    clickFunction: editCase
                },
                {

                    isActiveCondition: isClosedCase,
                    name: 'sheet.open',
                    icon: 'lock_open',
                    clickFunction: openCase
                }, {

                    isActiveCondition: isOpenCase,
                    name: 'sheet.close',
                    icon: 'lock',
                    clickFunction: closeCase
                }]
        };

        $scope.columnDefs = [
            {
                name: 'id',
                field: 'id',
                display: AuthService.isAdmin(),
                edit: false,
                type: 'number',
                link: false,
                displayName: 'id',
                filter: true
            },
            {
                name: 'deleted',
                field: 'deleted',
                display: false,
                edit: false,
                type: 'boolean',
                link: false,
                displayName: 'deleted',
                filter: false
            },
            {
                name: 'name',
                field: 'name',
                display: true,
                edit: true,
                type: 'text',
                link: false,
                displayName: 'case.mcase.name',
                filter: true
            },
            {
                name: 'status',
                field: 'status',
                display: true,
                edit: false,
                type: 'select',
                selectOptions: statuses,
                link: false,
                filter: true
            },
            {
                name: 'dateCreated',
                field: 'dateCreated',
                display: true,
                edit: false,
                type: 'date',
                link: false,
                filter: true
            },
            {
                name: 'createdByFacility',
                field: 'createdByFacility',
                displayName: 'createdBy',
                display: AuthService.isAdmin(),
                edit: false,
                type: 'text',
                filter: AuthService.isAdmin()
            },
            {
                name: 'dateOfAdmission',
                field: 'dateOfAdmission',
                displayName: 'dateOfAdmission',
                display: true,
                edit: false,
                type: 'date',
                filter: true

            },
            {
                name: 'dateOfAccident',
                field: 'dateOfAccident',
                displayName: 'dateOfAccident',
                display: true,
                edit: false,
                type: 'date',
                filter: true

            }
        ];

        $scope.initObject = {
            id: null,
            deleted: false,
            patentId: '',
            dateCreated: null,
            createdByFacility: null,
            dateOfAdmission: null


        };

        $scope.name = "CASES";

        $scope.case = CaseService;
        $scope.query = {
            order: 'id',
            limit: 10,
            page: 1
        };
        $scope.templateQuery = {
            order: 'id',
            limit: 10,
            page: 1
        };

        $scope.queryTemplateTypes = {
            order: 'id',
            limit: 10,
            page: 1
        };

        $scope.toggleWindow = function (card) {
            $scope.display[card] = !$scope.display[card];
        };

        $scope.calculateAge = function calculateAge(birthday) { // birthday is a date
            var ageDifMs = Date.now() - birthday.getTime();
            var ageDate = new Date(ageDifMs);
            return Math.abs(ageDate.getUTCFullYear() - 1970);
        };

        function deleteCase(mcase) {

            var confirm = $mdDialog.confirm({
                onComplete: function afterShowAnimation() {
                    var $dialog = angular.element(document.querySelector('md-dialog'));
                    var $actionsSection = $dialog.find('md-dialog-actions');
                    var $cancelButton = $actionsSection.children()[0];
                    var $confirmButton = $actionsSection.children()[1];
                    angular.element($confirmButton).removeClass('md-focused');
                    angular.element($cancelButton).addClass('md-focused');
                    $cancelButton.focus();
                }
            })
                .title($translate.instant('delete'))
                .textContent($translate.instant('core.delete_entry'))
                .ok('OK')
                .cancel($translate.instant('cancel'));

            $mdDialog.show(confirm).then(function () {

                CaseService.deleteCase(mcase);

            });

        };

        function editCase(mcase) {
            $location.path("/entry/" + mcase.id);
        }

        $scope.createCase = function () {
            if (CaseService.hasTemplate()) {
                var confirm = $mdDialog.confirm({
                    templateUrl: 'shared/components/casesManagement/dialogs/privacyStatement.html',
                    controller: 'PrivacyController',
                    controllerAs: 'privacyDialog',
                    parent: angular.element(document.body)
                });
                $mdDialog.show(confirm).then(function () {
                    CaseService.createCaseModal();
                });
            } else {
                var alert = $mdDialog.alert()
                    .title()
                    .content($translate.instant('noTemplate'))
                    .ok('ok');
                $mdDialog.show(alert)
            }
        };

        function closeCase(mcase) {
            CaseService.load(mcase.id).then(function (caseObject) {
                CaseService.closeCaseModal(caseObject);
            });
        }

        function openCase(mcase) {
            CaseService.load(mcase.id).then(function (caseObject) {
                CaseService.openCase(caseObject).then(function (caseObject) {
                    $rootScope.$broadcast('refreshTable', {entity: 'mCase'});
                    $location.path("/entry/" + mcase.id);
                })
            });
        }

        function isOpenCase(mcase) {
            return mcase.status === "OPEN" && AuthService.hasPermissionOrIsAdmin('ROLE_P_MCASE_CLOSE');
        }

        function isCaseEditable(mcase) {
            return mcase.status === "OPEN" && AuthService.hasPermissionOrIsAdmin('ROLE_P_MCASE_UPDATE');
        }

        function isClosedCase(mcase) {
            return mcase.status === "CLOSED" && AuthService.hasPermissionOrIsAdmin('ROLE_P_MCASE_REOPEN');
        }

        function hasDeletePermissions() {
            return AuthService.hasPermissionOrIsAdmin('ROLE_P_MCASE_DELETE');
        }

        $timeout = function () {
            PreloaderService.hidePreloader("pagePreloader");
        }

        function editCaseID(mcase) {
            var confirm = $mdDialog.prompt()
                .title($translate.instant('edit'))
                .textContent($translate.instant('caseId'))
                .placeholder($translate.instant('caseId'))
                .ariaLabel('CaseID')
                .initialValue(mcase.caseCustomId)
                .required(true)
                .ok($translate.instant('ok'))
                .cancel($translate.instant('cancel'));

            $mdDialog.show(confirm).then(function (result) {
                updateCaseId(result, mcase);
            });
        }

        function updateCaseId(result, mcase) {
            var copy = angular.copy(mcase);
            copy.caseCustomId = result;
            copy.name = result;
            copy.patientId = result;
            copy.caseFile.patientId = result;
            HttpService.put("/mCase/" + copy.id, copy).then(function (response) {
                $rootScope.$broadcast('refreshTable', {entity: 'mCase'});
                delete copy;

            }, function (response) {
                AlertService.renderErrorMessage(response.data.details.cause, response.data.details, response.status);
                $rootScope.$broadcast('refreshTable', {entity: 'mCase'});
                delete copy;
            });


        }


    },

    templateUrl:
        'shared/components/casesManagement/cases.management.html',

});