core_module.controller('UserSignInDialogController', function ($rootScope, $translate, AuthService, AlertService, HttpService, DialogService, PreloaderService, CaptchaService) {
    var vm = this;

    vm.signIn = signIn;
    vm.passwordRecovery = passwordRecovery;
    vm.refreshCaptcha = refreshCaptcha;

    vm.alert = null;
    vm.displayAlert = false;
    vm.attemptsAlert = null;
    vm.displayAttemptsAlert = false;
    vm.displayCaptcha = false;
    vm.captchaImg = null;
    vm.captchaId = null;

    function signIn() {
        PreloaderService.showPreloader('userDialog');

        var params = {
            username: vm.username,
            password: vm.password
        };

        if (vm.displayCaptcha) {
            params.captchaId = vm.captchaId;
            params.captchaAnswer = vm.captcha;
        }

        vm.displayAttemptsAlert = false;
        vm.displayCaptcha = false;
        vm.displayAlert = false;

        AuthService.signIn(params).then(
            function () {
                PreloaderService.hideParticularPreloader('userDialog');
                AlertService.showToast('user.logged_in_succesfully');
                $rootScope.$broadcast('changeUserState', AuthService.isSignedIn());
                AuthService.setFacilityParameter();
                DialogService.hideDialog();
            },
            function (response) {
                var details = response.data.details;
                PreloaderService.hideParticularPreloader('userDialog');

                if (details.hasOwnProperty('captchaImg')) {
                    vm.captchaSrc = "data:image/png;base64," + details.captchaImg;
                    vm.captchaId = details.captchaId;
                    vm.displayCaptcha = true;
                }

                vm.displayAlert = true;
                vm.alert = $translate.instant(details.key,  {attemptsLeft: details.attemptsLeft});

                vm.captcha = null;
            }
        );
    }

    function passwordRecovery() {
        DialogService.hideDialog();
        DialogService.passwordRecovery();
    }

    function refreshCaptcha() {
        PreloaderService.showPreloader('userDialog');
        CaptchaService.refreshCaptcha(vm.captchaId).then(function (captcha) {
            vm.captchaId = captcha.captchaId;
            vm.captchaSrc = captcha.captchaSrc;
            PreloaderService.hideParticularPreloader('userDialog');
        })
    }
});
