core_module.config(function($provide) {
   $provide.decorator('mdSliderContainerDirective', function($delegate) {
       var directive = $delegate[0];
       directive.compile = function() {
           return function postLink(scope, element, attr, ctrl) {
               var initialMaxWidth;
               ctrl.fitInputWidthToTextLength = function (length) {
                   var input = element[0].querySelector('md-input-container');

                   if (input) {
                       var computedStyle = getComputedStyle(input);
                       var minWidth = parseInt(computedStyle.minWidth);
                       var padding = parseInt((!computedStyle.padding) ? computedStyle.getPropertyValue('padding-left') : computedStyle.padding) * 2;

                       initialMaxWidth = initialMaxWidth || parseInt(computedStyle.maxWidth);
                       var newMaxWidth = Math.max(initialMaxWidth, minWidth + padding + (minWidth / 2 * length));

                       input.style.maxWidth = newMaxWidth + 'px';
                   }
               };
           }
       };
       return $delegate;
   }) ;
});