core_module.controller('AddPeriodicReportController', function ($rootScope, DialogService, AuthService, AlertService, PreloaderService, Upload, CONFIG) {
    var vm = this;

    vm.$onInit = init;
    vm.sendFile = sendFile;

    vm.currentFacility = {};
    vm.file = null;
    vm.reportName = "";

    function init() {
        PreloaderService.particularWaitForDOM('coreDialog');
        if (isCurrentFacility()) {
            vm.currentFacility = AuthService.getCurrentUserFacility();
        } else {
            AlertService.renderErrorMessage("Select Facility.");
            DialogService.cancelDialog();
        }
    }

    function isCurrentFacility() {
        return (typeof AuthService.getCurrentUserFacility() !== 'undefined');
    }

    function sendFile() {
        if (vm.file) {
            PreloaderService.showPreloader('coreDialog');
            upload(vm.file);
        }
    }

    function upload(file) {
        Upload.upload({
            url: CONFIG.REST_BASE_URL + '/periodicreport/createPeriodicReport',
            data: {reportName: vm.reportName},
            file: file
        }).then(function () {
            AlertService.showToast('fileUploadSuccessfully');
            delete vm.file;
            $rootScope.$broadcast('refreshTable', {entity: "periodicreport"});
            PreloaderService.hideParticularPreloader('coreDialog');
            DialogService.hideDialog();
        }, function () {
            AlertService.renderErrorMessage('Insert new Report failed.');
            PreloaderService.hideParticularPreloader('coreDialog');
        });
    }
});