core_module.component('permissionsManagement', {
    bindings: {
        parentId: '<?',
        roleName: '<?'
    },
    controller: function ($rootScope, $scope, $route, $mdDialog, $translate, HttpService, AlertService) {
        var self = this;

        processBindings();

        this.$onChanges = function (changes) {
            if (changes.hasOwnProperty('parentId')) {
                processBindings()
            }
        };

        function processBindings() {
            $scope.roleId = (self.parentId) ? self.parentId : null;
            $scope.roleName = (self.roleName) ? self.roleName : null;
        }

        $scope.getPermissionsUrl = function () {
            if ($scope.roleId) {
                return 'rolepermissions/' + $scope.roleId;
            }
        };

        $scope.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: true,
            add: true
        };

        $scope.query = {
            order: 'permission.name',
            limit: 10,
            page: 1
        };

        $scope.permissions = [];

        $scope.initObject = {
            id: null,
            name: null
        };


        $scope.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false},
            {name: 'permission.name', field: 'name', display: true, edit: true, type: 'text', link: false}
        ];

        $scope.addPermission = function () {
            $mdDialog.show({
                templateUrl: 'shared/components/permissionsManagement/dialogs/add.rolePermission.html',
                controller: 'RolePermissionDialogsController',
                fullscreen: true,
                locals: {
                    roleId: $scope.roleId,
                    roleName: $scope.roleName,
                    permissions: $scope.permissions
                }
            });
        };


        $scope.removepermission = function (row) {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('confirm_changes'))
                .textContent($translate.instant('confirm_changes'))
                .ok($translate.instant('delete'))
                .cancel($translate.instant('cancel'));
            $mdDialog.show(confirm).then(function () {
                var showDeleteSuccess = function () {
                    AlertService.showToast($translate.instant($scope.entity + ' deleted successfully'));
                    $rootScope.$broadcast('refreshTable', {entity: 'rolepermissions'});
                };
                var showErrorDelete = function () {
                    AlertService.renderErrorMessage('delete failed');
                };

                HttpService.shortDelete('/rolepermissions?roleId=' + $scope.roleId + '&permissionId=' + row.id, showDeleteSuccess, showErrorDelete);

            });
        };

    },
    templateUrl: 'shared/components/permissionsManagement/permissionsManagement.html'
});