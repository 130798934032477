core_module.factory('NewsService', function (HttpService, CONFIG) {

    function getAllNews(language) {
        if (!language)
            return HttpService.get('/news?sort=id'
                + "&size=" + CONFIG.MAX_PAGE_SIZE + "&page=1");
        else
            return HttpService.get('/news?language=' + language + '&sort=id'
                + "&size=" + CONFIG.MAX_PAGE_SIZE + "&page=1");

    }

    function loadNews(id) {
        return HttpService.get('/news/' + id);
    }

    function putNews(news) {
        return HttpService.put('/news/', news);
    }

    function postNews(news) {
        return HttpService.post('/news/', news);
    }

    function deleteNews(news) {
        return HttpService.delete('/news/' + news.id);
    }

    return {
        getAllNews: getAllNews,
        loadNews: loadNews,
        putNews: putNews,
        deleteNews: deleteNews,
        postNews: postNews
    }

});
