core_module.component('registryForm', {

    bindings: {
        model: '=?',
        document: '=?',
        validate: '<'
    },

    controller: function ($scope, $timeout, $http) {

        var registryForm = this;
        var currentSection = null;
        $scope.invalidSections = [];
        this.$onChanges = function (changes) {
            if (currentSection) {
                if (changes.hasOwnProperty('validate')) {
                    if (changes.validate.currentValue !== null) {
                        prepareValidation();
                        setInvalidFields(currentSection);
                    }
                }
            }
        };
        function showValidation(section) {
            setInvalidFields(section);
        }

        function setInvalidSections() {
            if (angular.isDefined($scope.validation)) {
                var fields = $scope.validation.fields;

                for (var i = 0; i < fields.length; i++) {
                    if ($scope.invalidSections.indexOf(fields[i].sectionName) === -1)
                        $scope.invalidSections.push(fields[i].sectionName);
                }
            }
        }

        $scope.isSectionInvalid = function (customId) {
            return $scope.invalidSections.indexOf(customId) > -1;
        };

        function setInvalidFields(customId) {
            var section = $scope.registryForm[customId];
            section.$$controls.forEach(function (field) {
                if(field.$$controls.length > 1){
                    field.$$controls.forEach(function (subfield) {
                        var validationResult = checkIfFieldHasError(subfield.$name);
                        if (validationResult !== false) {
                            if (validationResult.errors.length) {
                                subfield.$$controls[0].$setValidity('customError', false);
                                subfield.$$controls[0].$setDirty();
                                subfield.$$controls[0].$setTouched();
                            }
                            subfield.customErrors = validationResult.errors;
                            subfield.customWarnings = validationResult.warnings;
                        }
                    })
                }


                var validationResult = checkIfFieldHasError(field.$name);
                if (validationResult !== false) {
                    if (validationResult.errors.length) {
                        field.$$controls[0].$setValidity('customError', false);
                        field.$$controls[0].$setDirty();
                        field.$$controls[0].$setTouched();
                    }
                    field.customErrors = validationResult.errors;
                    field.customWarnings = validationResult.warnings;
                }
            });
        }

        function checkIfFieldHasError(fieldName) {

            var messages = {errors: [], warnings: []};
            var fields = $scope.validation.fields;
            for (var i = 0; i < fields.length; i++) {
                if (fields[i].fieldName === fieldName) {
                    if (fields[i].errors !== null) fields[i].errors.forEach(function (error) {
                        messages.errors.push(error);
                    });
                    if (fields[i].warnings !== null) fields[i].warnings.forEach(function (warning) {
                        messages.warnings.push(warning);
                    });
                    return messages;
                }
            }
            return false;
        }
        function init() {
            prepareScope();
            prepareModel();
            prepareDocument();
            prepareSections();
            prepareValidation();
        }

        function prepareScope() {
            $scope.sectionIds = [];
            $scope.section = {};
        }

        function prepareModel() {
            $scope.model = registryForm.model;
            if (!$scope.model) {
                $scope.model = {};
            }
        }

        function prepareValidation() {
            if (angular.isUndefined(registryForm.validate) || registryForm.validate !== null) {
                $scope.validation = registryForm.validate;
                $scope.invalidSections = [];
                setInvalidSections();
            }
        }

        function prepareDocument() {
            $scope.document = registryForm.document;
        }

        function prepareSections() {
            if (isDocumentAvailable()) {
                $scope.sections = getLeafSectionsFromDocument($scope.document);
                sortSections();
                setCurrentSection();
            } else {
                $scope.sections = [];
            }
        }

        function isDocumentAvailable() {
            return $scope.document;
        }

        function sortSections() {
            if ($scope.sections.sort) {
                $scope.sections.sort(function (a, b) {
                    return a.orderKey - b.orderKey
                });
                $scope.sectionIds.sort(function (a, b) {
                    return a.key - b.key
                });
            }
        }

        function setCurrentSection() {
            currentSection = $scope.sections[0];
        }

        function getLeafSectionsFromDocument(section, leafSections) {
            if (leafSections) {
                if (leafSections.length > 0)
                    leafSections.push(leafSections);
            } else
                leafSections = [];
            for (var i = 0; i < section.children.length; i++) {
                if (section.children[i].entries && section.children[i].entries.length > 0) {
                    leafSections.push(section.children[i]);
                    $scope.sectionIds.push({label: section.children[i].customId, key: section.children[i].orderKey});
                }
                else
                    return getLeafSectionsFromDocument(section.children[0], leafSections);
            }
            return leafSections;
        }

        $scope.select = function (section) {
            currentSection = section;
            if (angular.isDefined($scope.validation)) {
                var waitToComplete = function () {
                    if ($http.pendingRequests.length > 0) {
                        $timeout(waitToComplete);
                    } else {
                        showValidation(section);
                    }
                };
                $timeout(waitToComplete);
            }
        };


        $scope.isSelected = function (section) {
            return currentSection === section;
        };

        this.$onInit = function () {
            init();
        }
    },
    templateUrl: 'shared/components/registryForm/registryForm.html'

});