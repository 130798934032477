core_module.directive('htrPasswordInput',
    function () {
        return {
            restrict: 'AE',
            scope: {
                password: '=',
                input_type: '@'
            },
            templateUrl: 'shared/directives/htr-password-input.html',
            link: function (scope, element, attrs, ctrl) {
                scope.input_type = 'password';
                scope.toggle_password_type = function () {
                    if (scope.input_type === 'password') {
                        scope.input_type = 'text'
                    } else {
                        scope.input_type = 'password'
                    }
                }
            }
        }
    }
);
