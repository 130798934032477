core_module.controller('SectionTemplateExportCsvFieldsGroupController', function ($scope, $rootScope, $translate, $q,
                    $mdDialog, HttpService, AlertService, EntityService, DialogService, parentId) {
    var promisses = [];
    $scope.parentId = parentId;
    $scope.sectionTemplateId = undefined;

    $scope.cancel = function () {
        $mdDialog.cancel();
    };

    init();

    function init() {
        var csvfieldgrouptypesUrl = 'sectiontemplates/allFirstLevelSectionTemplates/';
        // parentId - exportCsvFieldsGroups ID.
        promisses[0] = EntityService.getEntries(csvfieldgrouptypesUrl + $scope.parentId);

        $q.all(promisses).then(function (dataArray) {
            var j, dd,tmpArray = [];
            var selectOption = {};
            var lSectiontemplatesOptions = [];

            if (dataArray[0].data) { // Sectiontemplates objects
                tmpArray = dataArray[0].data;
                for (j=0; j < tmpArray.length; j++) {
                    selectOption = {};
                    selectOption.value = tmpArray[j].id;
                    selectOption.name = tmpArray[j].id + "; " + tmpArray[j].name;
                    $scope.sectiontemplatesOptions.push(selectOption);
                }
            }
        }, function (response) {
            AlertService.renderErrorMessage(response.data.details.cause, response.data.details, response.status);
        });
    }

    $scope.addConfirm = function () {
        var url = "/sectiontemplates/addSectionTemplate?exportCsvFieldsGroupId=" + $scope.parentId + "&sectionTemplateId="
            + $scope.sectionTemplateId;

        HttpService.post(url).then(function (response) {
            $rootScope.$broadcast('refreshTable', {entity: 'sectiontemplates'});
        }, function (response) {
            console.log("failure", response);
        });

        DialogService.closeDialog();
    };
});