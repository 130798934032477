/**
 * Created by Thorsten.Wirth on 09.01.2017.
 */
core_module.component('unit', {
    controller: function ($scope, $mdDialog) {
        $scope.units = {};

        $scope.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            add: true,
        };
        $scope.query = {
            order: 'name',
            limit: 10,
            page: 1
        };

        $scope.initObject = {

        };

        $scope.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false},
            {name: 'name', field: 'name', display: true, edit: true, type: 'text', link: false},
        ];


        $scope.selRowUser = [];



    },
    templateUrl: 'shared/components/unit/unit.html'
});

