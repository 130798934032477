core_module.controller('PrivacyController', function (DialogService) {
    var vm = this;

    vm.privacyOptions = [
        {'value': 'yes', 'name': 'liegt vor'}, {
            'value': 'not',
            'name': 'liegt nicht vor'
        }, {
            'value': 'dead',
            'name': 'Patient verstorben'
        }
    ];

    vm.privacyConfirm = 'not';

    vm.rightSelection = rightSelection;
    vm.hide = hide;

    function rightSelection() {
        return vm.privacyConfirm === 'dead' || vm.privacyConfirm === 'yes'
    }

    function hide() {
        DialogService.hideDialog();
    }
});
