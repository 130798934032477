core_module.component('rolePermissionsManagement', {

    controller: function ($scope, $mdDialog, $translate, AuthService) {
        $scope.isChild = false;

        $scope.$on('fromParentTableEvent', function (event, data) {
            data.title = null;
            $scope.parentId = data.parentEntity.id;
            $scope.roleName = data.parentEntity.name;
            $scope.isChild = true;
        });

        $scope.isAdmin = AuthService.isAdmin;
    },
    templateUrl: 'shared/components/rolePermissionsManagement/rolePermissionsManagement.html'
});