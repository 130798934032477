core_module.component('facilitySelector', {
    controller: function ($mdDialog, $q, $scope, HttpService, AuthService) {
        var vm = this;

        vm.displayFacilitySelector = false;

        vm.selectFacility = selectFacility;
        vm.$onInit = init;

        function init() {
            if (AuthService.isSignedIn() && !AuthService.hasRoleByName('ROLE_ADMIN')) {
                getFacilitiesToSelect().then(function (facilitiesToSelect) {
                    if (facilitiesToSelect.length < 2) {
                        vm.displayFacilitySelector = false;
                    } else {
                        vm.displayFacilitySelector = true;
                        vm.facilitiesToSelect = facilitiesToSelect;
                    }
                }, function () {
                    vm.displayFacilitySelector = false;
                });
            } else {
                vm.displayFacilitySelector = false;
                vm.facilitiesToSelect = [];
            }
        }

        function getFacilitiesToSelect() {
            var deferred = $q.defer();
            HttpService.get("/currentUser/currentfacilityselection").then(function (response) {
                var facilitiesToSelect = response.data.content;
                deferred.resolve(facilitiesToSelect);
            }, function () {
                deferred.reject();
            });
            return deferred.promise;
        }

        function selectFacility() {
            $mdDialog.show({
                templateUrl: 'shared/components/facilitySelector/dialogs/select.facility.html',
                controller: 'SelectFacilityDialogController',
                controllerAs: 'selectFacilityDialog',
                fullscreen: true,
                locals: {
                    facilities: vm.facilitiesToSelect,
                }
            });
        }

        $scope.$on('changeUserState', function () {
            init();
        });
    },
    controllerAs: 'facilitySelector',
    templateUrl: 'shared/components/facilitySelector/facilitySelector.html'
});