core_module.directive('clickableSvg', ['$compile', function ($compile) {
        return {
            restrict: 'E',
            scope: {
                svgSelected:"=",
                svgDetailWindowOpen:"=",
                svgClick:"=",
                structure:'='
            },
            link: function (scope, element) {
                var regions = element[0].querySelectorAll('.state');
                angular.forEach(regions, function (path) {
                    var regionElement = angular.element(path);
                    regionElement.attr('svg-region', '');
                    regionElement.attr('hoverRegion', 'noHover');
                    $compile(regionElement)(scope);
                });
            },

        }
    }]
);
