core_module.component('facilityDependManagement', {

    controller: function ($scope) {
        var vm = this;

        vm.$onInit = resetChildDisplay;

        $scope.$on('hideChildTable', function(event, data) {
            if(data.entity === 'facility') resetChildDisplay();
        });

        $scope.$on('fromParentToMedical', function (event, data) {
            setChildData(data);
            displayChild('medicalChild');
        });

        $scope.$on('fromParentToAddresses', function (event, data) {
            setChildData(data);
            displayChild('addressChild');
        });

        $scope.$on('fromParentToConnector', function (event, data) {
            setChildData(data);
            displayChild('facilityChild');
        });

        $scope.$on('fromParentToParameter', function (event, data) {
            setChildData(data);
            displayChild('facilityParameters');
        });

        function displayChild(child) {
            resetChildDisplay();
            vm[child] = true;
        }

        function resetChildDisplay() {
            vm.addressChild = false;
            vm.medicalChild = false;
            vm.facilityChild = false;
            vm.facilityParameters = false;
        }

        function setChildData(data) {
            vm.parentId = data.parentEntity.id;
            vm.facilityName = data.parentEntity.name;
        }

    },
    templateUrl: 'shared/components/facilityDependManagement/facilityDependManagement.html',
    controllerAs: 'facilityDepend'
});