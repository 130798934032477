core_module.controller('PasswordChangeController', function ($rootScope, $location, $routeParams, $scope, $translate, UsersService, HttpService, AuthService, AlertService, $mdDialog, DialogService, PreloaderService, CaptchaService) {
    var vm = this;
    vm.sendPasswordChangeRequest = sendPasswordChangeRequest;
    vm.refreshCaptcha = refreshCaptcha;
    vm.close = close;
    vm.captchaId = null;
    vm.captchaSrc=null;
    vm.$onInit = init;
    vm.displayAlert=false;

    function init() {
        refreshCaptcha();
    }

    function sendPasswordChangeRequest() {
        PreloaderService.showPreloader('userDialog');
        HttpService.post('/passwords/' + $routeParams.code, {
                newPassword: vm.newPassword,
                passwordRepeat: vm.passwordRepeat,
                captchaId: vm.captchaId,
                captchaAnswer: vm.captcha
            }
        ).then(function (response) {
            PreloaderService.hidePreloader();
            AlertService.showToast('user.password_changed');
            close()
        }, function (response) {
            vm.captcha = "";
            refreshCaptcha()
            vm.displayAlert=true;
            if (response.data.details) {
                vm.alert =response.data.details.cause;
            }else{
                vm.alert = "error.unexpected"
            }
            PreloaderService.hidePreloader();
        })
    }
    function refreshCaptcha(){
        PreloaderService.showPreloader('userDialog');
        CaptchaService.refreshCaptcha(vm.captchaId).then(function (captcha) {
            vm.captchaId = captcha.captchaId;
            vm.captchaSrc = captcha.captchaSrc;
            PreloaderService.hideParticularPreloader('userDialog');
        })
    }
    function close() {
        DialogService.cancelDialog();;
        $location.path("/")
    }
});
