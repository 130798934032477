/**
 * Created by Thorsten.Wirth on 11.04.2017.
 */
core_module.controller('FacilityAddressController', function ($rootScope, $q, EntityService, AlertService, DialogService, facilityId, facilityAddress, initObject) {
    var vm = this;
    var isNewEntry = false;
    var entity = 'facilityAddress';

    vm.title = 'add_facilityAddress';
    vm.regex = '//^\d+$/';

    vm.$onInit = init;
    vm.confirm = confirm;

    function init() {
        getAddressTypesAndCountries();
        initDialogData();
    }

    function getAddressTypesAndCountries() {
        var promises = [
            EntityService.getEntries("addressType", {limit: -1}),
            EntityService.getEntries("country", {limit: -1})
        ];

        $q.all(promises).then(function (response) {
            vm.addressTypes = response[0].data.content.map(function (item) {
                return {name: item.name, value: item.id}
            });
            vm.countries = response[1].data.content.map(function (item) {
                return {name: item.countryCode, value: item.id}
            });
        });
    }

    function initDialogData() {
        if (facilityAddress) {
            vm.facilityAddress = angular.copy(facilityAddress);
            vm.title = 'edit_facilityAddress';
        }
        else {
            vm.facilityAddress = angular.copy(initObject);
            isNewEntry = true;
        }
    }

    function confirm() {
        if (isNewEntry) {
            createAddress();
        }
        else updateAddress();
    }

    function createAddress() {
        var facilityAddress = getAddress();

        EntityService.addEntry(entity, facilityAddress).then(function () {
            AlertService.showToast('facilityAddress create successfully');
            $rootScope.$broadcast("refreshTable", {entity: entity});
            DialogService.hideDialog();
        });
    }

    function updateAddress() {
        var facilityAddress = getAddress();
        facilityAddress.id = vm.facilityAddress.id;

        EntityService.updateEntry(entity, facilityAddress).then(function () {
            AlertService.showToast(entity + ' update successfully');
            $rootScope.$broadcast("refreshTable", {entity: entity});
            DialogService.hideDialog();
        });
    }

    function getAddress() {
        return {
            facility_id: facilityId,
            addressType_id: vm.facilityAddress.addressType_id,
            address: vm.facilityAddress.address
        }
    }
});