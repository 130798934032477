/**
 * Created by Thorsten.Wirth on 14.09.2016.
 */

core_module.controller('SchemeSectionController', function ($scope, $mdDialog, $translate, DialogService, node, doc) {
        var vm = this;

        vm.$onInit = init;
        vm.confirm = confirm;
        vm.appendToEditor = appendToEditor;

        vm.doc = doc;

        function init() {
            vm.node = angular.copy(node);
        }

        function confirm() {
            if (customIdChanged() && checkIfExists(doc.children)) {
                showAlertDialog();
            }
            else {
                if ($scope.sectionForm.$dirty) vm.node.modified = true;
                angular.copy(vm.node, node);
                DialogService.hideDialog();
            }
        }

        function appendToEditor(value) {
            vm.node.viewCondition = vm.node.viewCondition ? vm.node.viewCondition += 'model.' + value : 'model.' + value;
        }

        function customIdChanged() {
            return node.customId !== vm.node.customId
        }

        function checkIfExists(children) {
            var duplicatedCustomId = false;
            children.forEach(function (child) {
                if (child.customId === vm.node.customId) duplicatedCustomId = true;

                if (child.children && child.children.length) {
                    return checkIfExists(child.children);
                }
            });
            return duplicatedCustomId;
        }

        function showAlertDialog() {
            var alert = $mdDialog.alert()
                .title()
                .content($translate.instant('scheme.customIdExists'))
                .ok('ok');

            $mdDialog
                .show(alert)
                .finally(function () {
                    alert = undefined;
                });
        }
    }
);