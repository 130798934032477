core_module.component('caseNumbers', {
    controller: function ($scope, $q, HttpService, AuthService, $translate, $rootScope, PreloaderService) {
        var vm = this;
        var yearsToFetch = 7;

        var currentView = '';

        vm.statistics = [];
        vm.overallStatistics = {};
        vm.displayAsList = true;
        vm.displayAsColumns = false;

        vm.$onInit = init;
        vm.setView = setView;
        vm.isViewActive = isViewActive;

        vm.columnDefs = [
            {
                name: 'title',
                field: 'title',
                display: true,
                edit: false,
                type: 'text',
                filter: true
            },
            {
                name: 'statistics.closed_cases',
                field: 'closedCases',
                display: true,
                edit: false,
                type: 'text',
                filter: true
            },
            {
                name: 'statistics.open_cases',
                field: 'openCases',
                display: true,
                edit: false,
                type: 'text',
                filter: true
            },
            {
                name: 'statistics.parcticipants.entering',
                field: 'participants',
                display: true,
                edit: false,
                type: 'text',
                filter: true
            },
            {
                name: 'statistics.minMax',
                field: 'minMax',
                display: true,
                edit: false,
                type: 'text',
                filter: true
            },
            {
                name: 'statistics.median',
                field: 'median',
                display: true,
                edit: false,
                type: 'text',
                filter: true
            },
            {
                name: 'statistics.avg',
                field: 'avg',
                display: true,
                edit: false,
                type: 'text',
                filter: true
            }
        ];

        vm.options = {
            rowSelection: false,
            multiSelect: false,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: false,
            add: false,
            showFilter: true,
            hideActions: true
        };


        function init() {
            var view = JSON.parse(localStorage.getItem('board_view'));
            setView(view);

            var promises = [
                HttpService.get("/board/mCase/count"),
                HttpService.get("/board/mCase/count?years=" + yearsToFetch)
            ];

            $q.all(promises).then(function (response) {
                var overall = response[0].data;
                overall.title = $translate.instant('statistics.total');

                var byYear = response[1].data.filter(function (statistics) {
                    return statistics.openCases > 0 || statistics.closedCases > 0
                }).sort(function (a, b) {
                    return b.year - a.year;
                }).map(function (statistics) {
                    var stats = statistics;
                    stats.title = statistics.year;
                    return stats;
                });

                var all = byYear.slice();
                all.unshift(overall);

                vm.statistics = all.map(function (statistics) {
                    var totalCases = statistics.closedCases + statistics.openCases;
                    return ({
                        avg: Math.round(statistics.avg) + " ±(" + Math.round(statistics.std) + ")",
                        closedCases: totalCases ? statistics.closedCases + " (" + Math.round((statistics.closedCases * 100) / (statistics.closedCases + statistics.openCases)) + "%)" : 0,
                        minMax: statistics.min + " - " + statistics.max,
                        openCases: totalCases ? statistics.openCases + " (" + Math.round((statistics.openCases * 100) / (statistics.closedCases + statistics.openCases)) + "%)" : 0,
                        participants: statistics.participants,
                        median: Math.round(statistics.quartile[1]),
                        title: statistics.title
                    })
                });

                chartData(byYear);
                PreloaderService.hideParticularPreloader('statistics');
            })

        }

        function chartData(data) {
            $rootScope.$emit('dataForChart', data);
            $rootScope.$emit('update', data);
        }

        function setView(view) {
            switch (view) {
                case 'columns': {
                    vm.displayAsColumns = true;
                    vm.displayAsList = false;
                    break;
                }
                case 'list': {
                    vm.displayAsList = true;
                    vm.displayAsColumns = false;
                    break;
                }
                default: {
                    vm.displayAsColumns = true;
                    vm.displayAsList = false;
                    break;
                }
            }
            currentView = view || 'list';
            localStorage.setItem('board_view', JSON.stringify(currentView));
        }

        function isViewActive(view) {
            return currentView === view;
        }

    },
    templateUrl: 'shared/components/boardManagement/statistics/caseNumbers/caseNumbers.html',
    controllerAs: 'caseNumbers'
});