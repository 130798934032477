core_module.factory('QueryService', function () {
    var queryService = {
        getDefaultQuery: getDefaultQuery
    };

    function getDefaultQuery() {
        return {
            order: 'id',
            limit: 10,
            page: 1
        }
    }
    return queryService;
});
