core_module.directive('svgRegion', ['$compile', function ($compile) {
    return {
        restrict: 'A',
        scope: {
            dummyData: "="
        },
        link: function (scope, element) {
            scope.detailWindowOpen = false;
            scope.selected = false;
            scope.elementId = element.attr('id');
            scope.hoverRegion = element.attr('hoverRegion');
            scope.selected = function () {
                return scope.$parent.svgSelected(scope.elementId);
            };
            scope.detailWindowOpen = function () {
                return scope.$parent.svgDetailWindowOpen(scope.elementId);
            };
            scope.regionClick = function () {
                scope.$parent.svgClick(scope.elementId,false);
            };
            scope.regionMouseOver = function () {
                scope.hoverRegion = scope.elementId;
            };
            scope.regionMouseOut = function () {
                scope.hoverRegion = "noHover";
            };
            element.attr("ng-click", "regionClick()");
            element.attr("ng-mouseover", "regionMouseOver()");
            element.attr("ng-mouseout", "regionMouseOut()");
            element.attr('hoverRegion', '{{hoverRegion}}');
            element.attr("ng-class", "{active:detailWindowOpen()||hoverRegion==elementId,selected:selected()}");
            element.removeAttr("svg-region");
            $compile(element)(scope)
        }
    }
}
]);
