core_module.controller('CreateIssueController', function (caseObject, DialogService, CaseService, PreloaderService) {
    var vm = this;

    vm.$onInit = init;
    vm.saveStructure = saveStructure;
    vm.data = {};
    vm.section = [];

    function init() {
        vm.case = angular.copy(caseObject);
        vm.section = vm.case.section;
        vm.data = vm.case.data[vm.case.section.identifier];
        if (typeof vm.data === 'undefined') {
            vm.data = {};
        }
    }

    function saveStructure() {
        PreloaderService.showPreloader("issueDialog");
        vm.data.sectionVersionCustomId = vm.case.section.customId;
        vm.data.sectionVersionName = vm.case.section.name;
        vm.case.data[vm.case.section.identifier] = vm.data;
        if (typeof vm.case.issues === 'undefined') {
            vm.case.issues = [];
        }

        if (vm.case.issues.indexOf(vm.case.section) === -1) {
            vm.case.issues.push(vm.case.section);
        }
        CaseService.saveCase(vm.case).then(function (res) {
            vm.case = res;
            DialogService.hideDialog(vm.case);
            PreloaderService.hideParticularPreloader("issueDialog");
        });
    }
});
