core_module.component('coreDialogToolbar', {
    bindings: {
        title: '@'
    },
    controller: function (DialogService) {
        var vm = this;

        vm.close = close;

        function close() {
            DialogService.cancelDialog();
        }
    },
    templateUrl: 'shared/components/coreDialogToolbar/coreDialogToolbar.html',
    controllerAs: 'toolbar'
});