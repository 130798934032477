core_module.component('rolesManagement', {
    controller: function ($scope) {
        $scope.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            add: true,
            customActions: [{
                name: 'manage_permissions',
                icon: 'group',
                clickFunction: manageRoles
            }]
        };

        $scope.query = {
            order: 'name',
            limit: 10,
            page: 1
        };

        $scope.initObject = {
            deleted: false,
            name: null,
            facilityNeeded: false,
            permissions: []
        };

        function manageRoles(role) {
            var data = {
                title: "" + role.name,
                childEntity: 'rolepermissions',
                parentEntityId: role.id,
                parentEntity: role
            };
            $scope.$emit('fromParentTableEvent', data);
        }

        $scope.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {
                name: 'name',
                field: 'name',
                display: true,
                edit: true,
                type: 'text',
                link: false,
                editrules: {required: true},
                filter: true
            },
            {
                name: 'facilityNeeded',
                field: 'facilityNeeded',
                display: true,
                edit: true,
                type: 'boolean',
                link: false,
                filter: true
            }
        ];

        $scope.selRow = [];

    },
    templateUrl: 'shared/components/rolesManagement/rolesManagement.html'
});
