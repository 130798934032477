core_module.controller('ExportSettingToExportCsvFieldsGroupController', function ($scope, $rootScope, $translate, $q,
                    $mdDialog, HttpService, AlertService, EntityService, DialogService, parentId) {
    var promisses = [];
    $scope.parentId = parentId;
    $scope.exportSettingsId = undefined;

    $scope.cancel = function () {
        $mdDialog.cancel();
    };

    init();

    function init() {
        var exportSettingOptionsUrl = 'export_settings/exportsettingsForExport/';
        promisses[0] = EntityService.getEntries(exportSettingOptionsUrl + $scope.parentId);

        $q.all(promisses).then(function (dataArray) {
            var j, dd,tmpArray = [];
            var selectOption = {};
            var lSectiontemplatesOptions = [];

            if (dataArray[0].data) { // Sectiontemplates objects
                tmpArray = dataArray[0].data;
                for (j=0; j < tmpArray.length; j++) {
                    selectOption = {};
                    selectOption.value = tmpArray[j].id;
                    selectOption.name = tmpArray[j].id + "; " + tmpArray[j].csvFieldName;
                    $scope.exportSettingsOptions.push(selectOption);
                }
            }
        }, function (response) {
            AlertService.renderErrorMessage(response.data.details.cause, response.data.details, response.status);
        });

    }

    $scope.addConfirm = function () {
        var url = "/export_settings/addExportSetting?exportCsvFieldsGroupId=" + $scope.parentId + "&exportSettingId="
            + $scope.exportSettingsId;

        HttpService.post(url).then(function (response) {
            $rootScope.$broadcast('refreshTable', {entity: 'export_settings'});
        }, function (response) {
            console.log("failure", response);
        });

        DialogService.closeDialog();
    };

});