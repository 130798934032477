/**
 * Created by Thorsten.Wirth on 24.04.2017.
 */
core_module.controller('UserRegistrationController', function ($rootScope, $scope, $translate, UsersService, EntityService, HttpService, AuthService, AlertService, $mdDialog, DialogService, PreloaderService, $timeout, userExists, user, email) {

    $scope.userExists = userExists;
    $scope.email = email;
    $scope.facilities = [];
    $scope.searchText = '';
    $scope.selectedFac = {
        id: ""
    };
    $scope.selectedRole = {
        id: ""
    };
    $scope.user = {};

    $scope.data = {
        genders: [{'value': 'M', 'name': $translate.instant('salutation.male')}, {
            'value': 'F',
            'name': $translate.instant('salutation.female')
        }]
    };

    $scope.isAdmin = AuthService.isAdmin;
    $scope.addNewUser = addNewUser;
    $scope.addUserToFacility = addUserToFacility;
    $scope.clearSearchTerm = clearSearchTerm;

    this.$onInit = init;

    function init() {
        getFacilities();
        getRoles();
        $scope.user = user === "" ? {} : user;
        $scope.currentUser = AuthService.getCurrentUser();
        $scope.userExists = userExists;
        PreloaderService.waitForDOM();
    }

    function clearSearchTerm() {
        $scope.searchText = '';
    }

    function getFacilities() {
        if (AuthService.isAdmin()) {
            EntityService.getEntries('/facility', {limit: -1}).then(function (response) {
                $scope.facilities = response.data.content.map(function (facility) {
                    return {name: facility.displayName, value: facility.id}
                });
            });
        }
    }

    function getRoles() {
        HttpService.get('/currentUser/assignableroles').then(function (response) {
            $scope.roles = response.data.map(function (item) {
                return {name: item.name, value: item}
            });
        });
    }

    function addNewUser() {
        PreloaderService.showPreloader('userDialog');
        $scope.user.email = $scope.email;
        var url = "/users/registration?roleId=" + $scope.selectedRole.id;
        if ($scope.selectedRole.facilityNeeded === true) {
            var facilityId = AuthService.isAdmin() ? $scope.selectedFac.id : AuthService.getCurrentUserFacility().id;
            url += "&facilityId=" + facilityId;
        }

        HttpService.shortPost(url, $scope.user, function (response) {
            $rootScope.$broadcast("refreshTable", {entity: 'users'});
            AlertService.showToast('user.created');
            DialogService.hideDialog();
            PreloaderService.hideParticularPreloader('userDialog');
        }, function (response) {
            PreloaderService.hideParticularPreloader('userDialog');
            console.log(response)
        });
    }

    function addUserToFacility() {
        PreloaderService.showPreloader('userDialog');
        var userRole = {
            roleId: $scope.selectedRole.id,
            userId: $scope.user.id,
            facilityId: null
        };

        if ($scope.selectedRole.facilityNeeded === true) {
            userRole.facilityId = AuthService.isAdmin() ? $scope.selectedFac.id : AuthService.getCurrentUserFacility().id;
        }

        HttpService.shortPost("/userRole", userRole, function (response) {
            $rootScope.$broadcast("refreshTable", {entity: 'users'});
            AlertService.showToast('user.addedToFacility');
            DialogService.hideDialog();
            PreloaderService.hideParticularPreloader('userDialog');
        }, function (response) {
            PreloaderService.hideParticularPreloader('userDialog');
            console.log(response)
        });
    }
});
