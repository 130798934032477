core_module.factory('RouteService', function (AuthService, AlertService, PreloaderService, ScrollService, $rootScope, $route, $window) {
    var defaultRoutes = [
        {href: '/', name: 'app.home'},
        {href: '/error', name: 'app.error', hide: true},
        {href: '/chart', name: 'chart', hide: true}
    ];

    var routesSignedIn = [
        {href: '/coreTableTest', name: 'coreTableTest', hide: true},
        {
            name: 'Administration', children: [
                {href: '/localization', name: 'localization.loc_btn', permission: 'ROLE_P_LOCALIZATION_UPDATE'},
                {
                    name: 'facility.management', permission: 'ROLE_P_FACILITY_UPDATE', children: [
                        {href: '/facilities', name: 'facility.title'},
                        {
                            href: '/medicalSpeciality',
                            name: 'medicalSpeciality.title',
                            permission: 'ROLE_P_MEDICALSPECIALITY_ADMIN'
                        },
                        {href: '/facilityType', name: 'facilityType.title', permission: 'ROLE_P_FACILITYTYPE_ADMIN'}
                    ]
                },
                {
                    name: 'parameterManagement', href: '/parameter', permission: 'ROLE_P_PARAMETER_ADMIN'

                },
                {href: '/countries', name: 'countries.title', permission: 'ROLE_P_COUNTRY_ADMIN'},
                {href: '/addressType', name: 'addressType.title', permission: 'ROLE_P_ADDRESSTYPE_ADMIN'},
                {
                    name: 'roles.management', permission: 'ROLE_P_ROLEPERMISSION_ADMIN', children: [
                        {href: '/permissions', name: 'permissions.title'},
                        {href: '/rolesPermission', name: 'rolepermissions.title'}

                    ]
                },
                {href: '/userManagement', name: 'user.management', permission: 'ROLE_P_USER_ADMIN'},
                {
                    name: 'periodicreport.mainmenu', permission: 'ROLE_P_PERIODICREPORT', children: [
                        {href: '/periodicReport', name: 'periodicreport.mainmenu'}
                    ]
                },
                {href: '/news', name: 'news', permission: 'ROLE_P_NEWS_ADMIN'},
                {
                    facilityNeeded: true,
                    name: 'exports.management', permission: 'ROLE_P_EXPORTS_ADMIN', children: [
                        {href: '/exportsTable', name: 'exports.table.title'},
                        {href: '/exportFilesTable', name: 'exportFilesTable'},
                        {href: '/exportSettingsTable', name: 'exportSettingsTable'},
                        {href: '/sectionTemplateTable', name: 'sectionTemplateTable'},
                        {href: '/exportCsvFieldsGroupManagement', name: 'exportCsvFieldsGroupManagement'}
                    ]
                }
            ]
        },
        // {
        //     name: 'Test', permission: 'ROLE_P_ROLE_ADMIN', children: [
        //     {href: '/coreInputTest', name: 'coreInputTest'}
        // ]
        // },
        {
            name: 'logging.menu', permission: 'ROLE_P_ADMIN',
            children: [
                {href: '/clientlog', name: 'logging.logs'},
                {href: '/auditing', name: 'logging.auditing'},
            ]
        },
        // {
        //     name: 'case.management', permission: 'ROLE_P_MCASE_LIST', children: [
        //     {href: '/caseFileManagement', name: 'casefile.management', permission: 'ROLE_P_CASEFILE_ADMIN'},
        //     {href: '/caseSheetManagement', name: 'casefile.sheet_management', permission: 'ROLE_P_MCASE_LIST'}
        // ]
        // },
        {
            name: 'scheme.management', permission: 'ROLE_P_ADMIN', children: [
                {href: '/schemeManagement', name: 'scheme.management', permission: 'ROLE_P_ADMIN'},
                {href: '/schemeType', name: 'scheme.type', permission: 'ROLE_P_ADMIN'}

            ]
        },
        {
            href: '/caseSheetManagement/sheet',
            name: 'singleSheetManagement',
            permission: 'ROLE_P_MCASE_LIST',
            hide: true,
            facilityNeeded: true,
        },
        {
            href: '/boardManagement',
            name: 'boardManagement',
            permission: 'ROLE_P_BOARD_LIST',
            facilityNeeded: true,
        }
    ];

    function getRoutes() {
        if (AuthService.isSignedIn()) {
            return defaultRoutes.concat(routesSignedIn);
        }
        return defaultRoutes;
    }

    function changeSideBarTab(view) {
        var routes = getRoutes();
        angular.forEach(routes, function (v, k) {
            if (v.href === '/' + view) {
                $rootScope.$broadcast('changeSidebarTabPosition', k - 1);
                return k;
            }

        });
    }

    function reloadRoute() {
        $route.reload();
    }

    function fullReload() {
        $window.location.reload();
    }

    $rootScope.$on('$routeChangeError', function () {
        AlertService.renderErrorMessage("app.route.change.failed");
    });

    $rootScope.$on('$routeChangeStart', function () {
        ScrollService.scrollTop();
    });

    $rootScope.$on('$translateChangeSuccess', function () {
        reloadRoute();
    });

    return {
        getRoutes: getRoutes,
        changeSideBarTab: changeSideBarTab,
        reloadRoute: reloadRoute,
        fullReload: fullReload
    };

});