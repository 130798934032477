core_module.factory('WorkflowService', function (HttpService) {

    function addWorkflow(worklfow) {
        return HttpService.post('/workflow', {
            title: workflow.title,
            active: workflow.active
        });
    }

    function updateWorkflow(workflow) {
        return HttpService.put('/workflow/' + workflow.id, {
            title: workflow.title,
            active: workflow.active
        })
    }

    function getWorkflow() {
        return HttpService.get('/workflow/');
    }

    function deleteWorkflow(workflow) {
        return HttpService.delete('/workflow/' + workflow.id)
    }

    return {
        addWorkflow: addWorkflow,
        updateWorkflow: updateWorkflow,
        getWorkflow: getWorkflow,
        deleteWorkflow: deleteWorkflow
    }
});