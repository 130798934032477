core_module.component('countriesManagement', {
    controller: function ($mdDialog) {
        var vm = this;

        vm.addEntry = addEntry;
        vm.editEntry = editEntry;

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            add: true
        };

        vm.initObject = {
            countryCode: '',
            euCountry: false,
            postalAddressPattern: ''
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {name: 'countryCode', field: 'countryCode', display: true, edit: true, type: 'text', link: false, filter: true},
            {name: 'euCountry', field: 'euCountry', display: true, edit: true, type: 'boolean', link: false, filter: true},
            {name: 'postalAddressPattern', field: 'postalAddressPattern', display: true, edit: true, type: 'text', link: false, filter: true}
        ];


        function addEntry() {
            $mdDialog.show({
                templateUrl: 'shared/components/countriesManagement/dialogs/countriesEntry.html',
                controller: 'CountriesEntryController',
                controllerAs: 'countryDialog',
                fullscreen: true,
                locals: {
                    country: null,
                    initObject: vm.initObject
                }
            });
        }

        function editEntry(country) {
            $mdDialog.show({
                templateUrl: 'shared/components/countriesManagement/dialogs/countriesEntry.html',
                controller: 'CountriesEntryController',
                controllerAs: 'countryDialog',
                fullscreen: true,
                locals: {
                    country: country,
                    initObject: vm.initObject
                }
            });
        }
    },
    templateUrl: 'shared/components/countriesManagement/countriesManagement.html',
    controllerAs: 'countries'
});