core_module.component('addressType', {
    controller: function () {
        var vm = this;

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            showDeleted: true,
            add: true
        };

        vm.initObject = {
            id: '',
            name: ''
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {
                name: 'name',
                field: 'name',
                display: true,
                displayName: 'Kind of Address',
                edit: true,
                type: 'text',
                link: false,
                filter: true
            }
        ];
    },
    templateUrl: 'shared/components/addressType/addressType.html',
    controllerAs: 'addressType'
});