core_module.component('homepage', {
    controller: function ($scope, AuthService, DialogService, $routeParams) {
        var vm = this;

        vm.$onInit = init;
        vm.showNewsPreview = showNewsPreview;
        vm.showCasesPreview = showCasesPreview;
        vm.showStatisticsPreview = showStatisticsPreview;

        function init() {
            vm.isSignedIn = AuthService.isSignedIn();
            this.newsCount = '';
            if($routeParams.code){
                DialogService.passwordChange();
            } else if (!vm.isSignedIn)
                DialogService.signIn();
        }

        function showNewsPreview() {
            return AuthService.hasPermissionOrIsAdmin('ROLE_P_NEWS_LIST');
        }

        function showCasesPreview() {
            return AuthService.hasPermissionOrIsAdmin('ROLE_P_MCASE_LIST') && AuthService.hasCurrentUserFacility();
        }

        function showStatisticsPreview() {
            return vm.isSignedIn && AuthService.hasCurrentUserFacility();
        }

        $scope.$on('changeUserState', function (event, isSignedIn) {
            vm.isSignedIn = isSignedIn;
        });
    },
    templateUrl: 'shared/components/homepage/homepage.html',
    controllerAs: 'homepage'
});