core_module.component('userRole', {
    templateUrl: 'shared/components/userRole/userRole.html',
    controller: function($translate, $scope, $location, BreadCrumbsService, EntityService) {
        if(BreadCrumbsService.getCount() <= 1){
            $location.path('/administration');
        } else {
            $scope.getData = function () {
               // return getChildren($route.current.params.id)
                return null;
            };
        }
    }
});