core_module.controller('RolePermissionDialogsController', function ($rootScope, $scope, $mdDialog, EntityService, HttpService, AlertService, DialogService, $translate,
                                                                    permissions, roleId, roleName) {


    $scope.roleId = roleId;
    $scope.permissions = permissions;
    $scope.roleName = roleName;

    $scope.permissionsId = $scope.permissions.map(function (perm) {
        return perm.id
    });

    $scope.getAllPermissions = function () {
        HttpService.get("/rolepermissions/missing/" + $scope.roleId).then(function (response) {
            $scope.assignablePermissions = response.data.map(function (permission) {
                return {name: permission.name, value: permission.id}
            });
        });
    };


    $scope.permission_selected = null;

    $scope.getAllPermissions();

    $scope.addConfirm = function () {
        HttpService.post('/rolepermissions?roleId=' + $scope.roleId + '&permissionId=' + $scope.permission_selected).then(function (response) {
            AlertService.showToast($translate.instant(' create entry successfully'));
            $rootScope.$broadcast("refreshTable", {entity: "rolepermissions"});
            DialogService.closeDialog();
        }, function (/*response*/) {
            AlertService.renderErrorMessage($translate.instant('form_has_errors'), $translate.instant('form_has_errors'), '');
            $mdDialog.cancel();
        });

    };

});