/**
 * Created by Thorsten.Wirth on 11.04.2017.
 */
core_module.controller('CountriesEntryController', function ($rootScope, $translate, DialogService, EntityService, AlertService, HttpService, country, initObject) {
    var vm = this;
    var isNewEntry = false;

    vm.title = 'add_country';

    vm.$onInit = init;
    vm.confirm = confirm;

    function init() {
        if (country) {
            vm.country = angular.copy(country);
            vm.title = 'edit_country';
        }
        else {
            vm.country = angular.copy(initObject);
            isNewEntry = true;
        }
    }

    function confirm() {
        vm.country.countryCode = vm.country.countryCode.toUpperCase();
        checkIfCountryExists().then(function (response) {
            AlertService.renderDialogMessage('app.validation.entryExist', 'app.validation', {name: '"' + vm.country.countryCode + '"'}, response.status);
        }, function () {
            if (isNewEntry) {
                createCountry();
            }
            else updateCountry();
        });
    }

    function updateCountry() {
        EntityService.updateEntry('country', vm.country).then(function () {
            $rootScope.$broadcast("refreshTable", {entity: 'country'});
            AlertService.showToast('country UpdateSuccessfully');
            DialogService.hideDialog();
        });
    }

    function createCountry() {
        EntityService.addEntry('country', vm.country).then(function () {
            $rootScope.$broadcast("refreshTable", {entity: 'country'});
            AlertService.showToast('country CreatedSuccessfully');
            DialogService.hideDialog();
        });
    }

    function checkIfCountryExists() {
        return HttpService.head("/country/countryCode/" + vm.country.countryCode);
    }
});