/**
 * Created by Thorsten.Wirth on 16.09.2016.
 */
core_module.controller('CoreTableEntryController', function ($rootScope, $scope, $mdDialog, entriesArray, columns, EntityService, DialogService, AlertService, PreloaderService, entity, $translate, initObject, isRequired, row) {
    var vm = this;

    vm.columns = columns;
    vm.entriesArray = entriesArray;
    vm.isRequired = isRequired;

    vm.$onInit = init;
    vm.colName = colName;
    vm.isObject = isObject;
    vm.addConfirm = addConfirm;
    vm.editConfirm = editConfirm;

    function init() {
        PreloaderService.waitForDOM();
        if (row) {
            vm.entry = angular.copy(row);
            vm.isNewEntry = false;
            vm.title = 'edit';
        }
        else {
            vm.entry = angular.copy(initObject);
            vm.isNewEntry = true;
            vm.title = 'add_entry';
        }
    }

    function addConfirm() {
        if ($scope.coreTableForm.$valid) {
            EntityService.addEntry(entity, vm.entry).then(function () {
                AlertService.showToast(entity + 'CreatedSuccessfully');
                $rootScope.$broadcast('refreshTable', {entity: entity});
                DialogService.hideDialog();
            });
        }
    }

    function editConfirm() {
        if ($scope.coreTableForm.$valid) {
            EntityService.updateEntry(entity, vm.entry).then(function () {
                AlertService.showToast(entity + ' updated successfully');
                $rootScope.$broadcast('refreshTable', {entity: entity});
                DialogService.hideDialog();
            });
        }
    }

    function isObject(obj) {
        if (obj instanceof Date) {
            return false;
        }
        return angular.isObject(obj)
    }

    function colName(col) {
        if (!col.displayName || col.displayName === '') {
            return $translate.instant(col.name);
        } else {
            return $translate.instant(col.displayName);
        }
    }
});