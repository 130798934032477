core_module.factory('BreadCrumbsService', function (AuthService, $route) {

    var breadCrumbs = [];

    function getBreadCrumbs() {
        return breadCrumbs
    }

    function addLevel(title, location) {
        breadCrumbs.push({"title": title, "location": location});
    }

    function removeAbove(index) {
        breadCrumbs = breadCrumbs.slice(0, index + 1);
        return breadCrumbs;
    }

    function removeAll() {
        breadCrumbs = [];
    }

    function getCount() {
        return breadCrumbs.length;
    }

    function checkRoot(element) {
        if(breadCrumbs.length > 0 && breadCrumbs[0].location !== element) {
            removeAll();
        }

        if(breadCrumbs.length > 1 && !Object.keys($route.current.params).length) {
            removeAll();
        }
    }

    return {
        getBreadCrumbs: getBreadCrumbs,
        addLevel: addLevel,
        removeAbove: removeAbove,
        removeAll: removeAll,
        checkRoot: checkRoot,
        getCount: getCount
    };

});