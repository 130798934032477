core_module.component('singleOccupationNode', {
    bindings: {
        item: '<',
        callback: '<?'
    },
    controller: function () {
        var vm = this;

        vm.collapsed = true;
        vm.toggleNode = toggleNode;
        vm.showDefinition = false;

        function toggleNode() {
            vm.collapsed = !vm.collapsed;
        }

    },
    templateUrl: 'shared/components/casesManagement/dialogs/single-occupation-node.html',
    controllerAs: 'occupationNode'
});