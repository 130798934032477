core_module.component('alerts', {
    controller: function ($rootScope, $mdDialog) {
        var vm = this;

        vm.getAlerts = getAlerts;
        vm.openErrorsDialog = openErrorsDialog;
        vm.showAlerts = showAlerts;
        vm.ignoreErrors = ignoreErrors;

        function getAlerts() {
            return $rootScope.alerts;
        }

        function showAlerts() {
            return $rootScope.showAlerts;
        }

        function openErrorsDialog() {
            $mdDialog.show({
                templateUrl: 'shared/components/alerts/dialogs/errors.dialog.html',
                bindToController: true,
                controller: 'ErrorsDialogController',
                controllerAs: 'errorsDialog',
                fullscreen: true,
                multiple: true
            });
        }

        function ignoreErrors() {
            $rootScope.showAlerts = false;
        }
    },
    templateUrl: 'shared/components/alerts/alerts.html',
    controllerAs: 'alerts'
});