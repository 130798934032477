core_module.component('languageSelector', {
    controller: function ($mdDialog) {
        var vm = this;

        vm.selectLanguage = selectLanguage;

        function selectLanguage() {
            $mdDialog.show({
                templateUrl: 'shared/components/languageSelector/dialogs/select.language.html',
                controller: 'SelectLanguageDialogController',
                controllerAs: 'selectorDialog',
                fullscreen: true
            });
        }
    },
    templateUrl: 'shared/components/languageSelector/languageSelector.html',
    controllerAs: 'languageSelector'
});