core_module.component('singleStatistics', {
    bindings: {
        statistics: '<'
    },
    controller: function () {
        var vm = this;

        vm.$onChanges = function (changes) {
            if (changes.statistics) vm.statistics = changes.statistics.currentValue;
        };
    },
    templateUrl: 'shared/components/boardManagement/statistics/caseNumbers/singleStatistics.html',
    controllerAs: 'singleStatistics'
});