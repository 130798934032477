core_module.factory('UsersService', function (HttpService) {

    function getUsers() {
        return HttpService.get('/users');
    }

    function addUser(user) {
        return HttpService.post('/users', {
            firstName: user.firstName,
            lastName: user.lastName,
            gender: user.gender,
            title: user.title,
            email: user.email,
            password: user.password,
            language: user.language
        });
    }

    function updateUser(user) {
        return HttpService.put('/users/' + user.id, {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            gender: user.gender,
            title: user.title,
            password: user.password,
            locked: user.locked,
            newPassword: user.newPassword,
            changePassword: user.changePassword,
            language: user.language,
            currentFacility: user.currentFacility
        })
    }

    function updateCurrentUser(user) {
        return HttpService.put('/currentUser', user);
    }

    function deleteUser(user) {
        return HttpService.delete('/users/' + user.id)
    }

    return {
        addUser: addUser,
        updateUser: updateUser,
        updateCurrentUser: updateCurrentUser,
        getUsers: getUsers,
        deleteUser: deleteUser
    }
});
