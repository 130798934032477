core_module.component('alertBar', {
    controller: function (HttpService) {
        var vm = this;
        vm.testServer = false;
        vm.$onInit = init;

        function init() {
            vm.templateType = {};
            isTestServer();
        }

        function isTestServer() {
            return HttpService.get('/auth/testServer').then(function (response) {
                vm.testServer = response.data.isTestServer;
            });
        }
    },
    templateUrl: 'shared/components/alertBar/alert.bar.html',
    controllerAs: 'alertBar'
});