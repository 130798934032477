core_module.component('appHeader', {
    controller: function ($scope, $rootScope, $translate, $location, DialogService, HttpService, AuthService) {
        var vm = this;

        vm.showFacilitySelector = true;
        vm.isSignedIn = false;
        vm.signIn = signIn;
        vm.signOut = signOut;
        vm.openMenu = openMenu;

        vm.getSecondsToLogout = AuthService.getSecondsToLogout;

        vm.$onInit = init;

        function init() {
            vm.isSignedIn = AuthService.isSignedIn();
            if (vm.isSignedIn) {
                handleSignedIn();
            }
            else {
                vm.currentUser = '';
                $rootScope.$broadcast('changeUserState', false);
            }
        }

        function signIn() {
            DialogService.signIn();
        }

        function signOut() {
            AuthService.signOut();
        }

        function openMenu($mdMenu, ev) {
            $mdMenu.open(ev);
        }

        function handleSignedIn() {
            setCurrentUserData();
        }

        function setCurrentUserData() {
            vm.currentUserName = AuthService.getCurrentUserName();
            vm.currentUserFacility = AuthService.getCurrentUserFacility();
        }

        $scope.$on('changeUserState', function (event, isSignedIn) {
            vm.isSignedIn = isSignedIn;
            if (isSignedIn) {
                handleSignedIn();
            }
            else
                vm.currentUser = '';
        });
    },
    templateUrl: 'shared/components/header/header.html',
    controllerAs: 'header'
});