core_module.component('coreTable', {
    bindings: {
        entity: '@',
        columnDefs: '=?',
        options: '<?',
        expdataObject: '=?',
        query: '=?',
        initObject: '=?',
        addFunction: '<?',
        editFunction: '<?',
        delFunction: '<?',
        dropEntity: '=?', /* true - Physically delete from DB-table*/
        serverPaging: '<?',
        entries: '=?',
        dialogCallback: '=?',
        transformData: '<?',
        entityUrl: '<',
        prefilled: '<?'
    },
    controller: function ($scope, $mdDialog, $q, $translate, EntityService, AlertService, QueryService, PreloaderService, $filter, $timeout) {
        var vm = this;
        var userPerformedSearch = false;

        var stringOperators = [
            {value: ':=', name: 'filters.contains'},
            {value: '=', name: 'filters.equal'},
        ];

        var enumOperators = [
            {value: '=', name: 'filters.equal'},
        ];

        var numberOperators = [
            {value: '=', name: 'filters.equal'},
            {value: '<=', name: 'filters.lessOrEqual'},
            {value: '>=', name: 'filters.greaterOrEqual'},
            {value: '<', name: 'filters.lessThan'},
            {value: '>', name: 'filters.greaterThan'}
        ];

        var booleanOperators = [
            {value: '=', name: 'filters.isEqual'},
        ];

        var dateOperators = [
            {value: '<=', name: 'filters.before'},
            {value: '>=', name: 'filters.after'}
        ];


        vm.entriesArray = [];
        vm.textForView = {};
        vm.arrayData = {};
        vm.tableTotalElements = 0;
        vm.filters = [];
        vm.rowsButtons = {};

        vm.performSearch = performSearch;
        vm.getUrl = getUrl;
        vm.getData = getData;
        vm.reorder = reorder;
        vm.getArrayData = getArrayData;
        vm.filtersAvailable = filtersAvailable;
        vm.isObject = isObject;
        vm.isArray = isArray;
        vm.isRequired = isRequired;
        vm.isDisplayName = isDisplayName;
        vm.isLink = isLink;
        vm.isDisplay = isDisplay;
        vm.showSelected = showSelected;
        vm.sortableColumn = sortableColumn;
        vm.getLinkData = getLinkData;
        vm.removeEntries = removeEntries;
        vm.toggleShowDeleted = toggleShowDeleted;
        vm.clearFilters = clearFilters;
        vm.$onInit = init;
        vm.$onChanges = handleChanges;

        function init() {
            setPreloaderIdentifier();
            processBindings();
            loadSavedData();
            setDefaultOptions();
            setPassedOptions();
            setInputData();
            loadFilters();
            getData();
        }

        function loadFilters() {
            var filters = initializeFilters();

            try {
                var rememberedFilters = JSON.parse(localStorage.getItem('coreTable_' + vm.entity + '_filters') || '{}');
                filters.map(function (filter) {
                    if (rememberedFilters[filter.field]) {
                        return Object.assign(filter, {
                            value: rememberedFilters[filter.field].value,
                            operator: rememberedFilters[filter.field].operator
                        });
                    }
                    return filter;
                });
            } catch (error) {
                console.error('Incorrect filters');
            } finally {
                vm.filters = filters;
            }
        }

        function initializeFilters() {
            var filters = vm.columnDefs.filter(function (column) {
                return column.filter === true
            }).map(function (column) {
                var operators = getOperators(column.type);
                return ({
                    field: column.field,
                    filterField: column.filterField, // possibility to filter by nested fields
                    selectedField: getColumnName(column),
                    selectedFilter: column.field,
                    type: getTypeForFilter(column.type),
                    options: column.selectOptions,
                    operators: operators,
                    operator: operators[0].value
                });
            });

            return filters;
        }

        function getColumnName(column) {
            return column.displayName ? $translate.instant(column.displayName) : $translate.instant(column.name);
        }

        function clearFilters() {
            vm.filters = vm.filters.map(function (filter) {
                return Object.assign(filter, {value: null});
            });
        }

        function getTypeForFilter(type) {
            if (type === 'email') return 'text';
            return type;
        }

        function getOperators(type) {
            if (type === 'date') return dateOperators;
            else if (type === 'datetime') return dateOperators;
            else if (type === 'time' || type === 'number') return numberOperators;
            else if (type === 'boolean') return booleanOperators;
            else if (type === 'select') return enumOperators;
            else return stringOperators;
        }


        function setPreloaderIdentifier() {
            vm.preloaderIdentifier = 'coreTable_' + vm.entity;
        }

        function handleChanges(changes) {
            if (changes.hasOwnProperty('entityUrl') && !changes.entityUrl.isFirstChange()) {
                getData();
            }
            if (changes.hasOwnProperty('options') && !changes.options.isFirstChange()) {
                setDefaultOptions();
                setPassedOptions();
            }
        }

        function setDefaultOptions() {
            var defaultOptions = {
                title: '',
                rowSelection: false,
                multiSelect: false,
                autoSelect: true,
                decapitate: false,
                largeEditDialog: false,
                boundaryLinks: true,
                limitSelect: true,
                pageSelect: true,
                editable: false,
                add: false
            };
            vm.tableOptions = vm.options || defaultOptions;
        }

        function setInputData() {
            if (vm.prefilled) {
                vm.entriesArray = vm.entries;
                PreloaderService.particularWaitForDOM(vm.preloaderIdentifier);
            }
        }

        function setPassedOptions() {
            vm.tableOptions.showFilter = vm.tableOptions.showFilter || false;
            vm.tableOptions.editable = vm.tableOptions.editable || false;
            vm.tableOptions.deletable = vm.tableOptions.deletable || false;
            vm.tableOptions.add = vm.tableOptions.add || false;
            vm.tableOptions.showDeleted = vm.tableOptions.showDeleted || false;
            vm.tableOptions.boundaryLinks = vm.tableOptions.boundaryLinks || true;
            vm.tableOptions.editCondition = vm.tableOptions.editCondition || function (row) {
                return true;
            };
            vm.tableOptions.removeCondition = vm.tableOptions.removeCondition || function (row) {
                return true;
            };

            vm.tableOptions.addCondition = vm.tableOptions.addCondition || function () {
                return true;
            };

            vm.addLabel = 'core.add';
            if (angular.isDefined(vm.tableOptions.toolbar) && angular.isDefined(vm.tableOptions.toolbar.addLabel)) {
                vm.addLabel = vm.tableOptions.toolbar.addLabel;
            }
        }

        function processBindings() {
            vm.serverPaging = typeof vm.serverPaging === 'undefined' ? true : vm.serverPaging;
            vm.query = vm.query || QueryService.getDefaultQuery();
            vm.dialogCallback = vm.dialogCallback || function () {
            };
            vm.addEntry = vm.addFunction || defaultAddFunction;
            vm.editRow = vm.editFunction || defaultEditFunction;
            vm.delFunction = vm.delFunction || defaultDelFunction;
        }

        function loadSavedData() {
            vm.query.order = localStorage.getItem(vm.entity + "_sorting") || vm.query.order;
        }

        function performSearch() {
            var searchUrl = constructQueryUrl(vm.entityUrl);
            searchUrl = constructSearchUrl(searchUrl);
            sendRequest(searchUrl);
            userPerformedSearch = true;
        }

        function constructQueryUrl(url) {
            var sort;
            var separator = '?';

            if (url.indexOf('?') > -1)
                separator = '&';

            if (vm.serverPaging === true) {
                if (vm.query.order.charAt(0) === '-') {
                    sort = vm.query.order.substr(1) + ',desc'
                } else {
                    sort = vm.query.order;
                }

                return '/' + url + separator + 'sort=' + sort
                    + "&size=" + vm.query.limit + "&page=" + vm.query.page + '&showDeleted=' + vm.tableOptions.showDeleted;
            } else {
                return '/' + url + separator + 'showDeleted=' + vm.tableOptions.showDeleted;
            }
        }


        function filtersAvailable() {
            if (vm.serverPaging !== true) return false;
            return vm.columnDefs.some(function (column) {
                return column.filter === true
            });
        }

        function constructSearchUrl(url) {
            var filters = vm.filters.filter(function (filter) {
                return typeof filter.value !== 'undefined' && filter.operator
            });

            var searchUrl = url;
            if (filters.length > 0) {
                searchUrl += '&search=';
                filters.forEach(function (filter, index) {
                    if (filter.hasOwnProperty('value') && typeof filter.value !== 'undefined' && filter.value !== null) {
                        var value = filter.value;
                        if (filter.type === 'date') {
                            value = new Date(value);
                            if (filter.operator === '<' || filter.operator === '<=') {
                                value.setHours(23);
                                value.setMinutes(59);
                                value.setSeconds(59);
                            }
                            value = value.getTime();
                        }
                        if (index > 0) searchUrl += ',';
                        if (filter.filterField) {
                            searchUrl += filter.filterField;
                        } else {
                            searchUrl += filter.field;
                        }
                        searchUrl += filter.operator;
                        searchUrl += value;
                    }
                });
            }
            return searchUrl;
        }

        function getUrl() {
            var url = constructQueryUrl(vm.entityUrl || vm.entity);
            url = constructSearchUrl(url);
            return url;
        }


        function isObject(obj) {
            return angular.isObject(obj)
        }

        function isArray(arr) {
            return angular.isArray(arr)
        }

        function toggleShowDeleted() {
            vm.tableOptions.showDeleted = !vm.tableOptions.showDeleted;
        }

        function updateColumnDefs() {
            if (!vm.columnDefs && vm.entriesArray) {
                vm.columnDefs = [];
                angular.forEach(vm.entriesArray[0], function (value, key) {
                    if (angular.isObject(value)) {
                        angular.forEach(value, function (v, k) {
                            vm.columnDefs.push({
                                name: k,
                                field: key,
                                display: true,
                                edit: false,
                                type: 'text',
                                displayName: false
                            })
                        })
                    } else {
                        vm.columnDefs.push({
                            name: key,
                            field: key,
                            display: true,
                            edit: false,
                            type: 'text',
                            displayName: false
                        })
                    }
                })
            }
        }

        function sortableColumn(col) {
            return !((col.noSort && col.noSort === true) || (typeof vm.entriesArray === 'undefined'));
        }

        function processData(data) {
            data = formatData(data);
            if (vm.serverPaging !== true && !vm.tableOptions.showDeleted) {
                vm.entriesArray = data.filter(function (el) {
                    return el.deleted !== true;
                });
            } else {
                vm.entriesArray = data;
            }
            if (vm.transformData) {
                vm.entriesArray = vm.transformData(vm.entriesArray);
            }
            vm.entries = vm.entriesArray;
        }


        /*
         *   Data format
         */
        function formatData(data) {
            var formatedData = [],
                arrObjProperties = [],
                dataRow = {},
                formatedValue;

            if (data === undefined || data === null || data === '') {
                return data;
            }
            if (!angular.isArray(data)) {
                return [data];
            }
            if (data.length === 0) {
                return data;
            }
            angular.copy(data, formatedData);
            arrObjProperties = Object.keys(formatedData[0]);

            for (var i = 0; i < formatedData.length; i++) {
                dataRow = formatedData[i];
                for (var k = 0; k < arrObjProperties.length; k++) {
                    formatedValue = formatDataValue(dataRow[arrObjProperties[k]], arrObjProperties[k]);
                    formatedData[i][arrObjProperties[k]] = formatedValue;
                }
            }
            return formatedData;
        }

        function formatDataValue(val, propName) {
            var formatFunc = getFormatFunc(propName);
            if (angular.isFunction(formatFunc)) {
                val = formatFunc(val)
            }
            return translateValue(val, propName);
        }

        function translateValue(val, propName) {
            if (val === undefined || val === null) {
                return val;
            }
            try {
                if (typeof val === 'string' || val instanceof String) {
                    let indexBegin = val.search(new RegExp('\\$translate\([^)]+\)'));
                    if (indexBegin !== -1) {
                        let indexEnd = val.indexOf(')', indexBegin);
                        let toTranslate = val.slice(indexBegin, indexEnd + 1);
                        let firstPart = val.slice(0, indexBegin);
                        let secondPart = val.slice(indexEnd + 1);
                        toTranslate = toTranslate.slice(toTranslate.indexOf('(') + 1, toTranslate.length - 1);
                        let translated = $translate.instant(toTranslate);
                        val = firstPart + translated + secondPart;
                        val = translateValue(val);
                    } else {
                        if(getTranslate(propName)===true){
                            return $translate.instant(val);
                        } else {
                            return val;
                        }
                    }
                }
            } catch (error) {
                return val;
            }
            return val;
        }

        function getFormatFunc(propName) {
            var columnType;
            for (var i = 0; i < vm.columnDefs.length; i++) {
                if (vm.columnDefs[i].field == propName) {
                    if (vm.columnDefs[i].formatFunc != undefined) {
                        return vm.columnDefs[i].formatFunc
                    } else {
                        if (vm.columnDefs[i].type != undefined) {
                            columnType = vm.columnDefs[i].type;
                            if (columnType === 'currency') {
                                return formatCurrency;
                            }
                            if (columnType === 'date3') {
                                return formatDate;
                            }
                            if (columnType === 'datetime2') {
                                return formatDateTime;
                            }
                        }
                        return undefined;
                    }
                }
            }
            return undefined;
        }

        function getTranslate(propName) {
            for (var i = 0; i < vm.columnDefs.length; i++) {
                if (vm.columnDefs[i].field == propName) {
                    if (vm.columnDefs[i].translate != undefined) {
                        return vm.columnDefs[i].translate
                    } else {
                        return false;
                    }
                }
            }
            return false;
        }

        function formatCurrency(val) {
            var curr;
            if (val === undefined || val === null) {
                return val;
            }
            curr = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(val);
            return curr;
        }

        function formatDate(val) {
            return formatDateOnPattern(val, "dd.MM.yyyy");
        }

        function formatDateTime(val) {
            return formatDateOnPattern(val, "dd.MM.yyyy HH:mm:ss");
        }

        function formatDateOnPattern(val, pattern) {
            var _date, formattedDate;
            if (val === undefined || val === null) {
                return val;
            }
            if (!angular.isNumber(val)) {
                return val;
            }
            _date = new Date(val);
            formattedDate = $filter('date')(_date, pattern);
            return formattedDate;
        }

        function reorder() {
            localStorage.setItem(vm.entity + "_sorting", vm.query.order);
            getData();
        }

        function getData() {
            if (!vm.prefilled) {
                var url = getUrl();
                sendRequest(url);
                $scope.$emit('hideChildTable', {entity: vm.entity});
            }
        }

        function sendRequest(url) {
            PreloaderService.showPreloader(vm.preloaderIdentifier);
            if (vm.serverPaging === true) {
                EntityService.getEntries(url).then(function (response) {
                    if (response.data.content) {
                        vm.tableTotalElements = response.data.totalElements;
                        processData(response.data.content);
                    } else {
                        processData(response.data);
                        vm.tableTotalElements = response.data.length;
                    }
                    updateColumnDefs();
                    PreloaderService.hidePreloader(vm.preloaderIdentifier);
                }, function (response) {
                    PreloaderService.hidePreloader(vm.preloaderIdentifier);
                });
            } else {
                EntityService.getEntries(url).then(function (response) {
                    if (response.data.content) {
                        processData(response.data.content);
                    } else {
                        processData(response.data);
                    }
                    updateColumnDefs();
                    PreloaderService.hidePreloader(vm.preloaderIdentifier);
                }, function (response) {
                    console.log("failure", response);
                    PreloaderService.hidePreloader(vm.preloaderIdentifier);
                })
            }
        }

        $scope.$on('refreshTable', function (event, data /* data.entity -> coreTable entity*/) {
            if (data.entity && data.entity === vm.entity || data.entity === 'ALL') {
                init();
            }
        });

        function defaultAddFunction() {
            $mdDialog.show({
                templateUrl: 'shared/components/coreTable/dialogs/coreTableEntry.html',
                controller: 'CoreTableEntryController',
                controllerAs: 'entryDialog',
                fullscreen: true,
                locals: {
                    entriesArray: vm.entriesArray,
                    columns: vm.columnDefs,
                    entity: vm.entity,
                    isRequired: vm.isRequired,
                    initObject: vm.initObject,
                    row: null
                }
            });
        }


        function getLinkData(row, col) {
            var id = angular.isObject(row[col.field]) ? row[col.field][col.link.id] : row[col.link.id];

            EntityService.getEntryById(col.link.entity, id).then(function (response) {
                var obj = response.data;

                if (!vm.textForView[col.link.entity]) {
                    vm.textForView[col.link.entity] = {};
                }

                var textForView = col.link.properties.map(function (property) {
                    return obj[property]
                }).join(', ');

                vm.textForView[col.link.entity][row.id] = textForView;
                row[col.link.entity] = textForView;
            })
        }

        function getArrayData(row, col, line) {
            if (!vm.arrayData[col.field]) {
                vm.arrayData[col.field] = {}
            }
            if (angular.isObject(row[col.field][0])) {
                vm.arrayData[col.field][line] = row[col.field].map(function (el) {
                    return el[col.paramToDisplay]
                }).join(', ');
            } else vm.arrayData[col.field][line] = row[col.field].join(', ');
        }

        function removeEntries(row) {
            vm.delFunction(row);
        }

        function defaultDelFunction(row) {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('core.delete_entry'))
                .ok($translate.instant('delete'))
                .cancel($translate.instant('cancel'))
                .multiple(true);
            $mdDialog.show(confirm).then(function () {
                var showDeleteSuccess = function () {
                    AlertService.showToast($translate.instant(vm.entity + ' deleted successfully'));
                    getData();
                };

                if (vm.dropEntity === true) {
                    EntityService.deleteEntryPhysically(vm.entity, row.id).then(showDeleteSuccess);
                } else {
                    EntityService.deleteEntry(vm.entity, row.id).then(showDeleteSuccess);
                }
            });
        }

        function defaultEditFunction(row) {
            $mdDialog.show({
                templateUrl: 'shared/components/coreTable/dialogs/coreTableEntry.html',
                controller: 'CoreTableEntryController',
                controllerAs: 'entryDialog',
                locals: {
                    entriesArray: vm.entriesArray,
                    columns: vm.columnDefs,
                    row: row,
                    isRequired: vm.isRequired,
                    entity: vm.entity,
                    initObject: vm.initObject
                }
            });
        }

        function refreshViewRow(columnsDefs, tableRow, tableViewValues) {
            var aTextForView = tableViewValues;
            columnsDefs.forEach(function (col) {
                if (col.link !== undefined && col.link !== false) {
                    var valueId = tableRow[col.link.id];
                    EntityService.getEntryById(col.link.entity, valueId).then(function (response) {
                        var obj = response.data;
                        var strTextForView = '';
                        var length = col.link.properties.length;
                        for (var i = 0; i < length; i++) {
                            strTextForView += obj[col.link.properties[i]];
                            if (col.link.properties[i + 1]) {
                                strTextForView += ', '
                            }
                        }
                        if (!aTextForView[col.link.entity]) {
                            aTextForView[col.link.entity] = {};
                        }
                        aTextForView[col.link.entity][tableRow.id] = strTextForView;
                    })
                }
            });
        }

        function isRequired(colName) {
            var locColDefs = vm.columnDefs;
            var col;
            for (var i = 0; i < locColDefs.length; i++) {
                col = locColDefs[i];
                if (col.name === colName && angular.isObject(col['editrules'])) {
                    return col['editrules'].required;
                }
            }
            return false;
        }

        function isDisplayName(col) {
            return (typeof col.displayName !== 'undefined' && typeof col.displayName === "string" && col.displayName !== "");
        }

        function isLink(col) {
            return (typeof col.link !== 'undefined' && angular.isObject(col.link));
        }

        function isDisplay(col) {
            return (col.display === 'true' || col.display === true);
        }

        function showSelected(row, button) {
            clearSelection();
            vm.rowsButtons[row.id] = {};
            if (button.markUp || typeof button.markUp === 'undefined') {
                vm.rowsButtons[row.id][button.name] = true;
            }
        }

        function clearSelection() {
            vm.rowsButtons = {};
        }
    },
    templateUrl: 'shared/components/coreTable/coreTable.html',
    controllerAs:
        'coreTable'
})
;
