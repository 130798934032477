core_module.directive('mdDialogContent', function ($rootScope) {
    return {
        restrict: 'E',
        link: function (scope, element, attrs) {
            var el = element[0];

            function checkScroll() {
                var margin = 5;
                return (el.scrollTop + el.offsetHeight >= el.scrollHeight - margin);
            }

            function scrollBottom() {
                if (checkScroll()) {
                    $rootScope.$emit('scrollBottom', 'dialog');
                }
            }

            element.bind('scroll', scrollBottom);

            scope.$on('$destroy', function() {
                element.unbind('scroll', scrollBottom)
            })
        }
    }
});