core_module.component('exportCsvFieldsGroupManagement', {
    templateUrl: 'shared/components/exportCsv/exportCsvFieldsGroupManagement/exportCsvFieldsGroupManagement.html',
    controller: function ($scope, $mdDialog, $translate, AuthService) {
        $scope.childTitle = '';
        $scope.sectionTemplateChild = false;
        $scope.exportSettingChild = false;

        $scope.$on('fromParentToSectionTemplates', function (event, data) {
            $scope.childTitle = data.title;
            data.title = null;
            $scope.sectionTemplateChild = true;
            $scope.exportSettingChild = false;
            $scope.parentId = data.parentEntity.id;
        });

        $scope.$on('fromParentToExportcsvfieldsgroups', function (event, data) {
            $scope.childTitle = data.title;
            data.title = null;
            $scope.sectionTemplateChild = false;
            $scope.exportSettingChild = true;
            $scope.parentId = data.parentEntity.id;
        });
    }
});