core_module.factory('ScrollService', function (HttpService, $timeout) {
        var scrollService = {
            scrollTop: scrollTop
        };

        function scrollTop() {
            $timeout(function () {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })
            });
        }

        return scrollService;
    }
);
