core_module.controller('PasswordRecoveryController', function ($rootScope, $translate, HttpService, AlertService, DialogService, PreloaderService, CaptchaService) {
    var vm = this;

    vm.sendPasswordRecoveryRequest = sendPasswordRecoveryRequest;
    vm.refreshCaptcha = refreshCaptcha;
    vm.captchaId = null;
    vm.captchaSrc = null;
    vm.$onInit = init;
    vm.displayAlert=false;

    function init() {
        refreshCaptcha();
    }

    function sendPasswordRecoveryRequest() {
        PreloaderService.showPreloader('userDialog');
        HttpService.post('/passwords/', {
                email: vm.username,
                captchaId: vm.captchaId,
                captchaAnswer: vm.captcha
            }
        ).then(function () {
            vm.captcha= "";
            vm.displayAlert=false;
            PreloaderService.hidePreloader();
            DialogService.cancelDialog();
            AlertService.renderDialogMessage($translate.instant('user.will_get_new_password',{mail:vm.username}), 'user.reset_mail_sent');
        }, function (response) {
            vm.captcha = "";
            refreshCaptcha()
            vm.displayAlert=true;
            if (response.data.details) {
                vm.alert =response.data.details.cause;
            }else{
                vm.alert = "error.unexpected"
            }
            PreloaderService.hidePreloader();
        })
    }

    function refreshCaptcha(){
        PreloaderService.showPreloader('userDialog');
        CaptchaService.refreshCaptcha(vm.captchaId).then(function (captcha) {
            vm.captchaId = captcha.captchaId;
            vm.captchaSrc = captcha.captchaSrc;
            PreloaderService.hideParticularPreloader('userDialog');
        })
    }
});
