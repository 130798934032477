core_module.component('participantsOverview', {
    controller: function (HttpService, $translate, $q) {
        var vm = this;

        vm.$onInit = init;
        vm.getFacilityUrl = getFacilityUrl;

        function init() {
            var promises = [
                HttpService.get("/board/facility/count"),
                HttpService.get("/board/mCase/count")
            ];

            $q.all(promises).then(function (response) {
                vm.countOfFaclities = $translate.instant('participant.count') + ": " + response[0].data + " |";
                vm.enteringParticipants = $translate.instant('statistics.parcticipants.entering') + ": " + response[1].data.participants;

                vm.title = $translate.instant('statistics.participants', {
                    total: response[0].data,
                    entering:  response[1].data.participants,
                });
            });
        }

        function getFacilityUrl() {
            return '/board/facility';
        }

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: false,
            add: false,
            showFilter: true,
            hideActions: true,

        };

        vm.query = {
            order: 'displayName',
            limit: 10,
            page: 1
        };

        vm.initObject = {
            displayName: '',
        };

        vm.columnDefs = [
            {
                name: 'displayName',
                field: 'displayName',
                displayName: 'Name',
                display: true,
                edit: false,
                type: 'text',
                filter: true
            }
        ];
    },
    templateUrl: 'shared/components/boardManagement/participantsOverview/participantsOverview.html',
    controllerAs: 'overview'
});