core_module.controller('UserAddressDialogsController', function ($rootScope, $scope, $mdDialog, EntityService, AlertService, DialogService, $translate, userAddress,
        entriesArray, addressForSelect, addressTypeForSelect, usersForSelect) {

    $scope.entriesArray = entriesArray;
    $scope.addressForSelect = addressForSelect;
    $scope.addressTypeForSelect = addressTypeForSelect;
    $scope.usersForSelect = usersForSelect;

    $scope.userAddress_Id = userAddress ? userAddress.id : null;
    $scope.address = userAddress ? userAddress.address_id : null;
    $scope.addressType = userAddress ? userAddress.addressType_id : null;
    $scope.user = userAddress ? userAddress.user_id : null;

    $scope.addConfirm = function () {
        var newUserAddress;
        if ($scope.addUserAddressForm.$valid) {
            newUserAddress = {
                address_id: $scope.address,
                addressType_id: $scope.addressType,
                user_id: $scope.user
            };

            EntityService.addEntry('userAddress', newUserAddress).then(function (/*response*/) {
                AlertService.showToast($translate.instant('newUserAddressCreatedSuccessfully'));
                $rootScope.$broadcast('refreshTable', {entity: 'userAddress'});
                DialogService.closeDialog();
            }, function (/*response*/) {
                AlertService.renderErrorMessage($translate.instant('form_has_errors'), $translate.instant('form_has_errors'), '');
            });
        }
        else {
            AlertService.renderErrorMessage($translate.instant('form_has_errors'), $translate.instant('form_has_errors'), '');
        }
    };

    $scope.editConfirm = function () {
        if ($scope.editUserAddressForm.$valid) {
            var editedUserAddress = {
                id: $scope.userAddress_Id,
                address_id: $scope.address,
                addressType_id: $scope.addressType,
                user_id: $scope.user
            };
            EntityService.updateEntry('userAddress', editedUserAddress).then(function (/*response*/) {
                AlertService.showToast($translate.instant('userAddressUpdatedSuccessfully'));
                $rootScope.$broadcast('refreshTable', {entity: 'userAddress'});
                DialogService.closeDialog();
            }, function (/*response*/) {
                AlertService.renderErrorMessage($translate.instant('form_has_errors'), $translate.instant('form_has_errors'), '');
            });
        }
        else {
            AlertService.renderErrorMessage($translate.instant('form_has_errors'), $translate.instant('form_has_errors'), '');
        }
    }
});