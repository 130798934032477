core_module.component('exportCsvFieldsGroupTable', {
    templateUrl: 'shared/components/exportCsv/exportCsvFieldsGroupTable/exportCsvFieldsGroupTable.html',
    controller: function ($scope, $mdDialog, $rootScope, $q, HttpService, EntityService) {
        var promisses = [];
        var filetypesSelectOptions = [];
        var documentTemplateTypeSelectOptions = [];
        var csvFieldGroupTypesOptions = [];

        init();

        function init() {
            var filetypesUrl = 'export_files/filetypes';
            var exportsUrl = 'exports';
            var csvfieldgrouptypesUrl = 'exportcsvfieldsgroups/csvfieldgrouptypes';
            var documentTemplateTypeUrl = 'documentTemplateType';

            promisses[0] = EntityService.getEntries(filetypesUrl);
            promisses[1] = EntityService.getEntries(documentTemplateTypeUrl);
            promisses[2] = EntityService.getEntries(csvfieldgrouptypesUrl);

            $q.all(promisses).then(function (dataArray) {
                var j, tmpArray = [];
                var selectOption = {};

                if (dataArray[0].data) { // Filetypes objects
                    tmpArray = dataArray[0].data;
                    for (j=0; j < tmpArray.length; j++) {
                        selectOption = {};
                        selectOption.name = tmpArray[j];
                        selectOption.value = tmpArray[j];
                        filetypesSelectOptions.push(selectOption);
                    }
                }
                if (dataArray[1].data) { // DocumentTemplateType objects
                    tmpArray = dataArray[1].data.content;
                    for (j=0; j < tmpArray.length; j++) {
                        selectOption = {};
                        selectOption.name = tmpArray[j].templateType;
                        selectOption.value = tmpArray[j].id;
                        documentTemplateTypeSelectOptions.push(selectOption);
                    }
                }

                if (dataArray[2].data) { // csvfieldgrouptypes objects
                    tmpArray = dataArray[2].data;
                    for (j=0; j < tmpArray.length; j++) {
                        selectOption = {};
                        selectOption.name = tmpArray[j];
                        selectOption.value = tmpArray[j];
                        csvFieldGroupTypesOptions.push(selectOption);
                    }
                }

            }, function (response) {
                AlertService.renderErrorMessage(response.data.details.cause, response.data.details, response.status);
            });
        }

        $scope.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            showDeleted: true,
            add: true,
            customActions: [
                {
                    name: 'sectionTemplates',
                    icon: 'dns',
                    clickFunction: manageSectionTemplates

                },
                {
                    name: 'Exportsettings',
                    icon: 'settings',
                    clickFunction: manageExportsettings
                }
            ]
        };

        function manageSectionTemplates(exportcsvfieldsgroups) {
            console.log("manageSectionTemplates exportcsvfieldsgroups:" + exportcsvfieldsgroups);
            var data = {
                title: "" + exportcsvfieldsgroups.groupName,
                childEntity: 'sectiontemplates',
                parentEntityId: exportcsvfieldsgroups.id,
                parentEntity: exportcsvfieldsgroups
            };
            $scope.$emit('fromParentToSectionTemplates', data);
        }

        function manageExportsettings(exportcsvfieldsgroups) {
            console.log("manageExportsettings exportcsvfieldsgroups:" + exportcsvfieldsgroups);
            var data = {
                title: "" + exportcsvfieldsgroups.groupName,
                childEntity: 'export_settings',
                parentEntityId: exportcsvfieldsgroups.id,
                parentEntity: exportcsvfieldsgroups
            };
            $scope.$emit('fromParentToExportcsvfieldsgroups', data);
        }

        $scope.query = {
            order: 'id',
            limit: 10,
            page: 1
        };

        $scope.initObject = {
            id: null
        };

        $scope.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {name: 'groupName', field: 'groupName', display: true, edit: true, type: 'text', filter: true},
            {
                name: 'documentTemplateType',
                field: 'documentTemplateType',
                display: false,
                displayName: 'Bogentyp',
                editrules: {required: true},
                edit: true,
                type: 'select',
                selectOptions: documentTemplateTypeSelectOptions,
                link: false,
                filter: true
            },
            {
                name: 'documentTemplateTypeName',
                field: 'documentTemplateType_Name',
                display: true,
                displayName: 'Bogentyp',
                edit: false,
                type: 'text',
                link: false,
                filter: false
            },
            {name: 'fileType', field: 'fileType', display: true, edit: true, type: 'select',
                selectOptions: filetypesSelectOptions, link: false, filter: true},
            {name: 'csvFieldGroupType', field: 'csvFieldGroupType', display: true, edit: true,
                type: 'select', selectOptions: csvFieldGroupTypesOptions, link: false, filter: true},
            {name: 'position', field: 'position', display: true, edit: true, type: 'text', filter: true}
        ];
    }
});
