core_module.component('facilityMedicalSpecialityManagement', {
    bindings: {
        parentId: '<?',
        facilityName: '<?'
    },
    controller: function ($mdDialog, HttpService, $rootScope, $translate) {
        var vm = this;

        vm.getMedicalSpecialityUrl = getMedicalSpecialityUrl;
        vm.addEntry = addEntry;
        vm.delEntry = delEntry;

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: true,
            add: true
        };

        vm.initObject = {
            deleted: false,
            name: ''
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false},
            {name: 'deleted', field: 'deleted', display: true, edit: true, type: 'boolean', link: false},
            {
                name: 'name',
                field: 'name',
                display: true,
                edit: true,
                type: 'text',
                link: false,
                editrules: {required: true}
            }
        ];

        function getMedicalSpecialityUrl() {
            if (vm.parentId) {
                return 'facilityMedicalSpeciality?facilityId=' + vm.parentId;
            }
        }

         function addEntry() {
            $mdDialog.show({
                templateUrl: 'shared/components/facilityMedicalSpecialityManagement/dialogs/facilityMedicalSpecialityAddEntry.html',
                controller: 'FacilityMedicalSpecialityAddEntryController',
                controllerAs: 'medicalSpecialityDialog',
                fullscreen: true,
                locals: {
                    facilityId: vm.parentId
                }
            });
        }

        function delEntry(row) {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('core.delete_entry'))
                .ok($translate.instant('delete'))
                .cancel($translate.instant('cancel'));
            $mdDialog.show(confirm).then(function () {
                HttpService.delete('/facilityMedicalSpeciality?facilityId=' + vm.parentId + '&medicalSpecialityId=' + row.id).then(function () {
                    $rootScope.$broadcast("refreshTable", {entity: 'facilityMedicalSpeciality'});
                    AlertService.showToast(entity + 'DeleteSuccessfully');
                }, function () {
                    AlertService.showToast(entity + 'delete failed');
                });
            });
        }
    },
    templateUrl: 'shared/components/facilityMedicalSpecialityManagement/facilityMedicalSpecialityManagement.html',
    controllerAs: 'medicalSpeciality'
});