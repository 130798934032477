core_module.component('statisticsPreview', {
    controller: function ($scope, $rootScope, $q, $interval, $timeout, $translate, DialogService, HttpService, AuthService, CONFIG) {
        var yearsCount;
        var intervalPromise;

        this.$onDestroy = destroy;
        this.$onInit = init;

        function init() {
            yearsCount = 3;
            $scope.statisticItems = [];
            intervalPromise = $interval(statisticInInterval, CONFIG.REQUEST_STATISTIC_INTERVAL);
            $timeout(function () {
                $rootScope.$broadcast('facilityTemplateChange');
            }, 200);
        }

        function destroy() {
            $interval.cancel(intervalPromise);
        }


        $scope.$on('facilityTemplateChange', function () {
            getStatisticsOfLastYears();
        });

        $scope.$on('refreshStatistics', function () {
            getStatisticsOfLastYears();
        });


        function statisticInInterval() {
            getStatisticsOfLastYears();
        }

        function getStatisticsOfLastYears() {
            getStatisticsForLastYearsIntervalCount(yearsCount).then(function (result) {
                var promises = [];
                var newStats = [result];

                for (var yearsPast = yearsCount; yearsPast >= 0; yearsPast--) {
                    promises.push(getStatisticsForYearSubtractedFromCurrentYearBy(yearsPast));
                }

                $q.all(promises).then(function (responses) {
                    responses.forEach(function (result) {
                        newStats.push(result);
                    });
                    $scope.statisticItems = newStats;
                });
            });
        }

        function getStatisticIntervalFrom(years) {
            var today = new Date();
            today.setFullYear(today.getFullYear() - years);
            return new Date('January 1, ' + (today.getFullYear()) + " 00:00:00");
        }

        function getStatisticIntervalTo(years) {
            var today = new Date();
            today.setFullYear(today.getFullYear() - years);
            return new Date('December 31, ' + (today.getFullYear()) + " 23:59:59");
        }

        function getStatisticsForYearSubtractedFromCurrentYearBy(years) {
            var deferred = $q.defer();

            var fromDate = getStatisticIntervalFrom(years).getTime();
            var toDate = getStatisticIntervalTo(years).getTime();

            getStatisticsForInterval(fromDate, toDate).then(function (result) {
                deferred.resolve(result);
            });

            return deferred.promise;
        }

        function getStatisticsForLastYearsIntervalCount(yearsCount) {
            var deferred = $q.defer();
            var fromDate = getStatisticIntervalFrom(yearsCount).getTime();
            var toDate = getStatisticIntervalTo(0).getTime();

            getStatisticsForInterval(fromDate, toDate).then(function (result) {
                deferred.resolve(result)
            });
            return deferred.promise;
        }

        function getStatisticsForInterval(fromDate, toDate) {
            var deferred = $q.defer();
            var currentUserFacility = AuthService.getCurrentUserFacility();
            var url = "";

            if (AuthService.isAdmin()) {
                url = "/statistics/?from=" + fromDate + "&to= " + toDate;
            } else {
                url = "/statistics/?from=" + fromDate + "&to= " + toDate
                    + "&currentUserFacilityId= " + currentUserFacility.id;
            }

            HttpService.shortGet(url, function (response) {
                var result = response.data;
                var fromYear = new Date(fromDate).getFullYear();
                var toYear = new Date(toDate).getFullYear();
                result = setResultFields(result, fromYear, toYear);
                deferred.resolve(result)
            });
            return deferred.promise;
        }

        function setResultFields(result, fromYear, toYear) {
            var newResult = angular.copy(result);
            newResult.title = fromYear;
            newResult.toYear = toYear;
            if (fromYear !== toYear)
                newResult.title = $translate.instant('caseNumbers.intervall') + ' ' + fromYear + '-' + toYear;
            return newResult;
        }
    },
    templateUrl: 'shared/components/homepage/statisticsPreview.html'
});
