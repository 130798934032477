core_module.component('userRoleManagement', {
    bindings: {
        parentId: '<?'
    },
    controller: function ($rootScope, $scope, $route, $translate, $mdDialog, EntityService, HttpService, AlertService) {
        var vm = this;

        vm.getUserRoleUrl = getUserRoleUrl;
        vm.addUserRole = addUserRole;
        vm.removeUserRole = removeUserRole;

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            viewTable: true,
            boundaryLinks: true,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: true,
            add: true
        };

        vm.initObject = {
            roleId: null,
            facilityId: null,
            userId: null,
            roleName: null,
            facilityName: null
        };

        vm.columnDefs = [
            {name: 'roleId', field: 'roleId', display: false, edit: false, link: false},
            {name: 'role.name', field: 'roleName', display: true, edit: false, link: false, translate: true},
            {name: 'facilityId', field: 'facilityId', display: false, edit: false, link: false},
            {name: 'facility.name', field: 'facilityName', display: true, edit: false, link: false, noSort: true},
            {name: 'userId', field: 'userId', display: false, edit: false, link: false}
        ];

        function getUserRoleUrl() {
            if (vm.parentId) {
                return '/userRole/user/' + vm.parentId;
            }
        }

        function addUserRole() {
            $mdDialog.show({
                templateUrl: 'shared/components/userRoleManagement/dialogs/add.role.html',
                controller: 'UserRoleDialogsController',
                fullscreen: true,
                locals: {
                    userId: vm.parentId
                }
            });
        }

        function removeUserRole(userRole, callback) {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('confirm_changes'))
                .textContent($translate.instant('confirm_changes'))
                .ok($translate.instant('delete'))
                .cancel($translate.instant('cancel'));
            $mdDialog.show(confirm).then(function () {
                    var showDeleteSuccess = function () {
                        AlertService.showToast($translate.instant('userRole' + ' deleted successfully'));
                    };
                    return HttpService.delete('/userRole/' + userRole.id).then(function () {
                        $rootScope.$broadcast('refreshTable', {entity: 'userRole'});
                        callback();
                        showDeleteSuccess();
                    });
                }
            );
        }
    },
    templateUrl: 'shared/components/userRoleManagement/userRoleManagement.html',
    controllerAs: 'userRoles'
});