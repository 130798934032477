core_module.controller('SelectFacilityDialogController', function ($rootScope, $scope, $mdDialog, $location, $translate, HttpService, DialogService, AuthService, AlertService, facilities) {
    var vm = this;

    vm.$onInit = init;

    vm.selectFacility = changeFacility;

    vm.options = {
        rowSelection: false,
        multiSelect: false,
        autoSelect: true,
        decapitate: false,
        largeEditDialog: false,
        boundaryLinks: false,
        limitSelect: true,
        pageSelect: true,
        editable: false,
        deletable: false,
        add: false,
        customActions: [{
            name: 'change',
            icon: 'done',
            clickFunction: changeFacility
        }]
    };


    vm.columnDefs = [
        {name: 'id', field: 'id', display: true, edit: false, type: 'number', displayName: 'ID'},

        {
            name: 'name',
            field: 'name',
            display: true,
            edit: false,
            type: 'text',
            displayName: 'name'
        }

    ];

    function init() {
        vm.facilities = facilities;
    }

    function changeFacility(facility) {
        var userToModify = AuthService.getCurrentUser();
        userToModify.currentFacility = facility;
        HttpService.shortPut('/currentUser/currentFacility/' + facility.id, null, function (response) {
            AuthService.setCurrentUserFacility(facility);
            AuthService.setFacilityParameter();
            AuthService.setCurrentUserPermission(response.data);
            $rootScope.$broadcast('changeUserState', AuthService.isSignedIn);
            $rootScope.$broadcast("refreshTable", {entity: 'ALL'});
            $rootScope.$broadcast("hideChildTable", {entity: 'facility'});
            $rootScope.$broadcast("refreshStatistics");
            AlertService.showToast('user.facility_switched');
            DialogService.hideDialog();
        }, function (response) {
            console.log('Cannot update current facility for user.', response);
        });
    }
});
