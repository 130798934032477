core_module.component('newsManagement', {
    controller: function ($scope) {
        $scope.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            add: true
        };

        $scope.query = {
            order: 'id',
            limit: 10,
            page: 1
        };

        $scope.initObject = {
            deleted: false,
            headLine: '',
            newsDate: '',
            textNews: '',
            title: ''
        };

        $scope.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', displayName: 'ID', filter: true},
            {
                name: 'title',
                field: 'title',
                display: true,
                edit: true,
                type: 'text',
                displayName: 'news.subject',
                filter: true,
                editrules: {required: true}
            },
            {
                name: 'newsDate',
                field: 'newsDate',
                display: true,
                edit: true,
                type: 'date',
                displayName: 'news.date',
                filter: true,
                editrules: {required: true}
            },
            {
                name: 'language',
                field: 'language',
                display: true,
                edit: true,
                type: 'language',
                displayName: 'news.language',
                filter: true,
                editrules: {required: true}
            },
            {
                name: 'textNews',
                field: 'textNews',
                display: true,
                edit: true,
                type: 'text',
                displayName: 'news.text',
                filter: true,
                editrules: {required: true}
            },
            {
                name: 'headLine',
                field: 'headLine',
                display: true,
                edit: true,
                type: 'boolean',
                filter: true,
                displayName: 'news.headline'
            }

        ];

        $scope.selRow = [];

    },
    templateUrl: 'shared/components/newsManagement/newsManagement.html'
});