core_module.component('localizationManagement', {
    controller: function ($scope, $translate, $mdDialog, LocalizationService, LanguageService, $q, AlertService, $rootScope) {
        var vm = this;

        var languages = [];

        vm.transformEntries = transformEntries;
        vm.addEntry = addEntry;
        vm.editRow = editRow;
        vm.removeEntries = removeEntries;
        vm.$onInit = init;

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: true,
            deletable: true,
            add: true
        };

        vm.query = {
            order: 'prop',
            limit: 10,
            page: 1
        };

        vm.initObject = {
            DE: '',
            EN: '',
            disableEdit: false,
            isSelected: false,
            prop: ''
        };

        vm.columnDefs = [
            {
                name: 'prop',
                field: 'prop',
                display: true,
                edit: true,
                type: 'text',
                link: false,
                editrules: {required: true},
                displayName: 'localization.property'
            },
            {name: 'disableEdit', field: 'disableEdit', display: false, edit: false, type: 'text', link: false},
            {name: 'isSelected', field: 'isSelected', display: false, edit: false, type: 'text', link: false}
        ];

        function init() {
            getLanguages();
        }

        function addEntry() {
            $mdDialog.show({
                templateUrl: 'shared/components/localizationManagement/dialogs/localizationEntry.html',
                controller: 'LocalizationEntryController',
                controllerAs: 'localizationDialog',
                fullscreen: true,
                locals: {
                    languages: languages,
                    entry: null,
                    initObject: vm.initObject
                }
            });
        }

        function removeEntries(row) {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('core.delete_entry'))
                .ok($translate.instant('delete'))
                .cancel($translate.instant('cancel'));

            $mdDialog.show(confirm).then(function () {
                var showDeleteSuccess = function () {
                    $rootScope.$broadcast('refreshTable', {entity: 'localizations'});
                    AlertService.showToast($translate.instant('localization deleted successfully'));
                };
                var showErrorDelete = function () {
                    AlertService.renderErrorMessage('delete failed');
                };

                LocalizationService.deleteEntry(row).then(showDeleteSuccess, showErrorDelete);
            });
        }

        function editRow(row) {
            $mdDialog.show({
                templateUrl: 'shared/components/localizationManagement/dialogs/localizationEntry.html',
                controller: 'LocalizationEntryController',
                controllerAs: 'localizationDialog',
                locals: {
                    languages: languages,
                    entry: row,
                    initObject: vm.initObject
                }
            });
        }

        function getLanguages() {
            LanguageService.getLanguages().then(function (response) {
                languages = response.data;
                angular.forEach(response.data, function (language) {
                    vm.columnDefs.push({
                        name: language,
                        field: language,
                        display: true,
                        edit: true,
                        type: 'text',
                        link: false,
                        editrules: {required: true}
                    })
                });
            }, function (response) {
                console.log("failure", response);
            });
        }

        function transformEntries(entries) {
            return LocalizationService.restructureEntries(entries);
        }

        function isRequired(colName) {
            var locColDefs = vm.columnDefs;
            var col;
            for (var i = 0; i < locColDefs.length; i++) {
                col = locColDefs[i];
                if (col.name === colName && angular.isObject(col['editrules'])) {
                    return col['editrules'].required;
                }
            }
            return false;
        }
    },
    templateUrl: 'shared/components/localizationManagement/localizationManagement.html',
    controllerAs: 'localizations'
});