core_module.factory('CaptchaService', function (HttpService) {
    return {
        refreshCaptcha: function (captchaId) {
            return HttpService.get('/captcha/reload/' + captchaId).then(function (response) {
                return {
                    captchaId: response.data.captchaId,
                    captchaSrc: "data:image/png;base64," + response.data.captchaImg
                };
            });
        }
    }
})
;
