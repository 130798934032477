core_module.controller('ImportCSVController', function ($rootScope, $translate, DialogService, EntityService, AlertService, PreloaderService, CONFIG, Upload) {
    var vm = this;

    vm.$onInit = init;
    vm.sendFile = sendFile;

    function init() {
        vm.templateType = {};
        getTemplateTypes();
    }

    function getTemplateTypes() {
        EntityService.getEntries("documentTemplateType", { limit: -1 }, false).then(function (response) {
            vm.templateTypes = response.data.content.map(function (item) {
                return {name: item.templateType, value: item.templateType};
            });
            PreloaderService.hideParticularPreloader('coreDialog');
        });
    }

    function sendFile() {
        if (vm.file) {
            upload(vm.file);
        }
    };

    var formatDate = function (date) {
        function pad(s) {
            return (s < 10) ? '0' + s : s;
        }

        var d = new Date(date);
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('.');
    };


    function upload(file) {
        PreloaderService.showPreloader('coreDialog');
        var from = moment(formatDate(vm.activeFrom))._i;
        var to = moment(formatDate(vm.activeTo))._i;

        Upload.upload({
            url: CONFIG.REST_BASE_URL + '/import/csv',
            data: {
                name: vm.name,
                version: vm.version,
                activeFrom: from,
                activeTo: to,
                templateType: vm.templateType
            },
            file: file
        }).then(function () {
            AlertService.showToast($translate.instant('fileUploadSuccessfully'));
            delete vm.file;
            $rootScope.$broadcast('refreshTable', {entity: 'documentTemplate'});
            DialogService.hideDialog();
            PreloaderService.hideParticularPreloader('coreDialog');
        }, function () {
            PreloaderService.hideParticularPreloader('coreDialog');
            DialogService.cancelDialog();
        });
    }
});


