core_module.factory('httpInterceptor', function ($q, $rootScope, $injector, $location) {

    var httpInterceptor = {
        request: function (config) {
            config.headers['X-AUTH-TOKEN'] = sessionStorage.getItem('token');
            return config;
        },
        requestError: function (config) {
            handleRequestError();
        },
        response: function (response) {
            var AuthService = $injector.get('AuthService');
            var expirationTime = response.headers('TOKEN_EXP');

            readVersion(response);
            AuthService.checkExpiration(expirationTime);
            return response;
        },
        responseError: function (response) {
            handleResponseError(response);
            return $q.reject(response);
        }
    };

    function readVersion(response) {
        var version = response.headers('implementationVersion');
        if (version) sessionStorage.setItem('version', version);
    }

    function handleResponseError(response) {
        if (response.status === 401) {
            handle401();
        }
        else {
            if (response.status === 403) {
                handle403();
            }
            if (response.config.method !== 'HEAD') {
                if (response.status === 404) {
                    handle404();
                }
                else {
                    handleAnotherStatuses(response);
                }
            }
        }
    }

    function handleRequestError() {
        var AlertService = $injector.get('AlertService');
        AlertService.renderErrorMessage("app.request.failed", event);
    }

    function handle401() {
        var AuthService = $injector.get('AuthService');
        AuthService.signOut();
    }

    function handle403() {
        $location.path('/');
        AuthService.signOut();
        $location.path('/');
    }

    function handle404() {
        $location.path('/error');
    }

    function handleAnotherStatuses(response) {
        var AlertService = $injector.get('AlertService');
        if (response.data && response.data.message) {
            var details = response.data.details ? response.data.details : '';
            AlertService.renderMessageByHTTPStatus(response.status, response.data.message, details);
        } else {
            AlertService.renderMessageByHTTPStatus(response.status, response.statusText);
        }
    }

    return httpInterceptor;
});
