core_module.component('entrySvg', {
    bindings: {
        issues: "<",
        structureElement: "<",
        svgSelected: "<",
        svgDetailWindowOpen: "<",
        svgClick: "<",
        deleteIssue: "<"
    },
    controller: function () {
        var vm = this;

        vm.$onChanges = handleChanges;
        vm.isAmputation = isAmputation;

        function handleChanges(changes) {
            console.log('entry changes', changes);
            if (issuesChanged(changes) || structureElementChanged(changes)) {
                vm.filteredIssues = vm.issues.filter(function (issue) {
                    return issueBelongsToCurrentStructure(issue);
                });
            }
        }

        function issuesChanged(changes) {
            return changes.hasOwnProperty('issues') && typeof changes.issues.currentValue !== 'undefined';
        }

        function structureElementChanged(changes) {
            return changes.hasOwnProperty('structureElement') && typeof changes.structureElement.currentValue !== 'undefined';
        }

        function issueBelongsToCurrentStructure(issue) {
            return issue.structure === vm.structureElement.name;
        }

        function isAmputation() {
            if (vm.structureElement && vm.structureElement.name.indexOf('amputation') !== -1) {
                return true;
            }
            return false;
        }
    },
    controllerAs: 'entrySvg',
    templateUrl: "shared/components/entryManagement/entry/entry.svg.html"
});