core_module.component('patientSection', {

    bindings: {
        case: '<',
        svgClick: '<',
        deleteIssue: '<',
        svgDeleted: '<',
        sectionFilter: '<'
    },
    controller: function (HttpService, $mdDialog, $scope, $translate, PreloaderService, PatientSelectService) {
        var vm = this;

        vm.$onInit = init;
        vm.$onChanges = handleChanges;
        vm.showOccupationSelect = showOccupationSelect;
        vm.findOccupation = findOccupation;
        vm.currentDate = new Date();
        vm.clearTimeOfStay = clearTimeOfStay;
        vm.clearTransferKindOther = clearTransferKindOther;
        vm.clearTransferKind = clearTransferKind;
        vm.clearPrimaryCareOther = clearPrimaryCareOther;
        vm.getDateOfAccidentPlus14Days = getDateOfAccidentPlus14Days;
        vm.dateOfAccidentPlus14Days = null
        vm.changeDateOfAccident = changeDateOfAccident;

        vm.occupationOptions = [];
        vm.occupationData = [];

        function init() {
            PreloaderService.showPreloader("entryPreloader");
            PreloaderService.waitForDOM();

            fetchOccupations();

            vm.options = PatientSelectService.selectOptions;

            vm.filteredIssues = vm.case.issues.filter(function (issue) {
                return vm.sectionFilter(issue);
            });
        }

        function findOccupation() {
            if (!vm.case.patientSection.profession)
                return false;
            const found = vm.occupationOptions.find(o => o.value === vm.case.patientSection.profession);
            if (found)
                return true;
            return false;
        }

        function fetchOccupations() {
            PreloaderService.showPreloader('occupationSelect');

            HttpService.get('/occupation').then(function (response) {
                vm.occupationData = response.data;
                flattenData(vm.occupationData);
                PreloaderService.hideParticularPreloader('occupationSelect');
            }).catch(function () {
                PreloaderService.hideParticularPreloader('occupationSelect');
            })
        }

        function flattenData(aisData) {
            aisData.forEach(code => {
                if (code.children && code.children.length > 0) {
                    flattenData(code.children)
                } else {
                    vm.occupationOptions.push({
                        name: code.title,
                        value: code.isco08code,
                    })
                }
            })
            vm.occupationOptions.sort((a, b) => a.name.localeCompare(b.name));
        }


        function showOccupationSelect() {
            $mdDialog.show({
                multiple: true,
                templateUrl: 'shared/components/casesManagement/dialogs/occupation-select.html',
                controller: 'OccupationSelectController',
                controllerAs: 'occupationSelect',
                locals: {
                    data: vm.occupationData
                }
            }).then(function (node) {
                selectOccupation(node);
            })
        }

        function selectOccupation(node) {
            vm.case.patientSection.profession = node.isco08code;
        }

        function handleChanges(changes) {
            vm.filteredIssues = changes.case.currentValue.issues.filter(function (issue) {
                return vm.sectionFilter(issue);
            });
        }

        function clearTimeOfStay(val) {
            vm.case.patientSection.timeOfStay = undefined;
        }

        function clearTransferKindOther(val) {
            vm.case.patientSection.transferKindOther = undefined;
        }

        function clearTransferKind(val) {
            vm.case.patientSection.transferKind = undefined;
            vm.case.patientSection.transferKindOther = undefined;
        }

        function clearPrimaryCareOther(val) {
            vm.case.patientSection.primaryCareOther = undefined;
        }

        function getDateOfAccidentPlus14Days() {
            if (vm.case.treatmentSection.dateOfAccident && vm.dateOfAccidentPlus14Days === null) {
                vm.dateOfAccidentPlus14Days = new Date(vm.case.treatmentSection.dateOfAccident);
                vm.dateOfAccidentPlus14Days.setDate(vm.dateOfAccidentPlus14Days.getDate() + 14);
            }
            return vm.dateOfAccidentPlus14Days;
        }

        function changeDateOfAccident(value) {
            vm.dateOfAccidentPlus14Days = new Date(value);
            vm.dateOfAccidentPlus14Days.setDate(vm.dateOfAccidentPlus14Days.getDate() + 14);
        }
    },

    templateUrl: "shared/components/entryManagement/entry/patient.section.html",
    controllerAs: "patientSection"
});