core_module.component('chart', {
    bindings: {
        chartData: '<?',
        chartView: '@'
    },
    controller: function ($rootScope, $translate, $scope) {
        var vm = this;
        $scope.chartView = true;

        vm.$onInit = initChart;

        function initChart() {
            if ($scope.chartView) {
                var dataToView = prepareCaseChartData(vm.chartData);
                var caseContext = document.getElementById('caseChart').getContext('2d');

                Chart.defaults.global.defaultFontColor = '#333';
                Chart.defaults.global.defaultFontFamily = '"Roboto", sans-serif';
                vm.caseChart = new Chart(caseContext, {
                    type: 'bar',
                    data: dataToView,
                    options: {
                        title: {
                            display: true,
                            text: $translate.instant('caseChart.title'),
                            fontSize: 24,
                            fontStyle: '400'
                        },
                        scales: {
                            xAxes: [{
                                stacked: false

                            }],
                            yAxes: [{
                                stacked: false,
                                ticks: {
                                    suggestedMin: 0

                                }

                            }]
                        },
                        tooltips: {
                            callbacks: {
                                label: function(tooltipItem, data) {
                                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                                    var allCases =  data.datasets[0].data[tooltipItem.index] + data.datasets[1].data[tooltipItem.index];
                                    var percentage = Math.round((tooltipItem.yLabel / allCases) * 100);
                                    if (label) {
                                        label += ': ';
                                    }
                                    label += tooltipItem.yLabel + " (" + percentage +"%)";
                                    return label;
                                }
                            }
                        }
                    }
                });
            }

        }

        function prepareCaseChartData(caseNumbers) {
            var data = {
                labels: [],
                datasets: [{
                    label: $translate.instant('statistics.closed_cases'),
                    data: [],
                    backgroundColor: 'rgba(0, 99, 132, 0.6)',

                }, {
                    label: $translate.instant('statistics.open_cases'),
                    data: [],
                    backgroundColor: 'rgba(99, 132, 0, 0.6)',
                }]
            };
            var sortCaseNumbers = sortArray(caseNumbers, 'year', 'down');

            sortCaseNumbers.forEach(function (item) {
                data.labels.push(item.year);
                data.datasets[0].data.push(item.closedCases);
                data.datasets[1].data.push(item.openCases);


            });
            return data;
        }

        $rootScope.$on('update', function (event, data) {
            if (angular.isArray(data) && data.length > 0) {
                updateCaseChart(data);
                $scope.chartView = true;
            } else {
                $scope.chartView = false;
            }

        });


        function updateCaseChart(data) {
            vm.caseChart.data = [];
            vm.caseChart.data = prepareCaseChartData(data)
            vm.caseChart.update();
        }

        function sortArray(arr, prop, upOrDown) {
            switch (upOrDown) {
                case 'up':
                    return arr.sort(function (a, b) {
                        return a[prop] - b[prop];
                    });
                case 'down':
                    return arr.sort(function (a, b) {
                        return b[prop] - a[prop];
                    });
                default:
                    return arr.sort(function (a, b) {
                        return a[prop] - b[prop];
                    });
            }
        }
    },
    templateUrl: 'shared/components/boardManagement/statistics/chart/chart.html',
    controllerAs: 'chart'
});