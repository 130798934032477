core_module.controller('DetailsIssueController', function ($scope, caseObject, action, filter, $filter, $timeout, $rootScope, $mdDialog, $location, $translate, DialogService, HttpService, AlertService, CaseService, PreloaderService) {
    var vm = this;

    vm.issues = [];
    vm.data = [];

    vm.$onInit = init;
    vm.save = save;
    vm.closeCase = closeCase;
    vm.hasNoIssues = hasNoIssues;
    vm.isCloseCase = isCloseCase;
    vm.isSaveCase = isSaveCase;

    function init() {
        PreloaderService.showPreloader("entryPreloader");
        vm.case = angular.copy(caseObject);
        vm.data = vm.case.data;

        vm.issues = vm.case.issues.filter(function (issue) {
            return filter(issue);
        }).sort(function (curr, next) {
            return curr.customId.localeCompare(next.customId);
        });

        if (action === 'SAVE') {
            vm.title = 'case.save';
        }
        else {
            vm.title = 'case.close';
        }

        PreloaderService.waitForDOM();
    }

    function save() {
        PreloaderService.showPreloader('entryPreloader');
        if (!CaseService.validateCase(vm.case)) {
            PreloaderService.hideParticularPreloader('entryPreloader');
            return;
        }

        vm.case.data = vm.data;
        CaseService.saveCase(vm.case).then(function (res) {
            vm.issues = [];
            vm.data = [];
            vm.case = CaseService.clearUp(vm.case);
            PreloaderService.hideParticularPreloader('entryPreloader');
            $location.path("/");
            DialogService.hideDialog();
        }, function () {
            PreloaderService.hideParticularPreloader('entryPreloader');
        });
    }

    function closeCase() {
        PreloaderService.showPreloader('entryPreloader');
        vm.case.data = vm.data;
        CaseService.closeCase(vm.case).then(function (resolve) {
            vm.case = resolve;
            $rootScope.$broadcast('refreshTable', {entity: 'mCase'});
            $location.path("/");
            DialogService.hideDialog();
            PreloaderService.hideParticularPreloader('entryPreloader');
        });
    }

    function hasNoIssues() {
        return vm.issues.length === 0;
    }

    function isCloseCase() {
        return action === "CLOSE";
    }

    function isSaveCase() {
        return action === "SAVE";
    }

});
