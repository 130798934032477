core_module.component('facilityTypeManagement', {
    controller: function ($mdDialog) {
        var vm = this;

        var facilityTypeKind = [
            {name: 'JURISTICALPERSON', value: 'JURISTICALPERSON'},
            {name: 'COMMITTEE', value: 'COMMITTEE'},
            {name: 'UNION', value: 'UNION'},
            {name: 'OTHER', value: 'OTHER'}
        ];

        vm.addEntry = addEntry;
        vm.editEntry = editEntry;

        vm.options = {
            rowSelection: false,
            multiSelect: true,
            autoSelect: true,
            decapitate: false,
            largeEditDialog: false,
            boundaryLinks: false,
            limitSelect: true,
            pageSelect: true,
            editable: false,
            deletable: true,
            add: true
        };

        vm.columnDefs = [
            {name: 'id', field: 'id', display: true, edit: false, type: 'number', link: false, filter: true},
            {name: 'deleted', field: 'deleted', display: true, edit: true, type: 'boolean', link: false},
            {
                name: 'name',
                field: 'name',
                display: true,
                edit: true,
                type: 'text',
                link: false,
                filter: true,
                editrules: {required: true}
            },
            {
                name: 'kind',
                field: 'kind',
                display: true,
                edit: true,
                type: 'select',
                selectOptions: facilityTypeKind,
                link: false,
                filter: true,
                editrules: {required: true}
            }
        ];

        function addEntry() {
            $mdDialog.show({
                templateUrl: 'shared/components/facilityTypeManagement/dialogs/facilityTypeEntry.html',
                controller: 'FacilityTypeEntryController',
                controllerAs: 'typeDialog',
                fullscreen: true,
                locals: {
                    facilityType: null,
                    facilityTypeKind: facilityTypeKind
                }
            });
        }

        function editEntry(facilityType) {
            $mdDialog.show({
                templateUrl: 'shared/components/facilityTypeManagement/dialogs/facilityTypeEntry.html',
                controller: 'FacilityTypeEntryController',
                controllerAs: 'typeDialog',
                fullscreen: true,
                locals: {
                    facilityType: facilityType,
                    facilityTypeKind: facilityTypeKind
                }
            });
        }
    },
    templateUrl: 'shared/components/facilityTypeManagement/facilityTypeManagement.html',
    controllerAs: 'facilityType'
});